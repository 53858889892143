import React, {useContext, useEffect, useState} from 'react';
import Async, { useAsync } from 'react-select/async';
import useLicensingUsers from "../../hooks/useLicensingUsers";
import {getFormattedFacet, getTableSizes} from "../../hooks/helpers";
import {AppDataContext, IBLSpinLoader} from "@iblai/ibl-web-react-common";
import './AddManagersGroupAssignment.css'
import useTeams from "../../hooks/useTeams";

const AddManagersGroupAssignment = ({setFocused, group=null, setRefresher=()=>{}}) => {
    const {setNotification} = useContext(AppDataContext)
    const {getUserGroupsForSelectDropdown, getTeamsForSelectDropdown, updateManagersGroupAssignment} = useTeams()
    const TABLE_SIZES = getTableSizes();
    const [search, setSearch] = useState('');
    const [size, setSize] = useState(TABLE_SIZES[0]);
    const [page, setPage] = useState(1);
    const defaultFields = {
        groups:[],
        teams:[]
    }
    const [fields, setFields] = useState(defaultFields)
    const handleSetNotification = (success, msg, timeout=2500) => {
        setNotification({
            msg: msg,
            success
        })
    }

    useEffect(() => {
        if(group){
            setFields({
                ...fields,
                teams: group.departments.map((_team)=>({value:_team.id, label:_team.name}))
            })
        }
    }, [group]);

    const [submitting, setSubmitting] = useState(false)

    const handleSuccess = () => {
        setSubmitting(false)
        handleSetNotification(true, !!group ? `${group.name} assignment updated!` : "Assignments successful!")
        setFields(defaultFields)
        setRefresher(Date.now())
        if(group){
            setFocused(false)
        }
    }

    const handleError = (msg) => {
        setSubmitting(false)
        handleSetNotification(true,  msg || "An error occurred. Please try again!")
    }

    const handleSubmit = async (e) => {
        if((!group && fields.groups.length === 0) || fields.teams.length===0 ){
            handleSetNotification(false, "Fill out all required fields.")
            return
        }
        setSubmitting(true)
        updateManagersGroupAssignment({
            groups:group ? [group.id] : fields.groups.map((_group)=>(_group.value)),
            teams:fields.teams.map((_team)=>(_team.value))
        }).then((successful)=>{
            if(!successful){
                handleError()
            }
            handleSuccess()
        })
        /*if(!fields?.groupName || selectedUsers.length===0){
            handleSetNotification(false, "Fill out all required fields.", 5000)
            return
        }
        setSubmitting(true)
        const updatedFields = {...fields, users:selectedUsers.map((user)=>({value:user?.user_id, key:user?.isUploaded ? "email" : "user_id"}))}
        if(!!group){
            handleAssignUserToGroup({id:group?.id, users:updatedFields?.users}, handleSuccess, handleError)
        }else{
            createUserGroupsAndAssignUsers(updatedFields, handleSuccess, handleError)
        }*/
    }

    const userGroupsPromiseOptions = (inputValue) => {
        return new Promise((resolve) => {
            getUserGroupsForSelectDropdown(inputValue, (data) => {
                const result = data?.results;
                if (Array.isArray(result) && result.length > 0) {
                    resolve(
                        result
                            .filter((pre) => pre.name !== '')
                            .map((team) => {
                                return {
                                    value: team.id,
                                    label: team.name,
                                };
                            })
                    );
                } else {
                    resolve([]);
                }
            });
        });
    };

    const teamsPromiseOptions = (inputValue) => {
        return new Promise((resolve) => {
            getTeamsForSelectDropdown(inputValue, (data) => {
                const result = data?.results;
                if (Array.isArray(result) && result.length > 0) {
                    resolve(
                        result
                            .filter((pre) => pre.name !== '')
                            .map((team) => {
                                return {
                                    value: team.id,
                                    label: team.name,
                                };
                            })
                    );
                } else {
                    resolve([]);
                }
            });
        });
    };

    const handleMultiDropdownChange = (choice, target) => {
        setFields({
            ...fields,
            [target]: choice,
        });
    };

    return (
        <>
            <div style={{display: "flex"}} className="popup_div-nm_popup enroll-programs-modal active">
                <div className={`popup_cont-sk`}>
                    <div className="hf_popup program-enroll-popup active">
                        <div className="div-block-147 new_msg">
                            <div
                                className="text-block-135 mg-0">{!!group ? `Update ${group?.name} assignments` : "Assign a user group to a team manager"}</div>
                        </div>
                        <div className="div-block-228">
                            <div className="form-block-7 w-form">
                                <form onSubmit={e => e.preventDefault()} id="email-form-5" name="email-form-5"
                                      data-name="Email Form 5" method="get"
                                      data-wf-page-id="66e1c2afd7d305ab933f7a97"
                                      data-wf-element-id="5f64487e-caf3-b6dd-b426-6888986a7eb7"><label htmlFor="name-2"
                                                                                                       className="text-block-139-bd-f-soc">User Group
                                    {group ? " Name" : ""} *</label>
                                    {
                                        group ? (
                                            <input value={group?.name} disabled={true} readOnly={true}
                                                   className="text-field-3 w-input license-name-input" maxLength="256"
                                                   name="name-2"
                                                   data-name="Name 2" placeholder="Group name" type="text"
                                                   id="name-2"/>
                                        ) : (
                                            <Async
                                                isMulti
                                                cacheOptions
                                                classNamePrefix={'react-select'}
                                                defaultOptions
                                                loadOptions={userGroupsPromiseOptions}
                                                value={fields?.groups}
                                                options={fields?.groups}
                                                onChange={choice => handleMultiDropdownChange(choice, "groups")}
                                                isSearchable={true}
                                                placeholder={"Search for user groups..."}
                                            />
                                        )
                                    }
                                    <label htmlFor="name-2"
                                           className="text-block-139-bd-f-soc">Teams *</label>
                                    <Async
                                        isMulti
                                        cacheOptions
                                        classNamePrefix={'react-select'}
                                        defaultOptions
                                        loadOptions={teamsPromiseOptions}
                                        value={fields?.teams}
                                        options={fields?.teams}
                                        onChange={choice => handleMultiDropdownChange(choice, "teams")}
                                        isSearchable={true}
                                        placeholder={"Search for teams..."}
                                    />
                                </form>
                                <div className="w-form-done">
                                    <div>Thank you! Your submission has been received!</div>
                                </div>
                                <div className="w-form-fail">
                                    <div>Oops! Something went wrong while submitting the form.</div>
                                </div>
                            </div>
                        </div>
                        <div className="div-block-147 new_msg">
                            <div className="div-block-264 f-w">
                                <div onClick={() => setFocused(false)} data-w-id="5f64487e-caf3-b6dd-b426-6888986a7ec6"
                                     className="div-block-207 first active follow-user alt-btn cancel-program-enroll">
                                    <div className="text-block-111 red-text cf">Cancel</div>
                                </div>
                                <div onClick={!submitting ? handleSubmit : () => {
                                }} data-w-id="5f64487e-caf3-b6dd-b426-6888986a7ec9"
                                     className="div-block-207 first active follow-user redeem-btn mg-l16 program-enroll">
                                    <div className="text-block-111 red-text cf">{group ? "Update" : "Add"}</div>
                                    {submitting && <span style={{marginLeft: "5px"}}> <IBLSpinLoader size={15}
                                                                                                     color={"#FFF"}/> </span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default AddManagersGroupAssignment;