import React, { useContext, useEffect, useState } from 'react';
import { getTenantMetaData, updateTenantMetaData } from './utils/tenant';
import { AppDataContext } from '@iblai/ibl-web-react-common';

const useTenant = () => {
  const { tenantMetaData, setTenantMetaData } = useContext(AppDataContext);
  const METADATAS = [
    {
      slug: 'enable_sidebar_ai_mentor_display',
      label: 'Enable sidebar AI Mentor display',
      defaultValue: true,
    },
    {
      slug: 'enable_skills_leaderboard_display',
      label: 'Enable Skills Leaderboard display',
      defaultValue: true,
    },
    {
      slug: 'enable_public_profile_toggle_display',
      label: 'Enable public profile toggle display',
      defaultValue: false,
    },
  ];
  const [tenantMetaDataLoading, setTenantMetaDataLoading] = useState(false);

  useEffect(() => {
    setTenantMetaDataLoading(true);
    getTenantMetaData((data) => {
      setTenantMetaDataLoading(false);
      setTenantMetaData(data?.metadata ?? {});
    });
  }, []);

  const handleUpdateTenantMetaData = (_singleMetaData, callback) => {
    const metaDataToUpdate = {
      ...tenantMetaData,
      ..._singleMetaData,
    };
    updateTenantMetaData(metaDataToUpdate, callback);
  };

  return {
    handleUpdateTenantMetaData,
    tenantMetaDataLoading,
    tenantMetaData,
    METADATAS,
  };
};

export default useTenant;
