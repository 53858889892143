import React from 'react';
import {IBLSpinLoader} from "@iblai/ibl-web-react-common";
import EmptyTableDataInfographic from "../EmptyTableDataInfographic/EmptyTableDataInfographic";
import './UsersAdvancedSearchModal.css'
const UsersAdvancedSearchModal =
    ({
         loadingFacets=false,
         advancedSearchFacet=[],
         selectedAdvancedSearchQueryPreviewList=[],
         handleFilterCheckboxChange=()=>{},
         setActiveFacet=()=>{},
         activeFacet="",
         selectedAdvancedSearchQuery=()=>{},
         setAdvancedSearchFocused=()=>{},
         handleClearFilter=()=>{},
         handleAdvancedSearchFilterSubmit=()=>{},
}) => {
    return (
        <div style={{display: "flex"}} react-component="InvitePopup"
             className="popup_div-nm_popup user-advanced-search-popup">
            <div className="popup_cont-sk">
                <div className="hf_popup p-top p-relative advanced-filter">
                    <div className="div-block-147 new_msg">
                        <div className="text-block-135 mg-0">Advanced Filter</div>
                    </div>
                    {
                        loadingFacets ? <IBLSpinLoader size={30} color={"#FFF"} containerHeight={"200px"}/> : (
                            advancedSearchFacet.length > 0 ? (
                                <div className="w-layout-vflex flex-block-17">
                                    <div className="form-block-29 w-form">
                                        <form id="email-form-6" name="email-form-6" data-name="Email Form 6"
                                              method="get"
                                              className="form-19" data-wf-page-id="66e1c2afd7d305ab933f7a97"
                                              data-wf-element-id="caf75b14-4ec0-5e76-23ab-cca562c898db">
                                            <div className="w-layout-vflex advanced-filter-container">
                                                {
                                                    selectedAdvancedSearchQueryPreviewList.length > 0 && (
                                                        <div
                                                            className="w-layout-hflex advanced-filter-pill-preview-block">
                                                            {
                                                                selectedAdvancedSearchQueryPreviewList.map((query, index) => (
                                                                    <div key={`advanced-filter-pill-${index}`}
                                                                         className="w-layout-vflex advanced-filter-pill">
                                                                        <div
                                                                            className="advanced-filter-pill-label capitalize">{query?.facet} &gt; {String(query?.subFacet).replaceAll("_", " ")}
                                                                            {" : "}
                                                                            {query?.term}
                                                                        </div>
                                                                        <div
                                                                            onClick={() => handleFilterCheckboxChange(query?.value, {...query})}
                                                                            className="w-layout-hflex advanced-filter-pill-closer">
                                                                            <img
                                                                                src="/images/x-5.svg"
                                                                                loading="lazy"
                                                                                alt=""/></div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    )
                                                }
                                                {
                                                    advancedSearchFacet.map((facet, index) => (
                                                        <div
                                                            key={`facet-${index}`}
                                                            className="w-layout-vflex advanced-filter-block">
                                                            <div
                                                                onClick={() => setActiveFacet(facet?.title)}
                                                                className={`w-layout-hflex filter-tab-menu ${activeFacet === facet?.title || (index === 0 && !activeFacet) ? "active" : ""}`}>
                                                                <h5 className="heading-6 capitalize">{facet?.title}</h5>
                                                                <img
                                                                    src="/images/chevron-down-1.svg"
                                                                    loading="lazy"
                                                                    alt=""
                                                                    className="image-150"/>
                                                            </div>
                                                            <div
                                                                className={`w-layout-hflex filter-tab-content ${activeFacet === facet?.title || (index === 0 && !activeFacet) ? "active" : ""}`}>
                                                                {
                                                                    facet?.elements?.map((subFacet, subIndex) => (
                                                                        <div
                                                                            key={`${facet?.title}-subfacet-${subIndex}`}
                                                                            className="w-layout-vflex subfield-tab-content">
                                                                            <h6 className="subfield-tab-content-title capitalize">{String(subFacet?.title).replaceAll("_", " ")}</h6>
                                                                            {
                                                                                subFacet?.terms?.map((term, termIndex) => {
                                                                                    const value = `${facet?.title}__${subFacet?.title}=${term}`
                                                                                    const checked = selectedAdvancedSearchQuery.includes(value)
                                                                                    return (
                                                                                        <div title={term}
                                                                                             key={`${facet?.title}-${subFacet?.title}-term-${termIndex}`}
                                                                                             className="w-layout-hflex advanced-filter-box"
                                                                                             onClick={() => handleFilterCheckboxChange(value, {
                                                                                                 facet: facet?.title,
                                                                                                 subFacet: subFacet?.title,
                                                                                                 term: term,
                                                                                                 value: value
                                                                                             })}
                                                                                        >
                                                                                            <div
                                                                                                className="less-m-b w-embed">
                                                                                                <label><input
                                                                                                    type="checkbox"
                                                                                                    checked={checked}
                                                                                                    readOnly={true}
                                                                                                    id="toggle"/><span></span></label>
                                                                                            </div>
                                                                                            <div
                                                                                                className="advanced-filter-box-title capitalize">{term}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </form>
                                        <div className="w-form-done">
                                            <div>Thank you! Your submission has been received!</div>
                                        </div>
                                        <div className="w-form-fail">
                                            <div>Oops! Something went wrong while submitting the form.</div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <EmptyTableDataInfographic label={"Advanced search filters not found."}/>
                            )
                        )
                    }
                    <div onClick={() => setAdvancedSearchFocused(false)}
                         data-w-id="caf75b14-4ec0-5e76-23ab-cca562c8997b"
                         className="w-layout-hflex flex-block-21">
                        <img src="/images/x-3.svg" loading="lazy" width="24" alt="" className="image-147"/></div>
                    {
                        selectedAdvancedSearchQuery.length > 0 && (
                            <div className="div-block-147 new_msg">
                                <div className="div-block-264 f-w">
                                    <div onClick={() => handleClearFilter()}
                                         className="div-block-207 first active follow-user alt-btn cancel-program-enroll">
                                        <div className="text-block-111 red-text cf">Clear</div>
                                    </div>
                                    <div onClick={() => handleAdvancedSearchFilterSubmit()}
                                         data-w-id="b270d753-549e-29c8-7bf2-046af4b74811"
                                         className="div-block-207 first active follow-user redeem-btn mg-l16 program-enroll">
                                        <div className="text-block-111 red-text cf">Filter</div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>
        </div>
    );
};

export default UsersAdvancedSearchModal;