import React, {useContext, useEffect, useState} from 'react';
import './TeamsUsersAssignmentTabContent.css'
import {IBLSpinLoader} from "@iblai/ibl-web-react-common";
import dayjs from "dayjs";
import UsersAdvancedSearchModal from "../../../../../components/UsersAdvancedSearchModal/UsersAdvancedSearchModal";
import UsersPreviewBlock from "../../../../../components/UsersPreviewBlock/UsersPreviewBlock";
import EmptyTableDataInfographic from "../../../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic";
import {getFormattedFacet, getTableSizes} from "../../../../../hooks/helpers";
import {useDebounce} from "../../../../../hooks/useDebounce";
import {getTeamLearnersList} from "../../../../../hooks/utils/teams";
import useLicensingUsers from "../../../../../hooks/useLicensingUsers";
import useTeams from "../../../../../hooks/useTeams";

const TeamsUsersAssignmentTabContent =
    ({
         selectedUsers=[], setSelectedUsers=()=>{}
     }) => {

    //LEARNERS
    const TABLE_SIZES = getTableSizes();

    const [search, setSearch] = useState('');
    const [size, setSize] = useState(TABLE_SIZES[0]);
    const [page, setPage] = useState(1);
    const {getUsersForSelectDropdown, getAdvancedFilterUsersForSelectDropdown} = useLicensingUsers({search, size, page})

    const [searchTerm, setSearchTerm] = useState("")
    const [searching, setSearching] = useState(false)
    const [searchedUsers, setSearchedUsers] = useState([])
    const [allSearchedUserChecked, setAllSearchedUserChecked] = useState(false)

    const [groupUsersLoading, setGroupUsersLoading] = useState(false)

    const handleUserSearch = () => {
        setAllSearchedUserChecked(false)
        if(searchTerm.length < 2) {
            setSearchedUsers([])
            return
        }
        setSearching(true)
        getUsersForSelectDropdown(searchTerm, (data)=>{
            const result = data?.results
            if(Array.isArray(result) && result.length > 0){
                setSearchedUsers(result)
            }else{
                setSearchedUsers([])
            }
            setSearching(false)
        })
    }

    const handleRetrieveUser = (userID) => {
        setSelectedUsers((old)=>old.filter((user)=>user?.user_id!==userID))
    }

    const userIsAlreadySelected = (user) => {
        return selectedUsers.findIndex((pre)=>pre.user_id===user.user_id)!==-1
    }

    const handleSearchedUserCheck = (user) => {
        if(userIsAlreadySelected(user)){
            //checked
            handleRetrieveUser(user.user_id)
        }else{
            handleAddUserToSelectedUsers(user)
        }
    }

    const handleAllSearchedUsersCheck = () => {
        if(allSearchedUserChecked){
            handleRetrieveAllSearchedUsersToSelectedUsers()
            setAllSearchedUserChecked(false)
        }else{
            handleAddAllSearchedUsersToSelectedUsers()
            setAllSearchedUserChecked(true)
        }
    }

    const handleAddAllSearchedUsersToSelectedUsers = () => {
        if(searchedUsers.length > 0){
            searchedUsers.forEach((user)=>{
                handleAddUserToSelectedUsers(user)
            })
        }
    }

    const handleRetrieveAllSearchedUsersToSelectedUsers = () => {
        if(searchedUsers.length > 0){
            searchedUsers.forEach((user)=>{
                handleRetrieveUser(user.user_id)
            })
        }
    }

    const handleAddUserToSelectedUsers = (user) => {
        if(!userIsAlreadySelected(user)){
            setSelectedUsers((old)=>[
                user,
                ...old
            ])
        }
    }

    useDebounce(handleUserSearch, 200, [searchTerm])

    useDebounce(()=>{
        if(selectedUsers.length === 0){
            setAllSearchedUserChecked(false)
        }
    }, 200, [selectedUsers])

    //ADVANCED SEARCH

    const [advancedSearchFocused, setAdvancedSearchFocused] = useState(false)
    const [advancedSearchFacet, setAdvancedSearchFacet] = useState([])
    const [selectedAdvancedSearchQuery, setSelectedAdvancedSearchQuery] = useState([])
    const [selectedAdvancedSearchQueryPreviewList, setSelectedAdvancedSearchQueryPreviewList] = useState([])
    const [loadingFacets, setLoadingFacets]= useState(false)
    const [activeFacet, setActiveFacet] = useState("")

    const handleLoadAdvancedSearchFacets = () => {
        if(advancedSearchFocused && advancedSearchFacet.length === 0){
            setLoadingFacets(true)
            getAdvancedFilterUsersForSelectDropdown({}, (data)=>{
                const formattedFacets = getFormattedFacet(data?.facets)
                setAdvancedSearchFacet(formattedFacets)
                setLoadingFacets(false)
            })
        }

    }

    const handleFilterCheckboxChange = (value, previewData) => {
        if(selectedAdvancedSearchQuery.includes(value)){
            setSelectedAdvancedSearchQuery((old)=>old.filter((query)=>query!==value))
            setSelectedAdvancedSearchQueryPreviewList((old)=>old.filter((data)=>data?.value!==value))
        }else{
            setSelectedAdvancedSearchQuery((old)=>[
                value,
                ...old
            ])
            setSelectedAdvancedSearchQueryPreviewList((old)=>[
                previewData,
                ...old
            ])
        }
    }

    const handleClearFilter = () => {
        setSelectedAdvancedSearchQueryPreviewList([])
        setSelectedAdvancedSearchQuery([])
        setActiveFacet("")
        setAdvancedSearchFocused(false)
        setSearchedUsers([])
    }

    const handleAdvancedSearchFilterSubmit = () => {
        setAdvancedSearchFocused(false)
        setSearchTerm("")
        setSearching(true)
        getAdvancedFilterUsersForSelectDropdown(selectedAdvancedSearchQuery.join("&"), (data)=>{
            if(Array.isArray(data?.results) && data?.results.length > 0){
                const seen = new Set();
                setSearchedUsers(data?.results.filter((pre)=>{
                    const userInfo = pre?.user_info;
                    if (!userInfo) return false; // Skip if no user_info exists
                    const userId = userInfo.id; // Get the user ID
                    if (seen.has(userId)) return false; // Skip if user ID is already seen
                    seen.add(userId); // Add user ID to the Set
                    return true; // Keep this user
                }).map((user)=>({
                    ...user?.user_info,
                    profile_image:user?.user_info?.public_metadata?.profile_image?.image_url_full,
                    added_on:user?.user_info?.date_joined
                })))
            }else{
                setSearchedUsers([])
            }
            setSearching(false)
        })
    }

    useDebounce(handleLoadAdvancedSearchFacets, 200, [advancedSearchFocused])

    return (
        <>
            <div data-w-tab="Individual" className="w-tab-pane w--tab-active">
                <div className="w-layout-vflex flex-block-36">
                    <div
                        className="w-layout-hflex reports-filters-form-content">
                        <div className="w-layout-vflex reports-filter-box">
                            <h5 className="reports-filter-box-title">Learners</h5>
                            <div
                                className="w-layout-vflex reports-filter-box-search-block flex-d-row gap-10">
                                <div className="w-layout-hflex flex-block-14">{!searching
                                    ?
                                    <img src="/images/search-1.svg"
                                         loading="lazy" alt=""
                                         className="image-143"/>
                                    :
                                    <span style={{width: "17px"}}><IBLSpinLoader size={17}
                                                                                 color={"#FFF"}/></span>}<input
                                    className="text-field-10 w-input" value={searchTerm}
                                    onChange={e => setSearchTerm(e?.target?.value)}
                                    maxLength="256" name="field-6"
                                    data-name="Field 6"
                                    placeholder="Search by name, username, email..."
                                    type="text" id="field-6"/>
                                </div>
                                <div className="w-layout-hflex flex-block-27">
                                    <div
                                        onClick={() => setAdvancedSearchFocused(true)}
                                        data-w-id="f2cc1e8b-a88d-ee0c-34cc-0da31021b8cc"
                                        className="div-block-207 first active follow-user alt-btn">
                                        <div className="text-block-111 red-text cf w-max-content">Advanced
                                            Search
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-layout-hflex flex-block-38">
                        <div react-component="table"
                             className="table user-search-table _100p">
                            {
                                searchedUsers.length > 0 && (

                                    <>
                                        <div className="th">
                                            <div className="tc _10 centered">
                                                <div
                                                    className="div-block-2 options options_trigger no-margin centered">
                                                    <div className="w-embed"><label><input
                                                        checked={allSearchedUserChecked}
                                                        onChange={handleAllSearchedUsersCheck}
                                                        type="checkbox"
                                                        id="toggle"/><span></span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tc _60 centered">
                                                <div className="w_sort">
                                                    <div
                                                        className="text-block-134 fa_icon sort hidden">
                                                    </div>
                                                    <div className="sm">Name</div>
                                                </div>
                                            </div>
                                            <div className="tc _30 centered">
                                                <div className="w_sort">
                                                    <div className="sm">Date Added</div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            searchedUsers.map((user) => (
                                                (
                                                    <div className="tr "
                                                         onClick={() => handleSearchedUserCheck(user)}>
                                                        <div className="tc tcell _10 no-pad">
                                                            <div
                                                                className="div-block-2 options options_trigger no-margin centered">
                                                                <div className="w-embed"><label><input
                                                                    type="checkbox" id="toggle"
                                                                    readOnly={true}
                                                                    checked={selectedUsers.findIndex((pre) => pre.user_id === user.user_id) !== -1}
                                                                /><span></span></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            data-w-id="03fefb47-44d1-aba8-5c75-91d1533071dc"
                                                            className="tc tcell _60 no-pad-y">
                                                            <a href="#"
                                                               onClick={e => e.preventDefault()}
                                                               className="tl-2 np"><span
                                                                className="nb">{user?.name || user?.username || user?.email}</span></a>
                                                        </div>
                                                        <div className="tc tcell _30 no-pad-y">
                                                            <div
                                                                className="m smaller">{user?.added_on ? dayjs(user?.added_on).format("MMM DD, YYYY") : "-"}</div>
                                                        </div>
                                                    </div>
                                                )
                                            ))
                                        }
                                        {
                                            searchTerm && searchedUsers.length === 0 && !searching &&
                                            <EmptyTableDataInfographic label={"No user found"}/>
                                        }
                                    </>
                                )
                            }
                        </div>
                        {
                            selectedUsers.length > 0 && (
                                <UsersPreviewBlock {...{
                                    selectedUsers,
                                    setSelectedUsers,
                                    handleRetrieveUser,
                                    searchedUsers,
                                    /*nonFloating:true,
                                    fullHeight:true,*/
                                    title: "Learners Preview",
                                    inLayoutPreview: true,
                                    headingTitleSmaller: true
                                }} />
                            )
                        }
                    </div>
                </div>
            </div>
            {
                advancedSearchFocused && <UsersAdvancedSearchModal {...{
                    loadingFacets,
                    advancedSearchFacet,
                    selectedAdvancedSearchQueryPreviewList,
                    handleFilterCheckboxChange,
                    setActiveFacet,
                    activeFacet,
                    selectedAdvancedSearchQuery,
                    setAdvancedSearchFocused,
                    handleClearFilter,
                    handleAdvancedSearchFilterSubmit
                }} />
            }
        </>

    );
};

export default TeamsUsersAssignmentTabContent;