import React, {useState} from 'react';
import {getLearnersReports, getTeamsReports} from "./utils/reports";
import {useDebounce} from "./useDebounce";

const useTeamReports = ({filters={}, page=1}) => {

    const [reports, setReports] = useState([])
    const [reportsLoading, setReportsLoading] = useState(false)
    const [pagination, setPagination] = useState({
        totalItems:0,
        currentPage:1,
        totalPages:0,
        pageSize:0
    });

    const handleFetchLearnerReports = async () => {
        setReportsLoading(true)
        const query = {
            page,
            ...filters
        }
        const _reports = await getTeamsReports(query)
        setReports(Array.isArray(_reports?.data) ? _reports.data : [])
        setPagination({
            totalItems:  _reports?.pagination?.totalItems,
            currentPage: _reports?.pagination?.currentPage,
            totalPages: _reports?.pagination?.totalPages,
            pageSize: _reports?.pagination?.perPage,
        });
        setReportsLoading(false)
    }

    useDebounce(handleFetchLearnerReports, 200, [
        page, filters
    ])
    return {reports, reportsLoading, pagination}
};

export default useTeamReports;