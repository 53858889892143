import React, {useState} from 'react';
import {IBLSpinLoader} from "@iblai/ibl-web-react-common";
import EmptyTableDataInfographic from "../EmptyTableDataInfographic/EmptyTableDataInfographic";
import TableSizeDropdown from "../TableSizeDropdown/TableSizeDropdown";
import useAssignmentsPerLicense from "../../hooks/useAssignmentsPerLicense";
import {getTableSizes} from "../../hooks/helpers";
import ReactPaginate from "react-paginate";
import {useTranslation} from "react-i18next";
import './UsersAssignmentsListPerContent.css'
import {useDebounce} from "../../hooks/useDebounce";
import {getUserAssignments, unAssignContent} from "../../hooks/utils/contentAssignment";

const UsersAssignmentsListPerContent = ({
                                       username, setFocused = () => {
    }
                                   }) => {
    const { t } = useTranslation();
    const TABLE_SIZES = getTableSizes();
    const [isGroup, setIsGroup] = useState(false)
    const [refresh, setRefresh] = useState(null)
    const [focusedContent, setFocusedContent] = useState(null)
    const [size, setSize] = useState(TABLE_SIZES[0]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(TABLE_SIZES[0]);
    const contentListDropdownOptions = [
        {
            value:"course",
            label:"Courses",
        },
        {
            value:"program",
            label:"Programs",
        },
        {
            value:"pathway",
            label:"Pathways",
        },

    ]
    const [currentContentType, setCurrentContentType] = useState(contentListDropdownOptions[0].value)
    const [loading, setLoading] = useState(false)
    const [content, setContent] = useState([])
    const [pagination, setPagination] = useState({
        totalItems:0,
        currentPage:1,
        totalPages:0,
        pageSize:0
    });
    
    useDebounce(()=>{
        setLoading(true)
        getUserAssignments(currentContentType, (data)=>{
            setContent(data?.results ?? [])
            setPagination({
                totalItems:  data?.count,
                currentPage: page,
                totalPages: Math.ceil(data?.count / size),
                pageSize: size,
            });
            setLoading(false)
        }, username)
    }, 100, [currentContentType, page, refresh])

    const getContentName = (contentData) =>{
        switch (currentContentType){
            case "course":
                return contentData?.course_name
            case "program":
                return contentData?.program_name
            case "pathway":
                return contentData?.pathway_name

        }
    }
    
    //const {loading, assignments, unAssignLicense, pagination} = useAssignmentsPerLicense({contentType, isGroup, license, refresh, page, size})

    const paginate = ({ selected }) => {
        setPage(selected + 1);
    };

    const handleDropdownChange = (e) => {
        setPage(1)
        setCurrentContentType(e?.target?.value)
        //setIsGroup(e?.target?.value === "group")
    }

    const handleUnAssignmentCallback = (status) => {
        setFocusedContent(null)
        if(status === 200){
            setRefresh(Date.now())
        }else{
            //
        }
    }



    const handleAssignmentDeletion = (content) => {
        if (window.confirm(`Do you really want to unassign this ${currentContentType} from ${username}`)) {
            setFocusedContent(content)
            unAssignContent(currentContentType,content?.id).then(r =>{
                setFocusedContent(null)
                if(r.ok){
                    setRefresh(Date.now())
                }
            }).catch(()=>{
                setFocusedContent(null)
            })
            //unAssignLicense({assignment, isGroup, handleUnAssignmentCallback})
        }
    }

    return (
        <div style={{display: "flex"}} react-component="InvitePopup"
             className="popup_div-nm_popup user-group-list-popup">
            <div className="popup_cont-sk">
                <div className="hf_popup p-top p-relative license-assignment-list-popup-container">
                    <div className="div-block-147 new_msg">
                        <div className="text-block-135 mg-0 assignment-title capitalize">{`${currentContentType} assignments ${content.length > 1 ? `(${content.length})` : ""}`}</div>
                        <div className="div-block-171">
                            <div className="sm">Show</div>
                            <div className="form-block w-form">
                                <form
                                    id="email-form-2"
                                    name="email-form-2"
                                    data-name="Email Form 2"
                                    method="get"
                                    data-wf-page-id="657af3ad1367da03b3b125d5"
                                    data-wf-element-id="4386085a-1eef-af03-c6b1-7a5a39f298eb"
                                >
                                    <select
                                        id="field-2"
                                        name="field-2"
                                        data-name="Field 2"
                                        prop-events-names="onChange"
                                        prop-events-value-onchange="handleEntriesDropdownOnChange"
                                        onChange={handleDropdownChange}
                                        className="show w-select"
                                        style={{minWidth: "90px"}}
                                    >
                                        {
                                            contentListDropdownOptions.map((contentType)=> (
                                                <option selected={currentContentType===contentType.value} value={contentType.value}>{contentType.label}</option>
                                            ))
                                        }
                                    </select>
                                </form>
                                <div className="w-form-done">
                                    <div>Thank you! Your submission has been received!</div>
                                </div>
                                <div className="w-form-fail">
                                    <div>Oops! Something went wrong while submitting the form.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-layout-vflex flex-block-18">
                        <div className="w-layout-vflex table-container-2">
                            <div className="w-layout-hflex table-header invited-assignments-table-header"
                                 style={{width: "100%"}}>
                                <div className="table-block p-x" style={{width: "90%", maxWidth: "90%"}}>
                                    <h5 className="table-title capitalize">{currentContentType} name</h5>
                                </div>
                                {/*<div className="table-block _55">
                                    <h5 className="table-title">EMAIL</h5>
                                </div>*/}
                                <div className="table-block _10">

                                </div>
                            </div>
                            {
                                loading ?
                                    <IBLSpinLoader size={25} color={"#FFF"} containerHeight={"150px"}/>
                                    :
                                    content.length > 0 ?
                                        content.map((_content, index) => (
                                            <div key={`group-user-${index}`} className="w-layout-vflex flex-block-19">
                                                <div style={{width: "100%"}}
                                                     className="w-layout-hflex table-body-row greyish-bg-in-darkmode invited-assignments-table-row">
                                                    <div className="table-block _45 p-x"
                                                         style={{width: "90%", maxWidth: "90%"}}>
                                                        <div
                                                            className="table-text">{getContentName(_content)}</div>
                                                    </div>
                                                    <div onClick={focusedContent ? () => {
                                                    } : () => handleAssignmentDeletion(_content)}
                                                         className="table-block _10 y-center">
                                                        {
                                                            focusedContent?.id === _content?.id ?
                                                                <IBLSpinLoader size={17} color={"#FFF"}/> :
                                                                <img src="/images/trash-2.svg" loading="lazy" alt=""
                                                                     className="image-146"/>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <EmptyTableDataInfographic style={{width: "100%"}}/>
                            }
                            {
                                content.length > 0 && (
                                    <div className="tf pagination-container">
                                        <div className="div-block-289">
                                            <div className="info-3">
                                                {t('Showing')}{' '}
                                                {(page - 1) * limit + 1}{' '}
                                                {t('to')}{' '}
                                                {Math.min(page * limit, pagination.totalItems)}{' '}
                                                {t('of')} {pagination.totalItems}
                                            </div>
                                            {
                                                pagination.totalItems > size && (
                                                    <ReactPaginate
                                                        onPageChange={paginate}
                                                        pageCount={pagination.totalPages}
                                                        forcePage={page - 1}
                                                        previousLabel={'Prev'}
                                                        nextLabel={'Next'}
                                                        containerClassName={'pagination'}
                                                        pageLinkClassName={'page-number'}
                                                        previousLinkClassName={'page-number'}
                                                        nextLinkClassName={'page-number'}
                                                        activeLinkClassName={'active'}
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                    {/*<div className="w-layout-hflex flex-block-20">
                        <div className="pagination">
                            <div className="arrow-invite-previous-disabled p-item">
                                <div className="text-block fa_icon fa-arrow disabled"></div>
                            </div>
                            <div className="p-active p-item">
                                <div>1</div>
                            </div>
                            <div className="p-item arrow-next disabled">
                                <div className="text-block fa_icon fa-arrow"></div>
                            </div>
                        </div>
                    </div>*/}
                    <div onClick={() => setFocused(null)} data-w-id="4ba06e75-1771-d0a8-0557-d57c1a58e6cd"
                         className="w-layout-hflex flex-block-21"><img src="/images/x-3.svg" loading="lazy" width="24"
                                                                       alt="" className="image-147"/></div>
                </div>
            </div>
        </div>
    );
};

export default UsersAssignmentsListPerContent;