import {useContext, useEffect, useRef, useState} from 'react';
import {
    assignUserToGroup,
    createOrUpdateUserGroup,
    getAdvancedFilterUsersList,
    getLicensingUserGroupList,
    getUsersList
} from "./utils/licensing";
import {useDebounce} from "./useDebounce";
import {getTenant, slugify} from "./helpers";
import {AppDataContext} from "@iblai/ibl-web-react-common";  // Replace with the correct path

const useLicensingUsers = ({search = "", page = 1, size = 10}) => {
    const {setNotification} = useContext(AppDataContext)
    const bulkUploadInputRef = useRef(null);
    const [bulkFileUploading, setBulkFileUploading] = useState(false)
    const MAX_FILE_SIZE = 1024 * 1024 * 10 //10Mb
    const getUsersForSelectDropdown = (input, callback) => {
        return getUsersList({
            limit: size,
            page,
            query: input,
        }, callback, callback)
    }

    const getAdvancedFilterUsersForSelectDropdown = (query, callback) => {
        return getAdvancedFilterUsersList(query, callback, callback)
    }


    const createUserGroupsAndAssignUsers = ({groupName, users}, successCallback, errorCallback) => {
        const groupID = slugify(groupName) + '-' + Date.now()
        createOrUpdateUserGroup({
            name: groupName,
            group_id: groupID
        }, (data) => {
            if (!data?.id) {
                setNotification({
                    msg: "An error occurred when creating the user group.",
                    success: false
                })
                return
            }
            handleAssignUserToGroup({id: data?.id, users}, successCallback, errorCallback)
        })
    }

    const handleAssignUserToGroup = ({id, users}, successCallback, errorCallback) => {
        assignUserToGroup({
            id,
            users: users.map((user) => {
                return {
                    [user.key]: user.value
                }
            })
        }, (successful) => {
            if (!successful) {
                setNotification({
                    msg: "An error occurred when assigning users to this user group.",
                    success: false
                })
                return
            }
            successCallback()
        })
    }

    const handleCSVBulkUploadChange = (e, callback) => {
        if (!e && !e?.target?.files[0]) {
            return;
        }
        const file = e?.target?.files[0];
        if (file.size >= MAX_FILE_SIZE) {
            setNotification({
                msg: "Image card size must not exceed 10Mb",
                success: false
            })
            return;
        }
        bulkUploadInputRef.current.value = '';
        handleReadCSVFile(file, callback)
    }

    const handleReadCSVFile = (selectedFile, callback) => {
        if (selectedFile) {
            setBulkFileUploading(true)
            const reader = new FileReader();

            reader.onload = function (e) {
                const content = e.target.result;
                const lines = content
                    .split(/[\r\n]+/)
                    .filter((line) => line.trim() !== '');

                // Determine the separator (comma or semicolon)
                const separator = lines[0].includes(';') ? ';' : ',';

                const parsedData = lines.slice(1).map((line) => {
                    const [userIdentifier] = line.split(separator);
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    const entry = {};
                    if (emailRegex.test(userIdentifier)) {
                        entry.email = userIdentifier;
                    }
                    return entry;
                });
                callback(parsedData)

            }

            reader.onerror = function (e) {
                setNotification({
                    msg: "An error occurred when reading CSV file. Please try again",
                    success: false
                })
            };
            reader.readAsText(selectedFile);
        }
    }

    return {
        getUsersForSelectDropdown,
        createUserGroupsAndAssignUsers,
        handleAssignUserToGroup,
        getAdvancedFilterUsersForSelectDropdown,
        bulkUploadInputRef,
        handleCSVBulkUploadChange, bulkFileUploading,
        setBulkFileUploading
    }
};

export default useLicensingUsers
