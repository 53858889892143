import {useEffect, useState} from 'react';
import {useDebounce} from "./useDebounce";
import {getTeamsList} from "./utils/teams";  // Replace with the correct path

const useTeamsList = ({search="", page=1, size=10, refresher=null}) => {
    const [teams, setTeams] = useState([]);
    const [teamsLoading, setTeamsLoading] = useState(false);
    const [pagination, setPagination] = useState({
        totalItems:0,
        currentPage:1,
        totalPages:0,
        pageSize:0
    });


    const handleTeamListRetrieval = (data) => {
        setTeams(Array.isArray(data?.results) ? data.results : [])
        setPagination({
            totalItems:  data?.count,
            currentPage: page,
            totalPages: Math.ceil(data?.count / size),
            pageSize: size,
        });
        setTeamsLoading(false)
    }

    useDebounce(()=>{
        setTeamsLoading(true)
        getTeamsList( {
            page_size:size,
            page,
            ...(!!search ? {
                query:search
            } : {})
        }, handleTeamListRetrieval, handleTeamListRetrieval)
    }, 200, [search, page, size, refresher])

    return {
        teams,
        teamsLoading,
        pagination
    }


};

export default useTeamsList;
