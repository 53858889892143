import React, {useState} from 'react';
import {Link, Outlet} from "react-router-dom";
import './TeamsAssignmentCreationParentViewContent.css'

const TABS = [
    {
        label:"Courses",
        slug:"courses",
    },
     {
        label:"Programs",
        slug:"programs",
    },
     {
        label:"Pathways",
        slug:"pathways",
    },
    {
        label:"Skills",
        slug:"skills",
    },
     /*{
        label:"Credentials",
        slug:"credentials",
    },*/
]

const TeamsAssignmentCreationParentViewContent = () => {
    const [currentTab, setCurrentTab] = useState(TABS[0].slug)
    const [submitting, setSubmitting] = useState(false)
    return (
        <div className="p-row r-r">
            <div className="div-block-204 f-w">
                <div className="div-block-183">
                    <div className="div-block-205">
                        <div className="div-block-206"/>
                        <div className="text-block-113">Add {currentTab} assignment</div>
                    </div>
                    <div className="div-block-308">
                        <Link to={`/teams/assignments/${currentTab}`} className="link-block-22 w-inline-block">
                            <div data-w-id="2e311dd0-e1c2-0ab6-f2a1-1e492e5123a6"
                                 className="div-block-207 first active follow-user alt-btn issue-popup">
                                <div className="text-block-111">Back to assignments</div>
                            </div>
                        </Link>
                        <a data-w-id="0f0fbe27-ba22-528c-b845-669b6f0cebd5" href="#"
                           className="link-block-22 hidden w-inline-block">
                            <div data-w-id="0f0fbe27-ba22-528c-b845-669b6f0cebd6"
                                 className="div-block-207 first active follow-user create-cred-popup">
                                <div className="text-block-111">Add License</div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="w-layout-vflex flex-block-35">
                    <div data-current="Platform" data-easing="ease" data-duration-in={300} data-duration-out={100}
                         className="tabs _100p w-tabs">
                        <div className="tabs-menu w-tab-menu">
                            {
                                TABS.map((tab, index)=>(
                                    <Link to={`/teams/assignments/add-new/${tab.slug}`} key={`content-type-${index}`} data-w-tab="Platform" className={`invite-tab-link w-inline-block w-tab-link ${currentTab === tab.slug ? "w--current" : ""}`}>
                                        <div>{tab.label}</div>
                                    </Link>
                                ))
                            }
                        </div>
                        <div className="w-tab-content">
                            <Outlet context={{currentTab, setCurrentTab, submitting, setSubmitting}} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default TeamsAssignmentCreationParentViewContent;