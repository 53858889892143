import {getTenant, isCurrentUserOnlyDepartmentManager} from "../helpers";
import {isJSON} from "@iblai/ibl-web-react-common";

export const getLearnersReports =  (queryParam={}) => {
    const currentTenant = localStorage.getItem("current_tenant")
    const platformOrg = isJSON(currentTenant) ? JSON.parse(currentTenant)?.org : null
    const param = new URLSearchParams({...queryParam,
        department_mode:1
    /*...(isCurrentUserOnlyDepartmentManager() ? {
        department_mode:1
    } : {})*/
    }).toString()
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/perlearner/orgs/${platformOrg}/learners?${param}`
    return fetch(url, {
        method: 'GET',
        //body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp)=>{
       return resp.json().then((data)=>data)
    }).catch(()=>({}))
}

export const getTeamsReports =  (queryParam={}) => {
    const param = new URLSearchParams({
        ...queryParam,
        department_mode:1
        /*...(isCurrentUserOnlyDepartmentManager() ? {
            department_mode:1
        } : {})*/
    }).toString()
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/user-groups/orgs/${getTenant()}?${param}`
    return fetch(url, {
        method: 'GET',
        //body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp)=>{
       return resp.json().then((data)=>data)
    }).catch(()=>({}))
}

export const getReportDownload = (reportName = "learner-metrics-report") => {
    const param = new URLSearchParams({
        department_mode:1
    }).toString()
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/reports/platforms/${getTenant()}/${reportName}?${param}`
    return fetch(url, {
        method: 'GET',
        //body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp)=>{
        return resp.json().then((data)=>data)
    }).catch(()=>false)
}

export const createNewReport = (reportData, callback) => {
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/reports/platforms/${getTenant()}/new`
    fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            department_mode:1,
            ...reportData,
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then(callback).catch(()=>callback({}));
}

export const getDownloadsListAPI = (callback) => {
    const param = new URLSearchParams({
        department_mode:1
    }).toString()
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/reports/platforms/${getTenant()}?${param}`
     fetch(url, {
        method: 'GET',
        //body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp)=>{
         resp.json().then((response)=>{
             callback(response?.data ?? [])
         })
    }).catch(()=>callback([]))
}