import React, {useEffect, useState} from 'react';
import { getTableSizes } from '../../hooks/helpers';
import Select from "react-select";
import './TableSizeDropdown.css'

const TABLE_SIZES = getTableSizes();

const TableSizeDropdown = ({ setSize, setPage }) => {
  const handleDropdown = (choice) => {
    setPage(1);
    setSelectedEntry(choice);
    setSize(choice?.value);
  };
  const TABLE_SIZES_ENTRIES = TABLE_SIZES.map((_size)=>({
    label: _size,
    value: _size,
  }))
  const [selectedEntry, setSelectedEntry] = useState(TABLE_SIZES_ENTRIES[0]);

  return (
      <form
          className="table-size-dropdown-container"
          id="email-form-2"
          name="email-form-2"
          data-name="Email Form 2"
          method="get"
          data-wf-page-id="657af3ad1367da03b3b125d5"
          data-wf-element-id="4386085a-1eef-af03-c6b1-7a5a39f298eb"
      >
        <Select
            className={"react-select-container"}
            classNamePrefix={'react-select'}
            value={selectedEntry}
            options={TABLE_SIZES_ENTRIES}
            onChange={handleDropdown}
            placeholder={"Select size"}
        />
      </form>
  );
};

export default TableSizeDropdown;
