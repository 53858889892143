import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import '@iblai/ibl-web-react-common/index.css';
import './ProfileView.css';
import UsersAssignmentsListPerContent from '../components/UsersAssignmentsListPerContent/UsersAssignmentsListPerContent';

const menu = [
  {
    link: './activity',
    name: 'Activity',
  },
  {
    link: './skills',
    name: 'Skills',
  },
  {
    link: './credentials',
    name: 'Credentials',
  },
  {
    link: './pathways',
    name: 'Pathways',
  },
  {
    link: './programs',
    name: 'Programs',
  },
  {
    link: './courses',
    name: 'Courses',
  },
  {
    link: './public-profile',
    name: 'Public Profile',
  },
];

const ProfileView = () => {
  const { username } = useParams();
  const iframeRef = useRef(null);
  const [pageTitle, setPageTitle] = useState('');
  const [iframeRandom, setIframeRandom] = useState(0);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [userAssignmentPopupOpen, setUserAssignmentPopupOpen] = useState(false);

  const sendMessage = () => {
    return setTimeout(() => {
      iframeRef.current &&
        iframeRef.current.contentWindow.postMessage(
          { reason: 'PROFILE_DISPLAY', message: { username } },
          '*'
        );
    }, 1000);
  };
  const sendToken = () => {
    setTimeout(() => {
      iframeRef.current?.contentWindow.postMessage(
        { reason: 'AUTH', message: { ...localStorage } },
        '*'
      );
    }, 1000);
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.reason === 'PROFILE_DISPLAY') {
        const page = event.data.message.page;
        setPageTitle(`${page}`);
        sendMessage();
      }
      if (event.data.reason === 'IFRAME_DIMENSION') {
        // iframeRef.current.style.height = `${event.data.message.height + 100}px`;
      }
      if (event.data.reason === 'IFRAME_READY') {
        sendToken();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [username]);

  useEffect(() => {
    let timeoutIds = [];
    if (!iframeLoaded) {
      iframeRef.current.style.visibility = 'hidden';
      iframeRef.current.addEventListener('load', () => {
        setIframeLoaded(true);
        timeoutIds.push(sendMessage());
      });
    } else {
      timeoutIds.push(
        setTimeout(() => {
          iframeRef.current.style.visibility = '';
        }, 1000)
      );
    }
    return () => {
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, [iframeLoaded, username]);

  return (
    <>
      <div className="div-block-183">
        <div className="div-block-205">
          <div className="div-block-206"></div>
          <div
            className="text-block-113 h-align"
            style={{ textTransform: 'capitalize' }}
          >
            {pageTitle} <span> | {username}</span>{' '}
            <span>
              {' '}
              <Link
                to="#"
                prop-events-value-onclick="handleAddNewCourseClick"
                prop-events-names="onClick"
                onClick={() => setUserAssignmentPopupOpen(true)}
                className="link-block-22 w-inline-block"
              >
                <div className="div-block-207 first active follow-user">
                  <div className="text-block-111">View Assignments</div>
                </div>
              </Link>
            </span>
          </div>
        </div>
      </div>
      <iframe
        title={`Profile for ${username}`}
        key={username}
        ref={iframeRef}
        src={`${process.env.REACT_APP_IBL_SKILLS_IFRAME_WIDGET_ORIGIN_URL}/profile/activity`}
      ></iframe>
      {userAssignmentPopupOpen && (
        <UsersAssignmentsListPerContent
          setFocused={setUserAssignmentPopupOpen}
          username={username}
        />
      )}
    </>
  );
};

export default ProfileView;
