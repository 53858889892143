import {getTenant} from "@iblai/ibl-web-react-common";
import {isCurrentUserOnlyDepartmentManager} from "../helpers";

export const createNotification = (data) => {
    const currentTenant = getTenant()
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/notification/v1/orgs/${currentTenant}/notification-builder/preview/`
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            ...data,
            ...(isCurrentUserOnlyDepartmentManager() ? {
                department_mode:true
            } : {})
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp) => {
        if(!resp.ok){
            return false
        }
       return resp.json().then((data)=>data)
    }).catch(()=>false);
}

export const sendNotification = (build_id) => {
    const currentTenant = getTenant()
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/notification/v1/orgs/${currentTenant}/notification-builder/send/`
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            build_id,
            ...(isCurrentUserOnlyDepartmentManager() ? {
                department_mode:true
            } : {})
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp) => {
        if(!resp.ok){
            return false
        }
        return resp.json().then((data)=>data)
    }).catch(()=>false);
}

export const getNotificationContext = () => {
    const param = new URLSearchParams({
        ...(isCurrentUserOnlyDepartmentManager() ? {
            department_mode:1
        } : {})
    }).toString()
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/notification/v1/orgs/${getTenant()}/notification-builder/context?${param}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp) => {
        if(!resp.ok){
            return false
        }
        return resp.json().then((data)=>data?.data?.channels)
    }).catch(()=>false);
}