import {useEffect, useRef, useState} from 'react';
import Async from 'react-select/async';
import Select from 'react-select';
import './CreateCredential.css'

import {
  getTenant,
  getUserId,
  IBLSpinLoader, isJSON,
  isValidHttpUrl,
} from '@iblai/ibl-web-react-common';
import {GoPaperclip} from "react-icons/go";
import useCreateCredentials from "../../../../hooks/useCreateCredentials";

const CreateCredential = ({
  credentialData,
  setCredentialData,
  setRefreshCredential,
  courseID=null,
    programID=null,
  notification,
  setNotification,
  loading,
  setLoading,
  setCreateCredentialPopupOpen,
}) => {
  const {uploadIconCredentialImage} = useCreateCredentials()
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentialData({
      ...credentialData,
      [name]: value,
    });
  };

const getCredentialIssuers = async () => {
  const currentTenant = localStorage.getItem("current_tenant")
  const tenantOrg = isJSON(currentTenant) ? JSON.parse(currentTenant)?.org : null
  const url = `${process.env.REACT_APP_IBL_DM_URL}/api/credentials/orgs/${getTenant()}/users/${getUserId()}/issuers/${tenantOrg}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('axd_token')}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch credential issuers');
    }

    const data = await response.json(); 
    return data || []; 
  } catch (err) {
    console.error('Error fetching credentials:', err);
    return [];
  }
};

const [issuersListForDropdown, setIssuersListForDropdown] = useState([])


  const loadIssuerOptions = () => {
    return getCredentialIssuers().then((data) => {
      const issueList = data.map((issuer) => ({
        value: issuer.entityId,
        label: issuer.name,
      }))
      setIssuersListForDropdown(issueList)
      return issueList;
    });
  };

  const handleIssuerChange = (issuer) => {
    setCredentialData({ ...credentialData, issuer: issuer.value });
  };

  const credentialTypeOptions = [
    { value: 'MICROCREDENTIAL', label: 'Microcredential' },
      ...(courseID ? [{ value: 'COURSE_CERTIFICATE', label: 'Course Certificate' }] : []),
      ...(programID ? [{ value: 'PROGRAM_CERTIFICATE', label: 'Program Certificate' }] : []),
  ];

  const issuingSinalOptions = [
    { value: 'COURSE_COMPLETED', label: 'Course Completed' },
    { value: 'COURSE_PASSED', label: 'Course Passed' },
  ];

  const handleIssuingSignalChange = (signal) => {
    setCredentialData({
      ...credentialData,
      signal: signal.value,
    });
  };


  const handleCredentialTypeChange = (credentialType) => {
    setCredentialData({
      ...credentialData,
      credentialType: credentialType.value,
    });
  };

const postCreateCredential = async (data) => {
  const url = `${process.env.REACT_APP_IBL_DM_URL}/api/credentials/orgs/${getTenant()}/users/${getUserId()}/`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('axd_token')}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      setNotification({
        success: false,
        msg: 'Something went wrong, please try again.',
      });
      
      throw new Error('Failed to create credential');
    }



    // Reset credential data
    setCredentialData({
      is_update : false,
      issuer: '',
      name: '',
      description: '',
      icon_image_id: '',
      thumbnailImage: '',
      backgroundImage: '',
      credentialType: '',
      signal: '',
      criteriaNarrative: '',
      criteriaUrl: '',
      ...(courseID ? {
        courses:[courseID]
      } : {
        programs:[programID]
      }),
      html_template: '',
      ccs_template: '',
    });
    setUploadedIconImageLink("")
    setUploadedImgName("")

    // You can uncomment the notification line if you want to set it
    // setNotification({
    //   success: true,
    //   msg: 'Credential created successfully',
    // });

    setRefreshCredential((prev) => !prev);
    window.alert('Credential created successfully.');
  
  } catch (err) {
    setNotification({
      success: false,
      msg: err.message || 'Something went wrong, please try again.',
    });
    
  } finally {
    setLoading(false);
  }
};

  const updateCredential = async (data) => {
     const url = `${process.env.REACT_APP_IBL_DM_URL}/api/credentials/orgs/${getTenant()}/users/${getUserId()}/${data.entityId}`;

     try {
       const response = await fetch(url, {
         method: 'PUT',
         headers: {
           'Content-Type': 'application/json',
           Authorization: `Token ${localStorage.getItem('axd_token')}`,
         },
         body: JSON.stringify(data),
       });

       if (!response.ok) {
         setNotification({
           success: false,
           msg: 'Something went wrong, please try again.',
         });

         throw new Error('Failed to update credential');
       }

       // Reset credential data
       setCredentialData({
         is_update : false,
         issuer: '',
         name: '',
         description: '',
         icon_image_id: '',
         thumbnailImage: '',
         backgroundImage: '',
         credentialType: '',
         signal:"",
         criteriaNarrative: '',
         criteriaUrl: '',
         ...(courseID ? {
           courses:[courseID]
         } : {
           programs:[programID]
         }),
         html_template: '',
         ccs_template: '',
       });
       setUploadedIconImageLink("")
       setUploadedImgName("")

       // You can uncomment the notification line if you want to set it
       // setNotification({
       //   success: true,
       //   msg: 'Credential created successfully',
       // });

       setRefreshCredential((prev) => !prev);
       window.alert('Credential updated successfully.');
     } catch (err) {
       setNotification({
         success: false,
         msg: err.message || 'Something went wrong, please try again.',
       });
       
     } finally {
       setLoading(false);
     }
  };

  useEffect(() => {
    // Clear notification when any input changes
    if (credentialData) {
      setNotification({ success: null, msg: null });
    }
  }, [credentialData, courseID, programID]);

  const handleSubmit = () => {
    console.log({credentialData})
    const {
      issuer,
      name,
      description,
      icon_image_id,
      credentialType,
      backgroundImage,
    } = credentialData;

    if (
      !issuer ||
      !name ||
      !description ||
      !icon_image_id ||
      !credentialType ||
      backgroundImage

    ) {
      setNotification({
        success: false,
        msg: 'All fields must be filled.',
      });
      return;
    }

    // icon mage and background image must be valid urls
    /*if (!isValidHttpUrl(iconImage)) {
      setNotification({
        success: false,
        msg: 'Icon Image must be a valid URL',
      });
      return;
    }*/

    if (backgroundImage && !isValidHttpUrl(backgroundImage)) {
      setNotification({
        success: false,
        msg: 'Background Image must be a valid URL',
      });
      return;
    }

    setLoading(true);
    const {iconImage, ...otherData} = credentialData
    if(credentialData.is_update){
      updateCredential(otherData);
    }else{
      postCreateCredential(otherData);
    }
  };

  const handleClose = () => {
    setCreateCredentialPopupOpen(false);
     setCredentialData({
       is_update: false,
       issuer: '',
       name: '',
       description: '',
       icon_image_id: '',
       thumbnailImage: '',
       backgroundImage: '',
       credentialType: '',
       criteriaNarrative: '',
       criteriaUrl: '',
       ...(courseID ? {
         courses:[courseID]
       } : {
         programs:[programID]
       }),
       html_template: '',
       ccs_template: '',
     });
     setNotification({ success: null, msg: null });
  };

  //ICON UPLOAD
  const [iconUploading, setIconUploading] = useState(false)
  const [uploadedIconImageLink, setUploadedIconImageLink] = useState("")
  const [uploadedImgName, setUploadedImgName] = useState("")
  const uploadInputElement = useRef(null)
  const MAX_FILE_SIZE = 1024 * 1024
  const handleImgUploadChange = async (e) => {
    if (!e) {
      return;
    }
    const file = e?.target?.files[0];
    if (file.size >= MAX_FILE_SIZE) {
      alert('Icon image must not exceed 1Mb');
      return;
    }
    let fileName = file.name;
    // Check if the file name exceeds 10 characters (excluding the extension)
    if (fileName.length > 10) {
      const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
      const baseName = fileName.substring(0, fileName.lastIndexOf('.'));
      // Truncate the base name and append the extension
      fileName = `${baseName.substring(0, 10 - fileExtension.length)}...${fileExtension}`;
    }
    setUploadedImgName(fileName)
    /*const blobImg = URL.createObjectURL(file);
    setUploadedIconImageLink(blobImg)*/
    uploadInputElement.current.value = '';
    //const base64 = await convertToBinary(e);
    const formdata = new FormData();
    formdata.append('name', fileName);
    formdata.append('image', file);
    handleImgUploadToAPI(formdata);
  };

  const handleImgUploadToAPI = (formData)=>{
    setIconUploading(true)
    uploadIconCredentialImage(formData, (data)=>{
      setIconUploading(false)
      if(data){
        setUploadedImgName(data?.name)
        setUploadedIconImageLink(data?.image)
        setCredentialData({
          ...credentialData,
          icon_image_id: data?.id
        })
      }else{
        setNotification({
          success: false,
          msg: 'Icon upload error. Please try again!',
        });
      }
    })

  }

  const handleImgUploadClick = () => {
    uploadInputElement.current.click();
  };

  const handleImgPreviewClick = () => {
    window.open(uploadedIconImageLink, '_blank');
  };

  useEffect(() => {
    if(credentialData.is_update){
      setUploadedImgName(credentialData.name)
      setUploadedIconImageLink(credentialData.iconImage)
    }
  }, [credentialData.is_update]);

  return (
    <div
      style={{ display: 'flex' }}
      className="popup_div-nm_popup create-credential-popup"
    >
      <div className="popup_cont-sk">
        <div className="hf_popup" style={{height: '600px', overflowY: 'auto'}}>
          <div className="div-block-147 new_msg">
            <div className="text-block-135 mg-0">{credentialData.is_update ? 'Update Credential' : 'Create Credential'}</div>
            {notification?.msg && (
              <div
                className={`license-notification-block ${!notification?.success ? 'error' : ''}`}
              >
                {notification?.msg}
              </div>
            )}
          </div>
          <div className="tabs w-tabs">
            <div className="w-tab-content">
              <div className="w-tab-pane w--tab-active">
                <div className="div-block-228 less-pad">
                  <div className="form-block-7 w-form">
                    <form>
                      <label
                          htmlFor="issuer"
                          className="text-block-139-bd-f-soc"
                      >
                        Issuer *
                      </label>
                      <Async
                          cacheOptions
                          classNamePrefix="react-select"
                          defaultOptions
                          loadOptions={loadIssuerOptions}
                          onChange={handleIssuerChange}
                          isSearchable={false}
                          placeholder={"Search..."}
                          value={issuersListForDropdown.find(option => option.value === credentialData?.issuer)}
                      />

                      <label htmlFor="name" className="text-block-139-bd-f-soc">
                        Credential Name *
                      </label>
                      <input
                          className="text-field-3 w-input"
                          name="name"
                          value={credentialData.name}
                          onChange={handleInputChange}
                      />

                      <label
                          htmlFor="description"
                          className="text-block-139-bd-f-soc"
                      >
                        Description *
                      </label>
                      <input
                          className="text-field-3 w-input"
                          name="description"
                          value={credentialData.description}
                          onChange={handleInputChange}
                      />

                      <label
                          htmlFor="credentialType"
                          className="text-block-139-bd-f-soc"
                      >
                        Credential Type *
                      </label>
                      <Select
                          classNamePrefix="react-select"
                          name="credentialType"
                          options={credentialTypeOptions} // Static options go here
                          onChange={handleCredentialTypeChange}
                          isSearchable={false} // Disable typing ability
                          value={credentialTypeOptions.find(option => option.value === credentialData?.credentialType)}
                      />
                      <label
                          htmlFor="credentialType"
                          className="text-block-139-bd-f-soc"
                      >
                        Issuing Signal
                      </label>
                      <Select
                          classNamePrefix="react-select"
                          name="signal"
                          options={issuingSinalOptions} // Static options go here
                          onChange={handleIssuingSignalChange}
                          isSearchable={false} // Disable typing ability
                          value={issuingSinalOptions.find(option => option.value === credentialData?.signal)}
                      />
                      {/*<label
                          htmlFor="iconImage"
                          className="text-block-139-bd-f-soc"
                      >
                        Icon Image *
                      </label>*/}
                      <div className={"credential-icon-upload-block text-block-139-bd-f-soc"}>
                        <div>Icon Image *</div>
                        {
                          iconUploading && (
                                <span className={"loader-block"}><IBLSpinLoader size={15} color={"#FFF"}/></span>
                            )
                        }
                        {
                          !iconUploading && uploadedIconImageLink && uploadedImgName && (
                                <div onClick={()=>handleImgPreviewClick()} className={"credential-file-upload-name-block"}><GoPaperclip color={"#FFF"} size={15}/>
                                  <span>{uploadedImgName}</span>
                                </div>
                            )
                        }

                      </div>
                      <div onClick={!iconUploading ? ()=>handleImgUploadClick() : ()=>{}}
                          className={`div-block-207 first active follow-user credential-icon-upload-btn ${iconUploading ? "credential-icon-upload-btn-disabled" : ""}`}
                      >
                        <div className="text-block-111 red-text cf">Upload</div>
                      </div>
                      <input
                          ref={uploadInputElement}
                          onChange={handleImgUploadChange}
                          type="file"
                          className="credential-icon-upload-input"
                          accept="image/*"
                      />
                      {/*<input
                          className="text-field-3 w-input"
                          name="iconImage"
                          value={credentialData.iconImage}
                          onChange={handleInputChange}
                      />*/}
                      <label
                          htmlFor="courses"
                          className="text-block-139-bd-f-soc"
                      >
                        Course *
                      </label>
                      <input
                          className="text-field-3 w-input"
                          name="courseId"
                          data-name="Course ID"
                          placeholder={`Enter the ${courseID ? "Course" : "Program"} ID`}
                          type="text"
                          id="course-id"
                          style={{backgroundColor: 'transparent'}}
                          value={courseID ?? programID}
                          readOnly
                      />

                      <label
                          htmlFor="html_template"
                          className="text-block-139-bd-f-soc"
                      >
                        HTML Template
                      </label>
                      <input
                          className="text-field-3 w-input"
                          name="html_template"
                          value={credentialData.html_template}
                          onChange={handleInputChange}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="div-block-147 new_msg">
            <div className="div-block-264 f-w">
              <div
                  onClick={handleClose}
                  data-w-id="04b212b6-6908-556e-c3c3-d1f2508be27f"
                  className="div-block-207 first active follow-user alt-btn cancel-invite"
              >
                <div className="text-block-111 red-text cf">Close</div>
              </div>
              <div
                  onClick={handleSubmit}
                  className="div-block-207 first active follow-user redeem-btn mg-l16"
              >
                <div className="text-block-111 red-text cf">{credentialData.is_update ? 'Update' : 'Create'}</div>
                {loading && (
                  <span style={{ marginLeft: '10px' }}>
                    <IBLSpinLoader size={15} color={'#FFF'} />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCredential;
