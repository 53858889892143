import React, {useContext} from 'react';
import {addTeams, deleteTeam, getTeamsList, updateTeamsUsers} from "./utils/teams";
import {AppDataContext} from "@iblai/ibl-web-react-common";
import {createOrUpdateUserGroup, getLicensingUserGroupList, getUsersList} from "./utils/licensing";

const useTeams = () => {

    const [submitting, setSubmitting] = React.useState(false);
    const {setNotification} = useContext(AppDataContext)

    const createTeamWithMembers = async ({teamData, users=[]}) => {
        setSubmitting(true)
       const teamInfo = await addTeams(teamData)
        if(!teamInfo?.id){
            setNotification({
                msg:"Error occurred while creating team",
                success:false
            })
            return false
        }
        const success = await updateTeamWithMembers({teamID:teamInfo?.id, users})
        if(success){
            setNotification({
                msg:`Team ${teamData?.name} created successfully.`,
                success:true
            })
            return true
        }else{
            await deleteTeam(teamInfo?.id)
            setNotification({
                msg:`An error occurred while creating team members. Reverting updates...`,
                success:false
            })
        }
        setSubmitting(false)
        return false
    }

    const updateTeamWithMembers = async ({teamID, users=[], showNotification=false}) => {
        const teamUsers = await updateTeamsUsers({teamID, users})
        if(!teamUsers){
            setNotification({
                msg:"Error occurred while creating team managers",
                success:false
            })
            return false
        }
        if(showNotification){
            setNotification({
                msg:"Team managers updated successfully",
                success:true
            })
        }
        return true
    }

    const getTeamsForSelectDropdown = (input, callback) => {
        return getTeamsList({
            page_size:10,
            page:1,
            ...(!!input ? {
                query:input
            } : {})
        }, callback, callback)
    }

    const getUserGroupsForSelectDropdown = (input, callback) => {
        return getLicensingUserGroupList({
            page_size:10,
            page:1,
            ...(input ? {
                query:input
            } : {})
        }, callback, callback)
    }

    const updateManagersGroupAssignment = (assignmentData) => {
        const groups = assignmentData.groups
        const teams = assignmentData.teams
        let promises = []
        groups.forEach((groupID)=>{
            promises.push(new Promise((_resolve)=>{
                createOrUpdateUserGroup({
                    id:groupID,
                    departments:teams
                }, _resolve)
            }))
        })
       return Promise.all(promises).then((resp)=>resp.filter((pre)=>pre).length === promises.length)
    }



    return {createTeamWithMembers,
        updateTeamWithMembers, submitting,
        setSubmitting, getTeamsForSelectDropdown,
        getUserGroupsForSelectDropdown, updateManagersGroupAssignment
    }
};

export default useTeams;