import {useEffect, useState} from 'react';
import {useDebounce} from "./useDebounce";
import {getTeamLearnersList, getTeamsList} from "./utils/teams";  // Replace with the correct path

const useTeamLearnersList = ({teamID, search="", page=1, size=10, refresher=null}) => {
    const [learners, setLearners] = useState([]);
    const [team, setTeam] = useState()
    const [learnersLoading, setLearnersLoading] = useState(false);
    const [pagination, setPagination] = useState({
        totalItems:0,
        currentPage:1,
        totalPages:0,
        pageSize:0
    });


    const handleTeamLearnersListRetrieval = (data) => {
        setLearners(Array.isArray(data?.results) ? data.results : [])
        setPagination({
            totalItems:  data?.count,
            currentPage: page,
            totalPages: Math.ceil(data?.count / size),
            pageSize: size,
        });
        setLearnersLoading(false)
    }

    useDebounce(()=>{
        setLearnersLoading(true)
        getTeamLearnersList( {
            department_id:teamID,
            page_size:size,
            page,
            ...(!!search ? {
                query:search
            } : {})
        }, handleTeamLearnersListRetrieval, handleTeamLearnersListRetrieval)
    }, 200, [teamID, search, page, size, refresher])

    return {
        learners,
        learnersLoading,
        pagination
    }


};

export default useTeamLearnersList;
