import React, { useState } from 'react';
import Descriptivepagetitle from '../../../components/Descriptivepagetitle/Descriptivepagetitle';
import Registeredusers from '../../../components/Registeredusers/Registeredusers';
import './UserLicensesViewContent.css';
import useLicensingContent from '../../../hooks/useLicensingContent';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import EmptyTableDataInfographic from '../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic';
import AddLicense from '../../../components/AddLicense/AddLicense';
import AssignLicense from '../../../components/AssignLicense/AssignLicense';
import dayjs from 'dayjs';
import {getTableIndex, getTableSizes} from '../../../hooks/helpers';
import TableSizeDropdown from '../../../components/TableSizeDropdown/TableSizeDropdown';
import { Link } from 'react-router-dom';
import AssignmentsListPerLicense from '../../../components/AssignmentsListPerLicense/AssignmentsListPerLicense';
import ReactPaginate from "react-paginate";
import {useTranslation} from "react-i18next";

const UserLicensesViewContent = () => {
  const { t } = useTranslation();
  const TABLE_SIZES = getTableSizes();
  const [search, setSearch] = useState('');
  const [size, setSize] = useState(TABLE_SIZES[0]);
  const [page, setPage] = useState(1);
  const { licenses, licensesLoading, pagination, calculateUsage } = useLicensingContent({
    platformType: 'user',
    search,
    page,
    size,
  });
  const [assignLicenseFocused, setAssignLicenseFocused] = useState(false);
  const [focusedLicense, setFocusedLicense] = useState(null);
  const paginate = ({ selected }) => {
    setPage(selected + 1);
  };

  return (
    <>
      <div className="p-row r-r">
        <div className="div-block-204 f-w">
          <div className="div-block-183">
            <div className="div-block-205">
              <div className="div-block-206"></div>
              <div className="text-block-113">User Licenses</div>
            </div>
            <div className="div-block-308">
              <a
                href="#"
                className="link-block-22 w-inline-block"
                onClick={() => {
                  setAssignLicenseFocused(true);
                }}
              >
                <div
                  data-w-id="2e311dd0-e1c2-0ab6-f2a1-1e492e5123a6"
                  className="div-block-207 first active follow-user create-cred-popup"
                >
                  <div className="text-block-111">Assign License</div>
                </div>
              </a>
            </div>
          </div>
          <div react-component="CourseTable" className="table_cont-2">
            <div className="div-block-217">
              <div className="div-block-169">
                <div>
                  <div
                    prop-data-value-tablename="Courses"
                    prop-data-names="tableName"
                    className="text-block-135"
                  >
                    Licenses
                    <br />
                  </div>
                </div>
              </div>
              <div className="div-block-169">
                <div className="div-block-171">
                  <div className="sm">Show</div>
                  <div className="form-block w-form">
                    <TableSizeDropdown setSize={setSize} setPage={setPage} />
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                  <div className="sm">entries</div>
                </div>
                <div className="div-block-171">
                  <div className="sm">Search:</div>
                  <div className="form-block w-form">
                    <form
                      id="email-form-2"
                      name="email-form-2"
                      data-name="Email Form 2"
                      method="get"
                      data-wf-page-id="66e0598d95b3c617324f4850"
                      data-wf-element-id="4386085a-1eef-af03-c6b1-7a5a39f298f9"
                    >
                      <input
                        className="input w-input"
                        data-name="Field 3"
                        id="field-3"
                        maxLength={256}
                        name="field-3"
                        placeholder=""
                        required=""
                        type="text"
                        value={search}
                        onChange={(e) => {
                          setSearch(e?.target?.value);
                        }}
                      />
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div react-component="table" className="table">
                <div className="th">
                  <div className="tc _10">
                    <div className="sm">#</div>
                  </div>
                  <div className="tc _20">
                    <div className="w_sort">
                      <div className="text-block-134 fa_icon sort"></div>
                      <div className="sm">Name</div>
                    </div>
                  </div>
                  <div className="tc _20">
                    <div className="w_sort">
                      <div className="text-block-134 fa_icon sort"></div>
                      <div className="sm">Start Date</div>
                    </div>
                  </div>
                  <div className="tc _20">
                    <div className="w_sort">
                      <div className="text-block-134 fa_icon sort"></div>
                      <div className="sm">Expiry Date</div>
                    </div>
                  </div>
                  <div className="tc _10">
                    <div className="w_sort">
                      <div className="text-block-134 fa_icon sort"></div>
                      <div className="sm">Total</div>
                    </div>
                  </div>
                  <div className="tc _20">
                    <div className="w_sort">
                      <div className="text-block-134 fa_icon sort"></div>
                      <div className="sm">Usage</div>
                    </div>
                  </div>
                </div>
                {licensesLoading ? (
                    <IBLSpinLoader
                        containerHeight={'200px'}
                        size={20}
                        color={'white'}
                    />
                ) : licenses.length > 0 ? (
                    licenses.map((license, index) => (
                        <div key={`license-nbr-${index}`} className="tr">
                          <div className="tc tcell _10">
                            <div className="m">{getTableIndex(index, page, size)}</div>
                          </div>
                          <div
                              data-w-id="4386085a-1eef-af03-c6b1-7a5a39f2991c"
                              className="tc tcell _20"
                          >
                            <a
                                onClick={() => {
                                  setFocusedLicense(license);
                                }}
                                href="#"
                                className="tl-2 np"
                            >
                              <span className="nb">{license?.name}</span>
                            </a>
                          </div>
                          <div className="tc _20">
                            <div className="m small">
                              {license?.started ? dayjs(license?.started).format('DD MMM YYYY') : "-"}
                            </div>
                          </div>
                          <div className="tc _20">
                            <div className="m small">
                              {license?.expired ? dayjs(license?.expired).format('DD MMM YYYY') : "-"}
                            </div>
                          </div>
                          <div className="tc tcell _10">
                            <div className="m">{license?.count}</div>
                          </div>
                          <div className="tc tcell w_a _20">
                            <div className="w_per">
                              <div className="p_text">
                                {calculateUsage(license?.usage_count, license?.count)}%
                              </div>
                              <div className="div-block-177">
                                <div
                                    className={`div-block-203 ph`}
                                    style={{
                                      width: `${calculateUsage(license?.usage_count, license?.count)}%`,
                                    }}
                                ></div>
                              </div>
                            </div>
                            {/*<div className="div-block-2 options options_trigger">
                                                          <div className="w-embed"><label className="switch"><input
                                                              type="checkbox"
                                                              className="platform-switch"
                                                              data-username="hajar585"
                                                              id="toggle"
                                                              checked={license?.active}/><span
                                                              className="slider round"></span></label></div>
                                                      </div>*/}
                          </div>
                        </div>
                    ))
                ) : (
                    <EmptyTableDataInfographic/>
                )}
                <div className="tf pagination-container">
                  <div className="div-block-289">
                    <div className="info-3">
                      {t('Showing')}{' '}
                      {pagination.totalItems === 0 ? 0 : ((page - 1) * size + 1)}{' '}
                      {t('to')}{' '}
                      {Math.min(page * size, pagination.totalItems)}{' '}
                      {t('of')} {pagination.totalItems}
                    </div>
                    <ReactPaginate
                        onPageChange={paginate}
                        pageCount={pagination.totalPages}
                        forcePage={page - 1}
                        previousLabel={'Prev'}
                        nextLabel={'Next'}
                        containerClassName={'pagination'}
                        pageLinkClassName={'page-number'}
                        previousLinkClassName={'page-number'}
                        nextLinkClassName={'page-number'}
                        activeLinkClassName={'active'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*{
              addLicenseFocused && <AddLicense setFocused={setAddLicenseFocused} />
          }*/}
      {assignLicenseFocused && (
          <AssignLicense setFocused={setAssignLicenseFocused}/>
      )}
      {focusedLicense && (
          <AssignmentsListPerLicense
              license={focusedLicense}
              setFocused={setFocusedLicense}
          />
      )}
    </>
  );
};

export default UserLicensesViewContent;
