import React, {useState} from 'react';
import {getLearnersReports} from "./utils/reports";
import {useDebounce} from "./useDebounce";

const useTeamLearnerReports = ({filters={}, page=1}) => {

    const [learnerReports, setLearnerReports] = useState([])
    const [learnerReportsLoading, setLearnerReportsLoading] = useState(false)
    const [learnerPagination, setLearnerPagination] = useState({
        totalItems:0,
        currentPage:1,
        totalPages:0,
        pageSize:0
    });

    const handleFetchLearnerReports = async () => {
        setLearnerReportsLoading(true)
        const query = {
            page,
            ...filters
        }
        const reports = await getLearnersReports(query)
        setLearnerReports(Array.isArray(reports?.data) ? reports.data : [])
        setLearnerPagination({
            totalItems:  reports?.pagination?.totalItems,
            currentPage: reports?.pagination?.currentPage,
            totalPages: reports?.pagination?.totalPages,
            pageSize: reports?.pagination?.perPage,
        });
        setLearnerReportsLoading(false)
    }

    useDebounce(handleFetchLearnerReports, 200, [
        page, filters
    ])
    return {learnerReports, learnerReportsLoading, learnerPagination}
};

export default useTeamLearnerReports;