import React, {useContext, useEffect, useState} from "react";
import "./TeamsManagersAssignedGroupsListViewContent.css"
import {getTableIndex, getTableSizes} from "../../../hooks/helpers";
import useLicensingGroup from "../../../hooks/useLicensingGroup";
import TableSizeDropdown from "../../../components/TableSizeDropdown/TableSizeDropdown";
import AddLicensingUserGroup from "../../../components/AddLicensingUserGroup/AddLicensingUserGroup";
import {AppDataContext, IBLSpinLoader} from "@iblai/ibl-web-react-common";
import EmptyTableDataInfographic from "../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic";
import LicensingUsersInGroup from "../../../components/LicensingUsersInGroup/LicensingUsersInGroup";
import ReactPaginate from "react-paginate";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import useTeamsList from "../../../hooks/useTeamsList";
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import {deleteTeam} from "../../../hooks/utils/teams";
import AddManagersGroupAssignment from "../../../components/AddManagersGroupAssignment/AddManagersGroupAssignment";

const TeamsManagersAssignedGroupsListViewContent = () => {
    const { t } = useTranslation();
    const TABLE_SIZES = getTableSizes();
    const [search, setSearch] = useState('');
    const [size, setSize] = useState(TABLE_SIZES[0]);
    const [page, setPage] = useState(1);
    const [refresher, setRefresher] = useState(null);
    const [focusedGroup, setFocusedGroup] = useState(null)
    const [assignmentGroupModalOpen, setAssignmentGroupModalOpen] = useState(false)
    const {setNotification} = useContext(AppDataContext)
    const {groups, groupLoading, pagination} = useLicensingGroup({search, page, size, refresher})
    const [addGroupModalIsFocused, setAddGroupModalIsFocused] = useState(false)
    const [licensingUsersInGroupModalOpen, setLicensingUsersInGroupModalOpen] = useState(false)
    /*const {teams,
        teamsLoading,
        pagination} = useTeamsList({search, page, size, refresher})*/

    const paginate = ({ selected }) => {
        setPage(selected + 1);
    };

  return (
      <>
          <div className="p-row r-r">
              <div className="div-block-204 f-w">
                  <div className="div-block-183">
                      <div className="div-block-205">
                          <div className="div-block-206"/>
                          <div className="text-block-113">Team Management</div>
                      </div>
                      <div className="div-block-308">
                          <div onClick={()=>setAssignmentGroupModalOpen(true)} data-w-id="0f0fbe27-ba22-528c-b845-669b6f0cebd5"
                             className="link-block-22 w-inline-block">
                              <div data-w-id="0f0fbe27-ba22-528c-b845-669b6f0cebd6"
                                   className="div-block-207 first active follow-user create-cred-popup">
                                  <div className="text-block-111">Assign User Group to Team Manager</div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div react-component="CourseTable" className="table_cont-2">
                      <div className="div-block-217">
                          <div className="div-block-169">
                              <div className="div-block-171">
                                  <div className="sm">Show</div>
                                  <div className="form-block w-form">
                                      <TableSizeDropdown setSize={setSize} setPage={setPage} />
                                      <div className="w-form-done">
                                          <div>Thank you! Your submission has been received!</div>
                                      </div>
                                      <div className="w-form-fail">
                                          <div>Oops! Something went wrong while submitting the form.</div>
                                      </div>
                                  </div>
                                  <div className="sm">entries</div>
                              </div>
                              <div className="div-block-171">
                                  <div className="sm">Search:</div>
                                  <div className="form-block w-form">
                                      <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get"
                                            data-wf-page-id="66e1c2afd7d305ab933f7a97"
                                            data-wf-element-id="4386085a-1eef-af03-c6b1-7a5a39f298f9">
                                          <input
                                              className="input w-input"
                                              data-name="Field 3"
                                              id="field-3"
                                              maxLength={256}
                                              name="field-3"
                                              placeholder=""
                                              required=""
                                              type="text"
                                              value={search}
                                              onChange={(e) => {
                                                  setPage(1)
                                                  setSearch(e?.target?.value);
                                              }}/>
                                      </form>
                                      <div className="w-form-done">
                                          <div>Thank you! Your submission has been received!</div>
                                      </div>
                                      <div className="w-form-fail">
                                          <div>Oops! Something went wrong while submitting the form.</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div react-component="table" className="table">
                              <div className="th">
                                  <div className="tc _10">
                                      <div className="text-block-189">#</div>
                                  </div>
                                  <div className="tc _40">
                                      <div className="w_sort">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Name</div>
                                      </div>
                                  </div>
                                  <div className="tc _40">
                                      <div className="w_sort">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Assigned teams</div>
                                      </div>
                                  </div>
                                  <div className="tc _10">
                                      <div className="w_sort hidden">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Usage</div>
                                      </div>
                                  </div>
                              </div>
                              {
                                  groupLoading ?
                                      <IBLSpinLoader containerHeight={"200px"} size={20} color={"white"}/> :
                                      groups.length > 0 ?
                                          groups.map((group, index) => (
                                              <div className="tr">
                                                  <div className="tc _10">
                                                      <div className="m">{getTableIndex(index, page, size)}</div>
                                                  </div>
                                                  <div onClick={()=>{
                                                      setFocusedGroup(group)
                                                      setLicensingUsersInGroupModalOpen(true)
                                                  }} data-w-id="4386085a-1eef-af03-c6b1-7a5a39f2991c" style={{cursor:"pointer"}}
                                                       className="tc tcell _40"><img
                                                      src="/images/female20091023302387177.jpeg" loading="lazy" alt
                                                      className="r-i hidden"/>
                                                      <div
                                                            className="tl-2 np"><span
                                                          className="nb white-color">{group?.name || "-"}</span></div>
                                                  </div>
                                                  <div className="tc tcell _40">
                                                      {
                                                          Array.isArray(group?.departments) && group.departments.length > 0  ? group.departments.map((_team, index)=>(
                                                              <React.Fragment key={`group-${group.id}-team-${index}`}>
                                                                  <SlTooltip>
                                                                      <div style={{textAlign: "center"}} slot="content">
                                                                          <span>View team managers</span>
                                                                      </div>
                                                                      <Link to={`/teams/list/${_team?.id}/?team=${encodeURIComponent(_team?.name)}`}
                                                                            className="w-inline-block nb white-color no-color-hover no-underline">{_team?.name}</Link>
                                                                  </SlTooltip>
                                                                  {
                                                                      index === (group.departments.length - 1) ? "" : " | "
                                                                  }
                                                              </React.Fragment>
                                                          )) : "-"
                                                      }

                                                  </div>
                                                  <div className="tc tcell _10">
                                                      <SlTooltip>
                                                          <div style={{textAlign: "center"}} slot="content">
                                                              <span>Update assignment</span>
                                                          </div>
                                                          <div onClick={()=>{
                                                              setFocusedGroup(group)
                                                              setAssignmentGroupModalOpen(true)
                                                          }}><img
                                                              src="/images/settings-1.svg"
                                                              loading="lazy" alt
                                                              className="image-154"/></div>
                                                      </SlTooltip>
                                                  </div>
                                                  {/*<div onClick={()=>handleTeamDeletion(team)} data-w-id="d8fe1112-9a8c-530a-29ec-38dbcbc4dfaa"
                                                       className="tc tcell _10">
                                                      <SlTooltip>
                                                          <div style={{textAlign: "center"}} slot="content">
                                                              <span>Delete team</span>
                                                          </div>
                                                          <div>
                                                              {focusedTeamForDeletion === team?.id ?
                                                                  (
                                                                      <IBLSpinLoader size={20} color={"#FFF"}/>
                                                                  )
                                                                  : (
                                                                      <img
                                                                          src="/images/trash-2.svg" loading="lazy" alt
                                                                          className="image-154"/>
                                                                  )
                                                              }
                                                          </div>

                                                      </SlTooltip>
                                                  </div>*/}
                                              </div>
                                          ))
                                          :
                                          <EmptyTableDataInfographic/>
                              }
                              <div className="tf pagination-container">
                                  <div className="div-block-289">
                                      <div className="info-3">
                                          {t('Showing')}{' '}
                                          {(page - 1) * size + 1}{' '}
                                          {t('to')}{' '}
                                          {Math.min(page * size, pagination.totalItems)}{' '}
                                          {t('of')} {pagination.totalItems}
                                      </div>
                                      <ReactPaginate
                                          onPageChange={paginate}
                                          pageCount={pagination.totalPages}
                                          forcePage={page - 1}
                                          previousLabel={'Prev'}
                                          nextLabel={'Next'}
                                          containerClassName={'pagination'}
                                          pageLinkClassName={'page-number'}
                                          previousLinkClassName={'page-number'}
                                          nextLinkClassName={'page-number'}
                                          activeLinkClassName={'active'}
                                      />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          {
              assignmentGroupModalOpen && <AddManagersGroupAssignment group={focusedGroup} setFocused={setAssignmentGroupModalOpen} setRefresher={setRefresher} />
          }
          {
              licensingUsersInGroupModalOpen && <LicensingUsersInGroup group={focusedGroup} setFocused={setLicensingUsersInGroupModalOpen}
                                                     setAddUsersFocused={setAddGroupModalIsFocused}
                                                     setRefresher={setRefresher} refresher={refresher}/>
          }
          {
              addGroupModalIsFocused &&
              <AddLicensingUserGroup setFocused={setAddGroupModalIsFocused} group={focusedGroup}
                                     refresher={refresher} setRefresher={setRefresher}/>
          }
      </>
  );
};

export default TeamsManagersAssignedGroupsListViewContent;
