import React, {useEffect, useRef, useState} from 'react';
import {useDebounce} from "./useDebounce";

const useNotifications = () => {
    const notificationRef = useRef(null)
    const defaultNotification = {
        success:false,
        msg:""
    }
    const [notification, setNotification] = useState(defaultNotification)

    useDebounce(()=>{
        if(notification?.msg && notificationRef?.current){
            notificationRef.current?.toast()
        }
    }, 200, [notification])
    return {
        notificationRef,
        notification,
        setNotification
    }
};

export default useNotifications;