import React, {useContext, useEffect, useState} from "react";
import "./LearnersUnderTeamViewContent.css"
import {getTableIndex, getTableSizes} from "../../../hooks/helpers";
import useLicensingGroup from "../../../hooks/useLicensingGroup";
import TableSizeDropdown from "../../../components/TableSizeDropdown/TableSizeDropdown";
import AddLicensingUserGroup from "../../../components/AddLicensingUserGroup/AddLicensingUserGroup";
import {AppDataContext, IBLSpinLoader} from "@iblai/ibl-web-react-common";
import EmptyTableDataInfographic from "../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic";
import LicensingUsersInGroup from "../../../components/LicensingUsersInGroup/LicensingUsersInGroup";
import ReactPaginate from "react-paginate";
import {useTranslation} from "react-i18next";
import {Link, useParams, useSearchParams} from "react-router-dom";
import useTeamsList from "../../../hooks/useTeamsList";
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import {deleteLearnerFromTeam, deleteTeam} from "../../../hooks/utils/teams";
import useTeamLearnersList from "../../../hooks/useTeamLearnersList";

const LearnersUnderTeamViewContent = () => {
    const {teamID} = useParams()
    const { t } = useTranslation();
    const [searchParam, setSearchParam]= useSearchParams()
    const TABLE_SIZES = getTableSizes();
    const [search, setSearch] = useState('');
    const [size, setSize] = useState(TABLE_SIZES[0]);
    const [page, setPage] = useState(1);
    const [refresher, setRefresher] = useState(null);
    const [focusedForDeletion, setFocusedForDeletion] = useState('')
    const {setNotification} = useContext(AppDataContext)
    const {learners,
        learnersLoading,
        pagination} = useTeamLearnersList({teamID, search, page, size, refresher})

    const paginate = ({ selected }) => {
        setPage(selected + 1);
    };

    const handleLearnerDeletion = async (learnerInfo) => {
        setFocusedForDeletion(learnerInfo?.id)
        if(await deleteLearnerFromTeam(learnerInfo?.id)){
            setNotification({
                msg:`${learnerInfo?.name} removed successfully.`,
                success:true
            })
            setRefresher(Date.now())
        }else{
            setNotification({
                msg:`An error occurred during team deletion.`,
                success:false
            })
        }
        setFocusedForDeletion(null)
    }

  return (
      <>
          <div className="p-row r-r">
              <div className="div-block-204 f-w">
                  <div className="div-block-183">
                      <div className="div-block-205">
                          <div className="div-block-206"/>
                          <div className="text-block-113">Managers under {searchParam.get('team') ?? `team ${teamID}`}</div>
                      </div>
                      <div className="div-block-308">
                          <Link prop-events-value-onclick="handleAddNewCourseClick" prop-events-names="onClick"
                                to={'/teams/list'} className="link-block-22 w-inline-block">
                              <div className="div-block-207 first active follow-user back-to-course-btn"><img
                                  src="/images/move-left.svg" loading="lazy" alt className="back-to-course-btn-icon"/>
                                  <div className="text-block-111">Back to Teams Managers List</div>
                              </div>
                          </Link>
                      </div>
                  </div>
                  <div react-component="CourseTable" className="table_cont-2">
                      <div className="div-block-217">
                          <div className="div-block-169">
                              <div>
                                  <div prop-data-value-tablename="Courses" prop-data-names="tableName"
                                       className="text-block-135">Team Managers<br/></div>
                              </div>
                          </div>
                          <div className="div-block-169">
                              <div className="div-block-171">
                                  <div className="sm">Show</div>
                                  <div className="form-block w-form">
                                      <TableSizeDropdown setSize={setSize} setPage={setPage} />
                                      <div className="w-form-done">
                                          <div>Thank you! Your submission has been received!</div>
                                      </div>
                                      <div className="w-form-fail">
                                          <div>Oops! Something went wrong while submitting the form.</div>
                                      </div>
                                  </div>
                                  <div className="sm">entries</div>
                              </div>
                              <div className="div-block-171">
                                  <div className="sm">Search:</div>
                                  <div className="form-block w-form">
                                      <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get"
                                            data-wf-page-id="66e1c2afd7d305ab933f7a97"
                                            data-wf-element-id="4386085a-1eef-af03-c6b1-7a5a39f298f9">
                                          <input
                                              className="input w-input"
                                              data-name="Field 3"
                                              id="field-3"
                                              maxLength={256}
                                              name="field-3"
                                              placeholder=""
                                              required=""
                                              type="text"
                                              value={search}
                                              onChange={(e) => {
                                                  setPage(1)
                                                  setSearch(e?.target?.value);
                                              }}/>
                                      </form>
                                      <div className="w-form-done">
                                          <div>Thank you! Your submission has been received!</div>
                                      </div>
                                      <div className="w-form-fail">
                                          <div>Oops! Something went wrong while submitting the form.</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div react-component="table" className="table">
                              <div className="th">
                                  <div className="tc _10">
                                      <div className="text-block-189">#</div>
                                  </div>
                                  <div className="tc _60">
                                      <div className="w_sort">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Name</div>
                                      </div>
                                  </div>
                                  <div className="tc _10">
                                      <div className="w_sort hidden">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Usage</div>
                                      </div>
                                  </div>
                                  <div className="tc _10">
                                      <div className="w_sort hidden">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Usage</div>
                                      </div>
                                  </div>
                                  <div className="tc _10">
                                      <div className="w_sort hidden">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Usage</div>
                                      </div>
                                  </div>
                              </div>
                              {
                                  learnersLoading ?
                                      <IBLSpinLoader containerHeight={"200px"} size={20} color={"white"}/> :
                                      learners.length > 0 ?
                                          learners.map((team, index) => (
                                              <div className="tr">
                                                  <div className="tc _10">
                                                      <div className="m">{getTableIndex(index, page, size)}</div>
                                                  </div>
                                                  <div data-w-id="4386085a-1eef-af03-c6b1-7a5a39f2991c"
                                                       className="tc tcell _80"><img
                                                      src="/images/female20091023302387177.jpeg" loading="lazy" alt
                                                      className="r-i hidden"/>
                                                      <Link to={`/per-learner/learners/${team?.username}`}
                                                            className="tl-2 np"><span
                                                          className="nb white-color">{team?.name || team?.username || team?.email}</span></Link>
                                                  </div>
                                                  <div onClick={()=>handleLearnerDeletion(team)} data-w-id="d8fe1112-9a8c-530a-29ec-38dbcbc4dfaa"
                                                       className="tc tcell _10">
                                                      <SlTooltip>
                                                          <div style={{textAlign: "center"}} slot="content">
                                                              <span>Remove manager</span>
                                                          </div>
                                                          <div>
                                                              {focusedForDeletion === team?.id ?
                                                                  (
                                                                      <IBLSpinLoader size={20} color={"#FFF"}/>
                                                                  )
                                                                  : (
                                                                      <img
                                                                          src="/images/trash-2.svg" loading="lazy" alt
                                                                          className="image-154"/>
                                                                  )
                                                              }
                                                          </div>

                                                      </SlTooltip>
                                                  </div>
                                              </div>
                                          ))
                                          :
                                          <EmptyTableDataInfographic/>
                              }
                              <div className="tf pagination-container">
                                  <div className="div-block-289">
                                      <div className="info-3">
                                          {t('Showing')}{' '}
                                          {(page - 1) * size + 1}{' '}
                                          {t('to')}{' '}
                                          {Math.min(page * size, pagination.totalItems)}{' '}
                                          {t('of')} {pagination.totalItems}
                                      </div>
                                      <ReactPaginate
                                          onPageChange={paginate}
                                          pageCount={pagination.totalPages}
                                          forcePage={page - 1}
                                          previousLabel={'Prev'}
                                          nextLabel={'Next'}
                                          containerClassName={'pagination'}
                                          pageLinkClassName={'page-number'}
                                          previousLinkClassName={'page-number'}
                                          nextLinkClassName={'page-number'}
                                          activeLinkClassName={'active'}
                                      />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </>
  );
};

export default LearnersUnderTeamViewContent;
