import React, {useContext, useEffect, useState} from 'react';
import Async, { useAsync } from 'react-select/async';
import useLicensingUsers from "../../hooks/useLicensingUsers";
import {getFormattedFacet, getTableSizes} from "../../hooks/helpers";
import {AppDataContext, IBLSpinLoader} from "@iblai/ibl-web-react-common";
import './AddLicensingUserGroup.css'
import {useDebounce} from "../../hooks/useDebounce";
import EmptyTableDataInfographic from "../EmptyTableDataInfographic/EmptyTableDataInfographic";
import dayjs from "dayjs";
import {getLicensingUsersListInGroup} from "../../hooks/utils/licensing";
import UsersAdvancedSearchModal from "../UsersAdvancedSearchModal/UsersAdvancedSearchModal";
import UsersPreviewBlock from "../UsersPreviewBlock/UsersPreviewBlock";

const AddLicensingUserGroup = ({setFocused, group=null, setRefresher=()=>{}}) => {
    const {setNotification} = useContext(AppDataContext)
    const TABLE_SIZES = getTableSizes();
    const [search, setSearch] = useState('');
    const [size, setSize] = useState(TABLE_SIZES[0]);
    const [page, setPage] = useState(1);
    const defaultFields = {
        groupName:group?.name || "",
        users:[]
    }
    const [fields, setFields] = useState(defaultFields)
    const handleSetNotification = (success, msg, timeout=2500) => {
        setNotification({
            msg: msg,
            success
        })
    }
    const {getUsersForSelectDropdown,
        createUserGroupsAndAssignUsers, handleAssignUserToGroup,
        getAdvancedFilterUsersForSelectDropdown, setBulkFileUploading,
        bulkFileUploading, bulkUploadInputRef, handleCSVBulkUploadChange
    } = useLicensingUsers({search, size, page})
    const [submitting, setSubmitting] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const [searching, setSearching] = useState(false)
    const [searchedUsers, setSearchedUsers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [allSearchedUserChecked, setAllSearchedUserChecked] = useState(false)

    const [groupUsersLoading, setGroupUsersLoading] = useState(false)

    const handleUserSearch = () => {
        setAllSearchedUserChecked(false)
        if(searchTerm.length < 2) {
            setSearchedUsers([])
            return
        }
        setSearching(true)
        getUsersForSelectDropdown(searchTerm, (data)=>{
            const result = data?.results
            if(Array.isArray(result) && result.length > 0){
                setSearchedUsers(result)
            }else{
                setSearchedUsers([])
            }
            setSearching(false)
        })
    }

    const handleRetrieveUser = (userID) => {
        setSelectedUsers((old)=>old.filter((user)=>user?.user_id!==userID))
    }

    const userIsAlreadySelected = (user) => {
       return selectedUsers.findIndex((pre)=>pre.user_id===user.user_id)!==-1
    }

    const handleSearchedUserCheck = (user) => {
        if(userIsAlreadySelected(user)){
            //checked
            handleRetrieveUser(user.user_id)
        }else{
            handleAddUserToSelectedUsers(user)
        }
    }

    const handleAllSearchedUsersCheck = () => {
        if(allSearchedUserChecked){
            handleRetrieveAllSearchedUsersToSelectedUsers()
            setAllSearchedUserChecked(false)
        }else{
            handleAddAllSearchedUsersToSelectedUsers()
            setAllSearchedUserChecked(true)
        }
    }

    const handleAddAllSearchedUsersToSelectedUsers = () => {
        if(searchedUsers.length > 0){
            searchedUsers.forEach((user)=>{
                handleAddUserToSelectedUsers(user)
            })
        }
    }

    const handleRetrieveAllSearchedUsersToSelectedUsers = () => {
        if(searchedUsers.length > 0){
            searchedUsers.forEach((user)=>{
                handleRetrieveUser(user.user_id)
            })
        }
    }

    const handleAddUserToSelectedUsers = (user) => {
        if(!userIsAlreadySelected(user)){
            setSelectedUsers((old)=>[
                user,
                ...old
            ])
        }
    }

    useDebounce(handleUserSearch, 200, [searchTerm])

    useDebounce(()=>{
        if(selectedUsers.length === 0){
            setAllSearchedUserChecked(false)
        }
    }, 200, [selectedUsers])

    useEffect(() => {
        if(group){
            setGroupUsersLoading(true)
            getLicensingUsersListInGroup( {
                group_id:group?.id,
            }, (data)=>{
                setSelectedUsers(Array.isArray(data?.results) ? data.results.map((user)=>({
                    user_id:user.user_id,
                    name:user?.name,
                    username:user?.username,
                    email:user?.email,
                    profile_image:user?.public_metadata?.public_metadata
                })) : [])
                setGroupUsersLoading(false)
            }, ()=>{
                setSelectedUsers([])
                setGroupUsersLoading(false)
            })
        }
    }, [group]);



    const handleSuccess = () => {
        setSubmitting(false)
        handleSetNotification(true, !!group ? "Users updated!" : "Group created successfully!")
        setRefresher(Date.now())
        setSelectedUsers([])
        setSearchedUsers([])
        setFields(defaultFields)
        if(!!group){
            setFocused(false)
        }
    }

    const handleError = (msg) => {
        setSubmitting(false)
        handleSetNotification(true,  "An error occurred. Please try again!")
    }

    const handleSubmit = (e) => {
        if(!fields?.groupName || selectedUsers.length===0){
            handleSetNotification(false, "Fill out all required fields.", 5000)
            return
        }
        setSubmitting(true)
        const updatedFields = {...fields, users:selectedUsers.map((user)=>({value:user?.user_id, key:user?.isUploaded ? "email" : "user_id"}))}
        if(!!group){
            handleAssignUserToGroup({id:group?.id, users:updatedFields?.users}, handleSuccess, handleError)
        }else{
            createUserGroupsAndAssignUsers(updatedFields, handleSuccess, handleError)
        }
    }

    //ADVANCED SEARCH

    const [advancedSearchFocused, setAdvancedSearchFocused] = useState(false)
    const [advancedSearchFacet, setAdvancedSearchFacet] = useState([])
    const [selectedAdvancedSearchQuery, setSelectedAdvancedSearchQuery] = useState([])
    const [selectedAdvancedSearchQueryPreviewList, setSelectedAdvancedSearchQueryPreviewList] = useState([])
    const [loadingFacets, setLoadingFacets]= useState(false)
    const [activeFacet, setActiveFacet] = useState("")

    const handleLoadAdvancedSearchFacets = () => {
        if(advancedSearchFocused && advancedSearchFacet.length === 0){
            setLoadingFacets(true)
            getAdvancedFilterUsersForSelectDropdown({}, (data)=>{
                const formattedFacets = getFormattedFacet(data?.facets)
                setAdvancedSearchFacet(formattedFacets)
                setLoadingFacets(false)
            })
        }

    }

    const handleFilterCheckboxChange = (value, previewData) => {
        if(selectedAdvancedSearchQuery.includes(value)){
            setSelectedAdvancedSearchQuery((old)=>old.filter((query)=>query!==value))
            setSelectedAdvancedSearchQueryPreviewList((old)=>old.filter((data)=>data?.value!==value))
        }else{
            setSelectedAdvancedSearchQuery((old)=>[
                value,
                ...old
            ])
            setSelectedAdvancedSearchQueryPreviewList((old)=>[
                previewData,
                ...old
            ])
        }
    }

    const handleClearFilter = () => {
        setSelectedAdvancedSearchQueryPreviewList([])
        setSelectedAdvancedSearchQuery([])
        setActiveFacet("")
        setAdvancedSearchFocused(false)
        setSearchedUsers([])
    }

    const handleAdvancedSearchFilterSubmit = () => {
        setAdvancedSearchFocused(false)
        setSearchTerm("")
        setSearching(true)
        getAdvancedFilterUsersForSelectDropdown(selectedAdvancedSearchQuery.join("&"), (data)=>{
            if(Array.isArray(data?.results) && data?.results.length > 0){
                const seen = new Set();
                setSearchedUsers(data?.results.filter((pre)=>{
                    const userInfo = pre?.user_info;
                    if (!userInfo) return false; // Skip if no user_info exists
                    const userId = userInfo.id; // Get the user ID
                    if (seen.has(userId)) return false; // Skip if user ID is already seen
                    seen.add(userId); // Add user ID to the Set
                    return true; // Keep this user
                }).map((user)=>({
                    ...user?.user_info,
                    profile_image:user?.user_info?.public_metadata?.profile_image?.image_url_full,
                    added_on:user?.user_info?.date_joined
                })))
            }else{
                setSearchedUsers([])
            }
            setSearching(false)
        })
    }

    useDebounce(handleLoadAdvancedSearchFacets, 200, [advancedSearchFocused])

    //CSV FILE IMPORT

    const handleCSVBulkFileUploadClick = () => {
        bulkUploadInputRef.current.click();
    }

    const handleCSVContentLectureCallback = (parsedData) => {
        setBulkFileUploading(false)
        if(parsedData.length === 0){
            setNotification({
                msg:"No content parsed from the uploaded CSV file.",
                success:false
            })
            return
        }
        const newSelectedUsers = parsedData.map((_user)=>({
            user_id: _user?.email,
            name:_user?.email,
            isUploaded:true
        }))
        setSelectedUsers((old)=>([
            ...old,
            ...newSelectedUsers
        ]))
    }

    return (
        <>
            <div style={{display: "flex"}} className="popup_div-nm_popup enroll-programs-modal active">
                <div className={`popup_cont-sk ${selectedUsers.length > 0 ? "sidebar-available" : ""}`}>
                    <div className="hf_popup program-enroll-popup active with-sidebar">
                        <div className="div-block-147 new_msg">
                            <div
                                className="text-block-135 mg-0">{!!group ? `Update users of ${group?.name}` : "Add a group"}</div>
                        </div>
                        <div className="div-block-228">
                            <div className="form-block-7 w-form">
                                <form onSubmit={e=>e.preventDefault()} id="email-form-5" name="email-form-5" data-name="Email Form 5" method="get"
                                      data-wf-page-id="66e1c2afd7d305ab933f7a97"
                                      data-wf-element-id="5f64487e-caf3-b6dd-b426-6888986a7eb7"><label htmlFor="name-2"
                                                                                                       className="text-block-139-bd-f-soc">Group
                                    Name *</label>
                                    <input value={fields?.groupName} onChange={!group ? e => setFields({
                                        ...fields,
                                        groupName: e?.target?.value
                                    }) : () => {
                                    }} disabled={!!group} readOnly={!!group}
                                           className="text-field-3 w-input license-name-input" maxLength="256"
                                           name="name-2"
                                           data-name="Name 2" placeholder="Group name" type="text"
                                           id="name-2"/>
                                    <div className="w-layout-hflex flex-block-27"><label htmlFor="name-2"
                                                                                         className="text-block-139-bd-f-soc">Users
                                        *</label>
                                        <div onClick={()=>setAdvancedSearchFocused(true)} data-w-id="66b5f239-a275-9194-f246-307d15353a01"
                                             className="div-block-207 first active follow-user alt-btn">
                                            <div className="text-block-111 red-text cf">Advanced Search</div>
                                        </div>
                                    </div>
                                    <div className="w-layout-hflex flex-block-14">{!searching
                                        ?
                                        <img src="/images/search-1.svg"
                                             loading="lazy" alt=""
                                             className="image-143"/>
                                        :
                                        <span style={{width: "17px"}}><IBLSpinLoader size={17}
                                                                                     color={"#FFF"}/></span>}<input
                                        className="text-field-10 w-input" value={searchTerm}
                                        onChange={e => setSearchTerm(e?.target?.value)} maxLength="256" name="field-6"
                                        data-name="Field 6"
                                        placeholder="Search by name, username, email..." type="text" id="field-6"/>
                                    </div>
                                    <div react-component="table" className="table user-search-table">
                                        {
                                            searchedUsers.length > 0 && (

                                                <>
                                                    <div className="th">
                                                        <div className="tc _10 centered">
                                                            <div
                                                                className="div-block-2 options options_trigger no-margin centered">
                                                                <div className="w-embed"><label><input
                                                                    checked={allSearchedUserChecked}
                                                                    onChange={handleAllSearchedUsersCheck} type="checkbox"
                                                                    id="toggle"/><span></span></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tc _60 centered">
                                                            <div className="w_sort">
                                                                <div className="text-block-134 fa_icon sort hidden"></div>
                                                                <div className="sm">Full Name</div>
                                                            </div>
                                                        </div>
                                                        <div className="tc _30 centered">
                                                            <div className="w_sort">
                                                                <div className="sm">Date Added</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        searchedUsers.map((user) => (
                                                            (
                                                                <div className="tr "
                                                                     onClick={() => handleSearchedUserCheck(user)}>
                                                                    <div className="tc tcell _10 no-pad">
                                                                        <div
                                                                            className="div-block-2 options options_trigger no-margin centered">
                                                                            <div className="w-embed"><label><input
                                                                                type="checkbox" id="toggle"
                                                                                readOnly={true}
                                                                                checked={selectedUsers.findIndex((pre) => pre.user_id === user.user_id) !== -1}
                                                                            /><span></span></label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div data-w-id="03fefb47-44d1-aba8-5c75-91d1533071dc"
                                                                         className="tc tcell _60 no-pad-y">
                                                                        <a href="#" onClick={e => e.preventDefault()}
                                                                           className="tl-2 np"><span
                                                                            className="nb">{user?.name || user?.username || user?.email}</span></a>
                                                                    </div>
                                                                    <div className="tc tcell _30 no-pad-y">
                                                                        <div
                                                                            className="m smaller">{user?.added_on ? dayjs(user?.added_on).format("MMM DD, YYYY") : "-"}</div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        ))
                                                    }
                                                    {
                                                        searchTerm && searchedUsers.length === 0 && !searching &&
                                                        <EmptyTableDataInfographic label={"No user found"}/>
                                                    }
                                                </>
                                            )
                                        }
                                    </div>

                                </form>
                                <div className="w-form-done">
                                    <div>Thank you! Your submission has been received!</div>
                                </div>
                                <div className="w-form-fail">
                                    <div>Oops! Something went wrong while submitting the form.</div>
                                </div>
                            </div>
                        </div>
                        <div className="div-block-147 new_msg">
                            <div onClick={() => setFocused(false)} data-w-id="5f64487e-caf3-b6dd-b426-6888986a7ec6"
                                 className="div-block-207 first active follow-user alt-btn cancel-program-enroll">
                                <div className="text-block-111 red-text cf">Close</div>
                            </div>
                            <div className="div-block-264 f-w">
                                <input
                                    ref={bulkUploadInputRef}
                                    onChange={e=>handleCSVBulkUploadChange(e, handleCSVContentLectureCallback)}
                                    type="file"
                                    className="credential-icon-upload-input"
                                    accept=".csv, text/csv"
                                />
                                <div onClick={() => handleCSVBulkFileUploadClick()}
                                    className={`div-block-207 first active follow-user alt-btn cancel-program-enroll more-gap custom-submit-btn ${bulkFileUploading ? "disabled" : ""}`}>
                                    {
                                        bulkFileUploading ? <span><IBLSpinLoader size={20} color={"#FFF"} /></span> : (
                                            <img src="/images/paperclip-1.svg" loading="lazy" alt
                                                 className="image-155"/>
                                        )
                                    }
                                    <div className="text-block-111 red-text cf">Import CSV file</div>
                                </div>
                                <div onClick={!submitting ? handleSubmit : () => {
                                }} data-w-id="5f64487e-caf3-b6dd-b426-6888986a7ec9"
                                     className={`div-block-207 first active follow-user redeem-btn mg-l16 program-enroll custom-submit-btn ${submitting ? "disabled" : ""}`}>
                                    <div className="text-block-111 red-text cf">{group ? "Update" : "Add"}</div>
                                    {submitting && <span style={{marginLeft: "5px"}}> <IBLSpinLoader size={15}
                                                                                                     color={"#FFF"}/> </span>}
                                </div>
                            </div>
                        </div>
                        <div className="w-layout-vflex enroll-notification-block"><img src="/images/check_2.png"
                                                                                       loading="lazy" sizes="100vw"
                                                                                       srcSet="images/check_2-p-500.png 500w, images/check_2.png 512w"
                                                                                       alt="" className="image-141"/>
                            <h4 className="heading-4">Program enrolled successfully</h4>
                            <div data-w-id="5f64487e-caf3-b6dd-b426-6888986a7ed0"
                                 className="div-block-207 first active follow-user alt-btn cancel-program-enroll">
                                <div className="text-block-111 red-text cf">Close</div>
                            </div>
                        </div>
                        {
                            selectedUsers.length > 0 && (
                                <UsersPreviewBlock {...{
                                    selectedUsers,
                                    setSelectedUsers,
                                    handleRetrieveUser,
                                    searchedUsers
                                }} />
                            )
                        }
                    </div>
                </div>
            </div>
            {
                advancedSearchFocused && <UsersAdvancedSearchModal {...{
                    loadingFacets,
                    advancedSearchFacet,
                    selectedAdvancedSearchQueryPreviewList,
                    handleFilterCheckboxChange,
                    setActiveFacet,
                    activeFacet,
                    selectedAdvancedSearchQuery,
                    setAdvancedSearchFocused,
                    handleClearFilter,
                    handleAdvancedSearchFilterSubmit
                }} />
            }
        </>

    );
};

export default AddLicensingUserGroup;