import React from 'react';
import {getTenant, getUserId} from "@iblai/ibl-web-react-common";

const useCreateCredentials = () => {

    const uploadIconCredentialImage = (formData, callback) => {
        const url = `${process.env.REACT_APP_IBL_DM_URL}/api/credentials/orgs/${getTenant()}/users/${getUserId()}/images/`
        fetch(url, {
            method:"POST",
            body:formData,
            headers: {
                //'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('axd_token')}`,
            },
        }).then((resp)=>{
            resp.json().then((data)=>{
                callback(data)
            })
        }).catch(()=>{
            callback(null)
        })

    }

    return {uploadIconCredentialImage}
};

export default useCreateCredentials;