import {getTenant, isCurrentUserOnlyDepartmentManager} from "../helpers";

export const addTeams = async (data) =>{
    return new Promise((_resolve)=>{
        const currentTenant = getTenant()
        const url = process.env.REACT_APP_IBL_DM_URL + '/api/core/departments/'
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                ...data,
                platform_key:currentTenant
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + localStorage.getItem('dm_token'),
            },
        }).then((resp) => {
            if(!resp.ok){
                _resolve({})
            }
            resp.json().then(_resolve)
        }).catch(_resolve);
    })
}
export const deleteTeam = async (teamID) =>{
    return new Promise((_resolve)=>{
        const currentTenant = getTenant()
        const param = new URLSearchParams({
            id:teamID,
            platform_key:currentTenant
        }).toString()
        const url = process.env.REACT_APP_IBL_DM_URL + '/api/core/departments/?'+param
        fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + localStorage.getItem('dm_token'),
            },
        }).then((resp) => {
            _resolve(resp.ok)
        }).catch(_resolve);
    })
}

export const deleteLearnerFromTeam = async (learnerID) =>{
    return new Promise((_resolve)=>{
        const currentTenant = getTenant()
        const param = new URLSearchParams({
            id:learnerID,
            platform_key:currentTenant
        }).toString()
        const url = process.env.REACT_APP_IBL_DM_URL + '/api/core/departments/members/?'+param
        fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + localStorage.getItem('dm_token'),
            },
        }).then((resp) => {
            _resolve(resp.ok)
        }).catch(_resolve);
    })
}

export const updateTeamsUsers = ({teamID, users=[]}) => {
    return new Promise((_resolve)=>{
        const currentTenant = getTenant()
        const url = process.env.REACT_APP_IBL_DM_URL + '/api/core/departments/members/bulk/'
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                department_id:teamID,
                platform_key:currentTenant,
                users
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + localStorage.getItem('dm_token'),
            },
        }).then((resp) => {
            _resolve(resp.ok)
        }).catch(_resolve);
    })
}

export const getTeamsList = (queryParam={},successCallback, errorCallback) =>{
    const param = new URLSearchParams({...queryParam,
        platform_key:getTenant(),
        ...(isCurrentUserOnlyDepartmentManager() ? {
            department_mode:1
        } : {}),
    }).toString()
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/core/departments/?${param}`
    fetch(url, {
        method: 'GET',
        //body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp) => {
        resp.json().then(successCallback).catch(errorCallback);
    }).catch(errorCallback);
}

export const getTeamLearnersList = (queryParam={},successCallback, errorCallback) =>{
    const param = new URLSearchParams({...queryParam,
        platform_key:getTenant()
    }).toString()
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/core/departments/members/?${param}`
    fetch(url, {
        method: 'GET',
        //body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp) => {
        resp.json().then(successCallback).catch(errorCallback);
    }).catch(errorCallback);
}