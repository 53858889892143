import React, {useContext, useEffect, useState} from 'react';
import {getReportDownload, createNewReport} from "./utils/reports";
import {AppDataContext, getUserName} from "@iblai/ibl-web-react-common";
import dayjs from "dayjs";
import {isCurrentUserOnlyDepartmentManager} from "./helpers";

const UseReports = ({reportName="learner-metrics-report", queryFilters={}}) => {
    const { appData, setAppData, setNotification } = useContext(AppDataContext);
    const [reportDownloadLoading, setReportDownloadLoading] = useState(false);
    const [reportStatus, setReportStatus] = useState('');

    const handleGetReportData = async () => {
        const {data} = await getReportDownload(reportName)
        switch (data?.status?.state){
            case "completed":
                window.open(data?.status?.url, "_blank");
                setReportDownloadLoading(false)
                setReportStatus(data?.status?.state ?? '');
                clearInterval(refreshDatasetInterval);
                setNotification({
                    msg:"Report downloaded successfully",
                    success:true
                });
                break;
            case "pending":
                //PENDING
                setReportStatus("pending");
                setReportDownloadLoading(true)
                break;
            default:
                setNotification({
                    msg:"Error occurred while retrieving report.",
                    success:false
                })
                setReportStatus("");
                clearInterval(refreshDatasetInterval);
                setReportDownloadLoading(false)

        }
    }

    let refreshDatasetInterval = null;
    useEffect(() => {
        if(reportDownloadLoading){
            refreshDatasetInterval = setInterval(handleGetReportData, 3000);
            return () => {
                if (refreshDatasetInterval) {
                    clearInterval(refreshDatasetInterval);
                }
            };
        }
    }, [reportDownloadLoading]);

    const initializeReportDownload = (e) => {
        e.preventDefault()
        if(reportStatus==="pending"){
            return
        }
        clearInterval(refreshDatasetInterval)
        setReportStatus('pending');
        /*const response = await getReportMetricDownload()
        console.log({response})*/
        const {start_date, end_date, ...rest} = queryFilters
        createNewReport(
            {
                report_name:reportName,
                owner: getUserName(),
                ...(isCurrentUserOnlyDepartmentManager() ? {
                    department_mode:true
                } : {}),
                ...(start_date && end_date  ? {
                    start_date:dayjs(start_date).format('YYYY-MM-DD'),
                    end_date:dayjs(end_date).format('YYYY-MM-DD'),
                } : {}),
                ...(Object.keys(rest).length > 0 ? {
                    filters:rest
                } : {})
            },
            (resp) => {
                if(!resp?.ok){
                    setNotification({
                        msg:"Failed to create report.",
                        success:false
                    });
                    setReportStatus("")
                    return
                }
                const data = resp?.body?.data;
                if (data?.url) {
                    setReportStatus(data?.state || '');
                    window.open(data?.url, "_blank");
                    setNotification({
                        msg:"Report downloaded successfully",
                        success:true
                    });
                } else {
                    setReportDownloadLoading(true)
                }
            }
        );
    }

    return {
        reportStatus, reportDownloadLoading, initializeReportDownload
    }
};

export default UseReports;