import {useEffect, useState} from 'react';
import {useDebounce} from "./useDebounce";
import {getTeamsList} from "./utils/teams";
import {
    getLearnersTeamsAssignmentList,
    getSkillsLearnersTeamsAssignmentList
} from "./utils/teamsAssignments";  // Replace with the correct path

const useGroupsTeamsAssignmentsList = ({contentType="course",search="", page=1, size=10, refresher=null}) => {
    const [groups, setGroups] = useState([]);
    const [groupsLoading, setGroupsLoading] = useState(false);
    const [groupsPagination, setGroupsPagination] = useState({
        totalItems:0,
        currentPage:1,
        totalPages:0,
        pageSize:0
    });


    const handleTeamListRetrieval = (data) => {
        setGroups(Array.isArray(data?.results) ? data.results : [])
        setGroupsPagination({
            totalItems:  data?.count,
            currentPage: page,
            totalPages: Math.ceil(data?.count / size),
            pageSize: size,
        });
        setGroupsLoading(false)
    }

    useDebounce(()=>{
        setGroupsLoading(true)
        if(contentType==="skill"){
            getSkillsLearnersTeamsAssignmentList({
                page_size:size,
                page,
                ...(!!search ? {
                    query:search
                } : {})
            }, handleTeamListRetrieval, handleTeamListRetrieval, true)
        }else{
            getLearnersTeamsAssignmentList( contentType, {
                page_size:size,
                page,
                ...(!!search ? {
                    query:search
                } : {})
            }, handleTeamListRetrieval, handleTeamListRetrieval, true)
        }
    }, 200, [search, page, size, refresher, contentType])

    return {
        groupsLoading, groups,
        groupsPagination
    }


};

export default useGroupsTeamsAssignmentsList;
