import React, { useState } from 'react';
import useActiveUsers from '../../../hooks/useActiveUsers';
import Coursetable from '../../../components/Coursetable/Coursetable';
import './LearnersViewContent.css';
import { getPerLearnerUsers } from '../../../hooks/utils/perLearner';
import { useDebounce } from '../../../hooks/useDebounce';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import EmptyTableDataInfographic from '../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic';
import { Link } from 'react-router-dom';
import { getImageUrl, getTableSizes } from '../../../hooks/helpers';
import TablePagination from '../../../components/TablePagination/TablePagination';
import TableSizeDropdown from '../../../components/TableSizeDropdown/TableSizeDropdown';
const imageUrl = getImageUrl('');
const TABLE_SIZES = getTableSizes();

const LearnersViewContent = () => {
  const [search, setSearch] = useState('');
  const [size, setSize] = useState(TABLE_SIZES[0]);
  const [page, setPage] = useState(1);
  const [perLearnerUsers, setPerLearnerUsers] = useState([]);
  const [perLearnerUsersLoaded, setPerLearnerUsersLoaded] = useState(false);

  const handlePerLearnersLoad = () => {
    setPerLearnerUsersLoaded(false);
    getPerLearnerUsers(
      search,
      size,
      (data) => {
        setPerLearnerUsers(data);
        setPerLearnerUsersLoaded(true);
      },
      page
    );
  };

  useDebounce(handlePerLearnersLoad, 300, [search, size, page]);

  return (
    <div className="p-row r-r">
      <div className="div-block-204 f-w">
        <div className="div-block-183">
          <div className="div-block-205">
            <div className="div-block-206"></div>
            <div className="text-block-113">Learners</div>
          </div>
        </div>
        <div react-component="CourseTable" className="table_cont-2">
          <div className="div-block-217">
            <div className="div-block-169">
              <div>
                <div
                  prop-data-value-tablename="Courses"
                  prop-data-names="tableName"
                  className="text-block-135"
                >
                  Summary
                  <br />
                </div>
              </div>
            </div>
            <div className="div-block-169">
              <div className="div-block-171">
                <div className="sm">Show</div>
                <div className="form-block w-form">
                  <TableSizeDropdown setSize={setSize} setPage={setPage} />
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
                <div className="sm">entries</div>
              </div>
              <div className="div-block-171">
                <div className="sm">Search:</div>
                <div className="form-block w-form">
                  <form
                    id="email-form-2"
                    name="email-form-2"
                    data-name="Email Form 2"
                    method="get"
                    data-wf-page-id="657af3ad1367da03b3b125d5"
                    data-wf-element-id="4386085a-1eef-af03-c6b1-7a5a39f298f9"
                  >
                    <input
                      onChange={(e) => {
                        setSearch(e?.target?.value);
                        setPage(1);
                      }}
                      className="input w-input"
                      maxLength="256"
                      name="field-3"
                      data-name="Field 3"
                      placeholder=""
                      prop-events-value-onchange="handleSearchBarOnChange"
                      type="text"
                      id="field-3"
                      prop-events-names="onChange"
                      required=""
                    />
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div react-component="table" className="table">
              <div className="th">
                <div className="tc _10">
                  <div className="sm">#</div>
                </div>
                <div className="tc _30">
                  <div className="w_sort">
                    <div className="text-block-134 fa_icon sort"></div>
                    <div className="sm">Name</div>
                  </div>
                </div>
                <div className="tc _30">
                  <div className="w_sort">
                    <div className="text-block-134 fa_icon sort"></div>
                    <div className="sm">Engagement Index</div>
                  </div>
                </div>
                <div className="tc _30">
                  <div className="w_sort">
                    <div className="text-block-134 fa_icon sort"></div>
                    <div className="sm">Performance Index</div>
                  </div>
                </div>
              </div>
              {!perLearnerUsersLoaded ? (
                <IBLSpinLoader
                  color={'#FFF'}
                  size={20}
                  containerHeight={'200px'}
                />
              ) : (
                <>
                  {Array.isArray(perLearnerUsers?.data) &&
                  perLearnerUsers.data.length > 0 ? (
                    perLearnerUsers.data.map((user, index) => (
                      <div className="tr" key={index}>
                        <div className="tc tcell _10">
                          <div className="m">{index + 1}</div>
                        </div>
                        <div className="tc tcell _30">
                          <img
                            src={imageUrl}
                            loading="lazy"
                            alt=""
                            className="r-i"
                          />
                          <Link
                            to={`/per-learner/learners/${user?.username}`}
                            className="tl-2 np"
                          >
                            <span className="nb">{user?.full_name || '-'}</span>
                            <span className="text-span-7"></span>
                          </Link>
                        </div>
                        <div className="tc tcell _30">
                          <div className="w_per">
                            <div className="p_text">
                              {user?.engagement_index}%
                            </div>
                            <div className="div-block-177">
                              <div
                                className="div-block-203 ph"
                                style={{ width: `${user?.engagement_index}%` }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="tc tcell w_a _30">
                          <div className="w_per">
                            <div className="p_text">
                              {user?.performance_index}%
                            </div>
                            <div className="div-block-177">
                              <div
                                className="div-block-203 ph"
                                style={{ width: `${user?.performance_index}%` }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <EmptyTableDataInfographic />
                  )}
                  {!!perLearnerUsers?.pagination &&
                    !!perLearnerUsers?.pagination?.totalItems && (
                      <TablePagination
                        setPage={setPage}
                        page={page}
                        pagination={perLearnerUsers?.pagination}
                      />
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LearnersViewContent;
