import React, {useRef, useState} from 'react';
import useTeamLearnerReports from "../../../hooks/useTeamLearnerReports";
import {IBLSpinLoader} from "@iblai/ibl-web-react-common";
import EmptyTableDataInfographic from "../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic";
import {getTableIndex, getTableSizes} from "../../../hooks/helpers";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import ReactPaginate from "react-paginate";
import {useTranslation} from "react-i18next";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './TeamsReportForTeamsViewContent.css'
import Async from "react-select/async";
import useLicensingUsers from "../../../hooks/useLicensingUsers";
import Select from "react-select";
import useTeamReports from "../../../hooks/useTeamReports";
import useTeams from "../../../hooks/useTeams";
import useReports from "../../../hooks/useReports";


const TeamsReportForTeamsViewContent = () => {
    const { t } = useTranslation();
    const TABLE_SIZES = getTableSizes();
    const defaultFields = {
        start_date:null,
        end_date:null,
        groups:"",
        pathway:"",
        location:"",
        enrollment_status:null,
    }


    const [fields, setFields] = useState(defaultFields)
    const defaultDateRange = {
        startDate: new Date(),
        endDate: null,
        key: 'selection'
    }

    const [dateRangeInfo, setDateRangeInfo] = useState(defaultDateRange);
    const [focusDateRangeBox, setFocusDateRangeBox ] = useState(false);
    const getFiltersQuery = () => {
        return {
            ...(dateRangeInfo.startDate && dateRangeInfo.endDate  ? {
                start_date:dayjs(dateRangeInfo.startDate).format('YYYY-MM-DD'),
                end_date:dayjs(dateRangeInfo.endDate).format('YYYY-MM-DD'),
            } : {}),
            ...(fields?.groups ? {
                groups:fields.groups
            } : {}),
            ...(fields.pathway ? {
                pathway:fields.pathway
            } : {} ),
            ...(fields.location ? {
                location:fields.location
            } : {}),
            ...(typeof fields.enrollment_status === "boolean" ? {
                is_enrolled:fields.enrollment_status
            } : {})
        }
    }

    const {reportStatus, reportDownloadLoading, initializeReportDownload} = useReports({reportName:"user-group-metrics-report", queryFilters:getFiltersQuery()})
    const ENROLLMENT_STATUS_OPTIONS = [{
        label:"Enrolled",
        value:true
    }, {label:"Not enrolled",
        value:false}];

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(TABLE_SIZES[0]);
    const [filters, setFilters] = useState({})
    const [teamSearchTerm, setTeamSearchTerm] = useState("");

    const {reports, reportsLoading, pagination} = useTeamReports({filters, page:page})

    const paginateLearnerReport = ({ selected }) => {
        setPage(selected + 1);
    };

    const handleFieldTextChange = (e) => {
        setFields((old)=>({
            ...old,
            [e.target.name]: e.target.value
        }))
    }

    const handleApplyFilters = (e) => {
        e.preventDefault()
        setFilters(getFiltersQuery())
        setPage(1)

    }

    /*const { getTeamsForSelectDropdown } = useTeams()

    const teamsPromiseOptions = (inputValue) => {
        return new Promise((resolve) => {
            getTeamsForSelectDropdown(inputValue, (data) => {
                const result = data?.results;
                if (Array.isArray(result) && result.length > 0) {
                    resolve(
                        result
                            .filter((pre) => pre.name !== '')
                            .map((user) => {
                                return {
                                    value: user.id,
                                    label: user.name,
                                };
                            })
                    );
                } else {
                    resolve([]);
                }
            });
        });
    };

    const handleTeamChange = (choice) => {
        setFields({
            ...fields,
            teams : choice,
        });
    };*/

    return (
        <div className="p-row r-r">
            <div className="div-block-204 f-w">
                <div className="div-block-183">
                    <div className="div-block-205">
                        <div className="div-block-206"/>
                        <div className="text-block-113">Reports | Groups</div>
                    </div>
                </div>
                <div react-component="CourseTable" className="table_cont-2">
                    <div className="div-block-217">
                        <div className="reports-filters-form-block w-form">
                            <form onSubmit={handleApplyFilters} id="email-form-6" name="email-form-6" data-name="Email Form 6" method="get"
                                  className="reports-filters-form" data-wf-page-id="679cd689219630aaf2e43fff"
                                  data-wf-element-id="9800f647-0e3d-3252-e929-fb2bbe606779">
                                <div className="w-layout-hflex reports-filters-form-content">
                                    <div className="w-layout-vflex reports-filter-box reports-filter-box-date-range-container">
                                        <h5 className="reports-filter-box-title">Date range</h5>
                                        <div className="w-layout-vflex reports-filter-box-search-block" style={{cursor:"pointer"}}>

                                            <div className="w-layout-hflex flex-block-14"><img onClick={dateRangeInfo?.endDate && dateRangeInfo?.startDate ? ()=>setDateRangeInfo(defaultDateRange) : ()=>setFocusDateRangeBox(!focusDateRangeBox)}
                                                src={`/images/${dateRangeInfo?.endDate && dateRangeInfo?.startDate ? "x-3.svg" : "calendar-days-2.svg"}`} loading="lazy" alt
                                                className="image-143"/><input style={{backgroundColor:"inherit", cursor:"pointer"}} readOnly={true} onClick={()=>setFocusDateRangeBox(!focusDateRangeBox)} className="text-field-10 w-input"
                                                                              maxLength={256} name="field-6"
                                                                              data-name="Field 6"
                                                                              placeholder="" type="text" value={dateRangeInfo?.startDate && dateRangeInfo?.endDate ? `${dayjs(dateRangeInfo?.startDate).format("MMM DD")} - ${dayjs(dateRangeInfo?.endDate).format("MMM DD")}` : ""}
                                                                              id="field-6"/></div>
                                        </div>
                                        <div className={`date-range-container ${focusDateRangeBox ? "active" : ""}`}>
                                            <div onClick={()=>setFocusDateRangeBox(false)} className="date-range-container-closer"><img src="/images/x-3.svg" /></div>
                                            <DateRange
                                                editableDateInputs={true}
                                                onChange={item => {
                                                    //console.log("Date range info ", item.selection)
                                                    setDateRangeInfo(item.selection)
                                                }}
                                                moveRangeOnFirstSelection={false}
                                                ranges={[dateRangeInfo]}
                                                className={`reports-filter-box-date-range`}
                                                color={"#FFF"}
                                                maxDate={new Date()}
                                            />
                                        </div>

                                    </div>
                                    <div className="w-layout-vflex reports-filter-box">
                                        <h5 className="reports-filter-box-title">Pathway</h5>
                                        <div className="w-layout-vflex reports-filter-box-search-block">
                                            <div className="w-layout-hflex flex-block-14"><img src="/images/search-1.svg"
                                                                                               loading="lazy" alt
                                                                                               className="image-143"/><input
                                                className="text-field-10 w-input" maxLength={100} name="pathway" value={fields?.pathway} onChange={handleFieldTextChange}
                                                data-name="Field 6" placeholder type="text" id="field-6"/></div>
                                        </div>
                                    </div>
                                    <div className="w-layout-vflex reports-filter-box">
                                        <h5 className="reports-filter-box-title">Group</h5>
                                        <div className="w-layout-vflex reports-filter-box-search-block">
                                            <div className="w-layout-hflex flex-block-14"><img src="/images/search-1.svg"
                                                                                               loading="lazy" alt
                                                                                               className="image-143"/>
                                                <input name="groups" value={fields?.groups} onChange={handleFieldTextChange}
                                                       className="text-field-10 w-input" maxLength={100}
                                                       data-name="Field 6" placeholder type="text" id="field-6"/></div>
                                            {/*<Async
                                                isMulti
                                                cacheOptions
                                                classNamePrefix={'react-select'}
                                                defaultOptions
                                                loadOptions={teamsPromiseOptions}
                                                options={fields?.teams}
                                                value={fields?.teams}
                                                onChange={handleTeamChange}
                                                isSearchable={true}
                                                placeholder={""}
                                                className={"react-select-container"}
                                            />*/}
                                        </div>
                                    </div>
                                    {/*<div className="w-layout-vflex reports-filter-box">
                                        <h5 className="reports-filter-box-title">Location</h5>
                                        <div className="w-layout-vflex reports-filter-box-search-block">
                                            <div className="w-layout-hflex flex-block-14"><img src="/images/search-1.svg"
                                                                                               loading="lazy" alt
                                                                                               className="image-143"/><input
                                                className="text-field-10 w-input" maxLength={100} name="location" value={fields?.location} onChange={handleFieldTextChange}
                                                data-name="Field 6" placeholder type="text" id="field-6"/></div>
                                        </div>
                                    </div>*/}
                                    <div className="w-layout-vflex reports-filter-box">
                                        <h5 className="reports-filter-box-title">Enrollment Status</h5>
                                        <div className="w-layout-vflex reports-filter-box-search-block">
                                            <Select
                                                cacheOptions
                                                classNamePrefix={'react-select'}
                                                className={"react-select-container"}
                                                options={ENROLLMENT_STATUS_OPTIONS}
                                                value={ENROLLMENT_STATUS_OPTIONS.find((pre)=>pre.value === fields.enrollment_status)}
                                                onChange={choice=>{
                                                    setFields({
                                                        ...fields,
                                                        enrollment_status: choice?.value,
                                                    });
                                                }}
                                                placeholder={""}
                                                isClearable={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-layout-hflex reports-form-btn-block">
                                    <a onClick={handleApplyFilters} href="#" className="link-block-22 w-inline-block">
                                        <div className="div-block-207 first active follow-user reports-form-btn"><img
                                            src="/images/filter-1.svg" loading="lazy" alt className="image-152"/>
                                            <div className="text-block-111">Apply Filters</div>
                                        </div>
                                    </a>
                                    {/*<a href="#" className="link-block-22 w-inline-block">
                                        <div className="div-block-207 first active follow-user reports-form-btn"><img
                                            src="/images/arrow-down-wide-narrow.svg" loading="lazy" alt
                                            className="image-152"/>
                                            <div className="text-block-111">Import Filters</div>
                                        </div>
                                    </a>
                                    <a href="#" className="link-block-22 w-inline-block">
                                        <div className="div-block-207 first active follow-user reports-form-btn"><img
                                            src="/images/arrow-up-narrow-wide.svg" loading="lazy" alt
                                            className="image-152"/>
                                            <div className="text-block-111">Export Filters</div>
                                        </div>
                                    </a>*/}
                                </div>
                            </form>
                            <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                        </div>
                        <div react-component="table" className="table">
                            <div className="th">
                                <div className="tc centered _5">
                                    <div className="sm">#</div>
                                </div>
                                <div className="tc _12-5 centered">
                                    <div className="sm">Name</div>
                                </div>
                                <div className="tc _10 centered">
                                    <div className="sm">Time Spent</div>
                                </div>
                                <div className="tc _10 centered">
                                    <div className="sm">Total Courses Enrolled
                                    </div>
                                </div>
                                <div className="tc _10 centered">
                                    <div className="sm">Total Courses Completed
                                    </div>
                                </div>
                                <div className="tc _10 centered">
                                    <div className="sm">Average Completion Rate
                                    </div>
                                </div>
                                <div className="tc _10 centered">
                                    <div className="sm">Pathways Completed Rate
                                    </div>
                                </div>
                                <div className="tc _10 centered">
                                    <div className="sm">Certificates Earned</div>
                                </div>
                                <div className="tc _10 centered">
                                    <div className="sm">Skills Earned
                                    </div>
                                </div>
                                <div className="tc _10 centered">
                                    <div className="sm">Skills Points
                                    </div>
                                </div>
                            </div>
                            {
                                reportsLoading ?
                                    <IBLSpinLoader containerHeight={"200px"} size={20} color={"white"}/>
                                    :
                                    reports.length > 0 ?
                                        reports.map((report, index) => (
                                            <div className="tr">
                                                <div className="tc tcell _5">
                                                    <div
                                                        className="m">{getTableIndex(index, page, size)}</div>
                                                </div>
                                                <div data-w-id="4386085a-1eef-af03-c6b1-7a5a39f2991c"
                                                     className="tc tcell _12-5"><img
                                                    src="/images/female20091023302387177.jpeg" loading="lazy" alt
                                                    className="r-i hidden"/>
                                                    <div className="tl-2 np text-overflowable">{report?.name}</div>
                                                </div>
                                                <div className="tc tcell _10">
                                                    <div className="m small">{report?.time_spent ?? "-"}</div>
                                                </div>
                                                <div className="tc tcell _10">
                                                    <div className="m small">{report?.courses_enrolled ?? 0}</div>
                                                    <img src="/images/eye-svgrepo-com.svg" loading="lazy" alt
                                                         className="report-view-btn"/>
                                                </div>
                                                <div className="tc tcell _10">
                                                    <div className="m small">{report?.courses_completed ?? 0}</div>
                                                    <img src="/images/eye-svgrepo-com.svg" loading="lazy" alt
                                                         className="report-view-btn"/>
                                                </div>
                                                <div className="tc tcell _10">
                                                    <div className="m small">{report?.completion_rate}%</div>
                                                </div>
                                                <div className="tc tcell _10">
                                                    <div className="m small">{report?.pathways_completed ?? 0}</div>
                                                </div>
                                                <div className="tc tcell _10">
                                                    <div className="m small">{report?.certificates_earned ?? 0}</div>
                                                    <img src="/images/eye-svgrepo-com.svg" loading="lazy" alt
                                                         className="report-view-btn"/>
                                                </div>
                                                <div className="tc tcell _10">
                                                    <div className="m small">{report?.skills_earned ?? 0}</div>
                                                </div>
                                                <div className="tc tcell _10">
                                                    <div className="m small">{report?.skills_points ?? 0}</div>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <EmptyTableDataInfographic/>
                            }
                            <div className="tf pagination-container">
                                <div className="div-block-289">
                                    <div className="info-3">
                                        {t('Showing')}{' '}
                                        {(page - 1) * size + 1}{' '}
                                        {t('to')}{' '}
                                        {Math.min(page * size, pagination.totalItems)}{' '}
                                        {t('of')} {pagination.totalItems}
                                    </div>
                                    <ReactPaginate
                                        onPageChange={paginateLearnerReport}
                                        pageCount={pagination.totalPages}
                                        forcePage={page - 1}
                                        previousLabel={'Prev'}
                                        nextLabel={'Next'}
                                        containerClassName={'pagination'}
                                        pageLinkClassName={'page-number'}
                                        previousLinkClassName={'page-number'}
                                        nextLinkClassName={'page-number'}
                                        activeLinkClassName={'active'}
                                    />
                                    {
                                        reports.length > 0 && (
                                            <a onClick={initializeReportDownload} href="#" className={`link-block-22 w-inline-block`}>
                                                <div className={`div-block-207 first active follow-user reports-form-btn custom-submit-btn ${reportStatus==="pending" ? "disabled" :""}`}>{reportStatus==="pending" ? <span><IBLSpinLoader size={15} color={"#FFF"} /></span> : <img
                                                    src="/images/download.svg" loading="lazy" alt className="image-152"/> }
                                                    <div className="text-block-111">Download CSV</div>
                                                </div>
                                            </a>
                                        )
                                    }
                                </div>
                            </div>
                            {/*<div className="tf">
                                <div className="div-block-289">
                                    <div className="info-3">Showing 1 to 15 of 108 entries</div>
                                    <div className="pagination">
                                        <div className="arrow-invite-previous-disabled p-item">
                                            <div className="text-block fa_icon fa-arrow disabled"></div>
                                        </div>
                                        <div className="p-active p-item">
                                            <div>1</div>
                                        </div>
                                        <div className="p-item arrow-next disabled">
                                            <div className="text-block fa_icon fa-arrow"></div>
                                        </div>
                                    </div>
                                    <a href="#" className="link-block-22 w-inline-block">
                                        <div className="div-block-207 first active follow-user reports-form-btn"><img
                                            src="/images/download.svg" loading="lazy" alt className="image-152"/>
                                            <div className="text-block-111">Download CSV</div>
                                        </div>
                                    </a>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamsReportForTeamsViewContent;