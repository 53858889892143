import {getCookie} from "../helpers";

export const uploadFileToCourseStudio = async (formData, courseKey) => {
    return new Promise((_resolve)=>{
        const baseURL = process.env.REACT_APP_IBL_STUDIO_URL
        const url = `${String(baseURL).endsWith("/") ? baseURL : baseURL+"/"}assets/${encodeURIComponent(courseKey)}/`
        fetch(url, {
            method:"POST",
            body:formData,
            credentials:'include',
            headers:{
                'Accept': 'application/json, text/plain, */*',
                "X-CSRFToken": getCookie("csrftoken"),
            }
        }).then((resp)=>{
            if(!resp.ok){
                _resolve()
            }
            resp.text().then(_resolve)
        }).catch(_resolve)
    })


}

export const createCourseAPI = async (courseData) => {
    return new Promise((_resolve)=>{
        const baseURL = process.env.REACT_APP_IBL_STUDIO_URL
        const url = `${String(baseURL).endsWith("/") ? baseURL : baseURL+"/"}api/ibl/manage/course/`
        fetch(url, {
            method:"POST",
            body:JSON.stringify(courseData),
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Token ${localStorage.getItem('axd_token')}`,
            },
            credentials:'include'
        }).then((resp)=>{
            if(!resp.ok){
                _resolve()
            }
            resp.json().then(_resolve)
        }).catch(_resolve)
    })
}

export const deleteCourseAPI = async (course_key) => {
    return new Promise((_resolve)=>{
        const baseURL = process.env.REACT_APP_IBL_STUDIO_URL
        const url = `${String(baseURL).endsWith("/") ? baseURL : baseURL+"/"}api/ibl/manage/course/delete`
        fetch(url, {
            method:"POST",
            body:JSON.stringify({course_key}),
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Token ${localStorage.getItem('axd_token')}`,
            },
            credentials:'include'
        }).then((resp)=>{
            if(!resp.ok){
                _resolve()
            }
            resp.json().then(_resolve)
        }).catch(_resolve)
    })
}

export const updateCourseMetaDataAPI = async (courseData) => {
    return new Promise((_resolve)=>{
        const baseURL = process.env.REACT_APP_IBL_STUDIO_URL
        const url = `${String(baseURL).endsWith("/") ? baseURL : baseURL+"/"}api/ibl/v1/course_settings/`
        fetch(url, {
            method:"POST",
            body:JSON.stringify(courseData),
            headers:{
                'Content-Type': 'application/json',
            },
            credentials:'include'
        }).then((resp)=>{
            resp.json().then(_resolve)
        }).catch(_resolve)
    })
}

export const createCourseSection = async (sectionData) => {
    return new Promise((_resolve)=>{
        const baseURL = process.env.REACT_APP_IBL_STUDIO_URL
        const url = `${String(baseURL).endsWith("/") ? baseURL : baseURL+"/"}xblock/`
        fetch(url, {
            method:"POST",
            body:JSON.stringify(sectionData),
            headers:{
                'Content-Type': 'application/json',
            },
            credentials:'include'
        }).then((resp)=>{
            resp.json().then(_resolve)
        }).catch(_resolve)
    })
}

export const createCourseExtraSection = async (locatorAppend, sectionData, bodyAsFormData = false, additionalHeaders={}) => {
    return new Promise((_resolve)=>{
        const baseURL = process.env.REACT_APP_IBL_STUDIO_URL
        const url = `${String(baseURL).endsWith("/") ? baseURL : baseURL+"/"}xblock/${locatorAppend}`
        fetch(url, {
            method:"POST",
            body:bodyAsFormData ? sectionData : JSON.stringify(sectionData),
            headers:{
                ...(bodyAsFormData ? {} : {
                    'Content-Type': 'application/json'
                } ),
                ...additionalHeaders
            },
            credentials:'include'
        }).then((resp)=>{
            resp.json().then(_resolve)
        }).catch(_resolve)
    })
}

export const setScormCreationAPI = async (courseKey, updateData, additionalHeaders) => {
    return new Promise((_resolve)=>{
        const baseURL = process.env.REACT_APP_IBL_STUDIO_URL
        const url = `${String(baseURL).endsWith("/") ? baseURL : baseURL+"/"}settings/advanced/${courseKey}`
        fetch(url, {
            method:"POST",
            body:JSON.stringify(updateData),
            headers:{
                'Content-Type': 'application/json',
                "X-CSRFToken": getCookie("csrftoken"),
                ...additionalHeaders
            },
            credentials:'include'
        }).then((resp)=>{
            resp.json().then(_resolve)
        }).catch(_resolve)
    })
}

export const videoFileUploadAPI = async (courseKey, formData) =>{
    return new Promise((_resolve)=>{
        const baseURL = process.env.REACT_APP_IBL_STUDIO_URL
        const url = `${String(baseURL).endsWith("/") ? baseURL : baseURL+"/"}api/v1/ibl/course/${courseKey}/s3-upload`
        fetch(url, {
            method:"POST",
            body:formData,
            credentials:'include',
            headers:{
                'Accept': 'application/json, text/plain, */*',
                "X-CSRFToken": getCookie("csrftoken"),
            }
        }).then((resp)=>{
            if(!resp.ok){
                _resolve()
            }
            resp.text().then(_resolve)
        }).catch(_resolve)
    })
}