import React, {useContext, useEffect, useState} from 'react';
import './TeamsAssignmentTabContent.css'
import {IBLSpinLoader} from "@iblai/ibl-web-react-common";
import dayjs from "dayjs";
import UsersPreviewBlock from "../../../../../components/UsersPreviewBlock/UsersPreviewBlock";
import EmptyTableDataInfographic from "../../../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic";
import {getFormattedFacet, getTableSizes} from "../../../../../hooks/helpers";
import {useDebounce} from "../../../../../hooks/useDebounce";
import useLicensingUsers from "../../../../../hooks/useLicensingUsers";
import {getTeamsList} from "../../../../../hooks/utils/teams";
import {getLicensingUserGroupList} from "../../../../../hooks/utils/licensing";

const TeamsAssignmentTabContent =
    ({
         selectedTeams=[], setSelectedTeams=()=>{}
     }) => {

    //LEARNERS
    const TABLE_SIZES = getTableSizes();

    const [search, setSearch] = useState('');
    const [size, setSize] = useState(TABLE_SIZES[0]);
    const [page, setPage] = useState(1);

    const [searchTerm, setSearchTerm] = useState("")
    const [searching, setSearching] = useState(false)
    const [searchedUsers, setSearchedUsers] = useState([])
    const [allSearchedUserChecked, setAllSearchedUserChecked] = useState(false)

    const [groupUsersLoading, setGroupUsersLoading] = useState(false)

    const handleUserSearch = () => {
        setAllSearchedUserChecked(false)
        if(searchTerm.length < 2) {
            setSearchedUsers([])
            return
        }
        setSearching(true)
        /*getTeamsList({
            page_size:size,
            page,
            ...(!!searchTerm ? {
                query:searchTerm
            } : {})
        },*/
        getLicensingUserGroupList( {
            page_size:size,
            page,
            ...(searchTerm ? {
                query:searchTerm
            } : {})
        },
            (data)=>{
            const result = data?.results
            if(Array.isArray(result) && result.length > 0){
                setSearchedUsers(result)
            }else{
                setSearchedUsers([])
            }
            setSearching(false)
        })
    }

    const handleRetrieveUser = (userID) => {
        setSelectedTeams((old)=>old.filter((team)=>team?.id!==userID))
    }

    const userIsAlreadySelected = (team) => {
        return selectedTeams.findIndex((pre)=>pre.id===team.id)!==-1
    }

    const handleSearchedUserCheck = (team) => {
        if(userIsAlreadySelected(team)){
            //checked
            handleRetrieveUser(team.id)
        }else{
            handleAddUserToSelectedUsers(team)
        }
    }

    const handleAllSearchedUsersCheck = () => {
        if(allSearchedUserChecked){
            handleRetrieveAllSearchedUsersToSelectedUsers()
            setAllSearchedUserChecked(false)
        }else{
            handleAddAllSearchedUsersToSelectedUsers()
            setAllSearchedUserChecked(true)
        }
    }

    const handleAddAllSearchedUsersToSelectedUsers = () => {
        if(searchedUsers.length > 0){
            searchedUsers.forEach((team)=>{
                handleAddUserToSelectedUsers(team)
            })
        }
    }

    const handleRetrieveAllSearchedUsersToSelectedUsers = () => {
        if(searchedUsers.length > 0){
            searchedUsers.forEach((team)=>{
                handleRetrieveUser(team.id)
            })
        }
    }

    const handleAddUserToSelectedUsers = (team) => {
        if(!userIsAlreadySelected(team)){
            setSelectedTeams((old)=>[
                team,
                ...old
            ])
        }
    }

    useDebounce(handleUserSearch, 200, [searchTerm])

    useDebounce(()=>{
        if(selectedTeams.length === 0){
            setAllSearchedUserChecked(false)
        }
    }, 200, [selectedTeams])

    return (
        <>
            <div data-w-tab="Bulk" className="w-tab-pane w--tab-active">
                <div className="w-layout-vflex flex-block-36">
                    <div
                        className="w-layout-hflex reports-filters-form-content">
                        <div className="w-layout-vflex reports-filter-box">
                            <h5 className="reports-filter-box-title">Teams</h5>
                            <div
                                className="w-layout-vflex reports-filter-box-search-block flex-d-row gap-10">
                                <div className="w-layout-hflex flex-block-14">{!searching
                                    ?
                                    <img src="/images/search-1.svg"
                                         loading="lazy" alt=""
                                         className="image-143"/>
                                    :
                                    <span style={{width: "17px"}}><IBLSpinLoader size={17}
                                                                                 color={"#FFF"}/></span>}<input
                                    className="text-field-10 w-input" value={searchTerm}
                                    onChange={e => setSearchTerm(e?.target?.value)}
                                    maxLength="256" name="field-6"
                                    data-name="Field 6"
                                    placeholder="Search by name"
                                    type="text" id="field-6"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-layout-hflex flex-block-38">
                        <div react-component="table"
                             className="table team-search-table _100p full-width">
                            {
                                searchedUsers.length > 0 && (

                                    <>
                                        <div className="th">
                                            <div className="tc _10 centered">
                                                <div
                                                    className="div-block-2 options options_trigger no-margin centered">
                                                    <div className="w-embed"><label><input
                                                        checked={allSearchedUserChecked}
                                                        onChange={handleAllSearchedUsersCheck}
                                                        type="checkbox"
                                                        id="toggle"/><span></span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tc _80 centered">
                                                <div className="w_sort">
                                                    <div
                                                        className="text-block-134 fa_icon sort hidden">
                                                    </div>
                                                    <div className="sm">Full Name</div>
                                                </div>
                                            </div>
                                            {/*<div className="tc _30 centered">
                                                <div className="w_sort">
                                                    <div className="sm">Date Added</div>
                                                </div>
                                            </div>*/}
                                        </div>
                                        {
                                            searchedUsers.map((team) => (
                                                (
                                                    <div className="tr "
                                                         onClick={() => handleSearchedUserCheck(team)}>
                                                        <div className="tc tcell _10 no-pad">
                                                            <div
                                                                className="div-block-2 options options_trigger no-margin centered">
                                                                <div className="w-embed"><label><input
                                                                    type="checkbox" id="toggle"
                                                                    readOnly={true}
                                                                    checked={selectedTeams.findIndex((pre) => pre.id === team.id) !== -1}
                                                                /><span></span></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            data-w-id="03fefb47-44d1-aba8-5c75-91d1533071dc"
                                                            className="tc tcell _80 no-pad-y">
                                                            <a href="#"
                                                               onClick={e => e.preventDefault()}
                                                               className="tl-2 np"><span
                                                                className="nb">{team?.name}</span></a>
                                                        </div>
                                                        {/*<div className="tc tcell _30 no-pad-y">
                                                            <div
                                                                className="m smaller">{team?.added_on ? dayjs(team?.added_on).format("MMM DD, YYYY") : "-"}</div>
                                                        </div>*/}
                                                    </div>
                                                )
                                            ))
                                        }
                                        {
                                            searchTerm && searchedUsers.length === 0 && !searching &&
                                            <EmptyTableDataInfographic label={"No team found"}/>
                                        }
                                    </>
                                )
                            }
                        </div>
                        {
                            selectedTeams.length > 0 && (
                                <UsersPreviewBlock {...{
                                    selectedUsers:selectedTeams,
                                    setSelectedUsers:setSelectedTeams,
                                    handleRetrieveUser,
                                    searchedUsers,
                                    /*nonFloating:true,
                                    fullHeight:true,*/
                                    title: "Teams Preview",
                                    inLayoutPreview: true,
                                    headingTitleSmaller: true,
                                    idKey:"id",
                                    defaultImg:"/images/team-img.png"
                                }} />
                            )
                        }
                    </div>
                </div>
            </div>
        </>

    );
    };

export default TeamsAssignmentTabContent;