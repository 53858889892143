import {getTenant, isCurrentUserOnlyDepartmentManager} from "../helpers";
import {getUserId, getUserName} from "@iblai/ibl-web-react-common";

export const assignContent = (contentType="course", data, callback, /*isGroup=false, unassign=false*/) => {
    const currentTenant = getTenant()
    const dataToSubmit = {
        ...data,
        platform_key:currentTenant,
        ...(isCurrentUserOnlyDepartmentManager() ? {
            department_mode:true
        } : {}),
    }
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/catalog/suggestions/${contentType}/manage/`//assignment/${isGroup ? "group/" : ""}${unassign ? "?"+new URLSearchParams(dataToSubmit).toString() : ""}`
    fetch(url, {
        method: "POST",//!unassign ? 'POST' : 'DELETE',
        body: JSON.stringify(dataToSubmit),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp) => {
        callback(resp.status)
    }).catch(err => {
        //
    });
}

export const unAssignContent = (contentType="course", assignmentID) => {
    const currentTenant = getTenant()
    const dataToSubmit = {
        suggestion_id:assignmentID,
        platform_key:currentTenant,
        ...(isCurrentUserOnlyDepartmentManager() ? {
            department_mode:1
        } : {}),
    }
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/catalog/suggestions/${contentType}/manage/?${new URLSearchParams(dataToSubmit).toString()}`
    return fetch(url, {
        method: "DELETE",//!unassign ? 'POST' : 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    })
}


export const getUserAssignments = (contentType, callback, username=null) =>{
    username = username ?? getUserName()
    const query = {
        username:username,
        platform_key:getTenant(),
        ...(isCurrentUserOnlyDepartmentManager() ? {
            department_mode:1
        } : {}),
    }
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/catalog/suggestions/${contentType}/user/?${new URLSearchParams(query).toString()}`
    fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp) => {
        if(!resp.ok){
            throw new Error("User assignments fetching failed!")
        }
        resp.json().then((data)=>{
            callback(data)
        })
    }).catch(err => {
        callback(null)
    });
}