import React, {useEffect, useState} from 'react';
import './TeamsNotificationEventViewContent.css'
import useTeamsNotifications from "../../../hooks/useTeamsNotifications";
import useTeamsAssignments from "../../../hooks/useTeamsAssignments";
import useLicensingUsers from "../../../hooks/useLicensingUsers";
import Async from "react-select/async";
import useTeams from "../../../hooks/useTeams";
import dayjs from "dayjs";
import {IBLSpinLoader} from "@iblai/ibl-web-react-common";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "@draft-js-plugins/image/lib/plugin.css"
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import createVideoPlugin from '@draft-js-plugins/video';
import createImagePlugin from '@draft-js-plugins/image';


const TeamsNotificationEventViewContent = () => {

    const {
        submitting, notificationChannels, setFields,
        fields, handleSubmit, handleTextInputChange, handleMultiDropdownChange, editorState, setEditorState
    } = useTeamsNotifications()
    const {getProgramsForSelectDropdown} = useTeamsAssignments()
    const {getTeamsForSelectDropdown} = useTeams()
    const {getUsersForSelectDropdown} = useLicensingUsers({})

    //WYSIWIG
    const videoPlugin = createVideoPlugin();
    const imagePlugin = createImagePlugin();

    const TOOLBAR_OPTIONS = {
        options: ['inline', 'blockType', 'fontSize', 'colorPicker', 'link', 'emoji', 'remove', 'history']
    }

    const onEditorStateChange = newState => {
        setEditorState(newState);
        setFields(old => ({
            ...old,
            message: stateToHTML(newState.getCurrentContent())
        }));
    };

    /*useEffect(() => {
        setEditorState(EditorState.createWithContent(stateFromHTML(props?.fields?.comment)));
    }, []);*/

    //FILTER FIELDS
    const coursePromiseOptions = (inputValue) => {
        return new Promise((resolve) => {
            getProgramsForSelectDropdown(inputValue, (data) => {
                const result = data?.results;
                if (Array.isArray(result) && result.length > 0) {
                    resolve(
                        result
                            .map((program) => {
                                return {
                                    value: program?.data?.program_id,
                                    label: program?.data?.name,
                                };
                            })
                    );
                } else {
                    resolve([]);
                }
            });
        });
    };

    const teamsPromiseOptions = (inputValue) => {
        return new Promise((resolve) => {
            getTeamsForSelectDropdown(inputValue, (data) => {
                const result = data?.results;
                if (Array.isArray(result) && result.length > 0) {
                    resolve(
                        result
                            .filter((pre) => pre.name !== '')
                            .map((team) => {
                                return {
                                    value: team.id,
                                    label: team.name,
                                };
                            })
                    );
                } else {
                    resolve([]);
                }
            });
        });
    };

    const usersPromiseOptions = (inputValue) => {
        return new Promise((resolve) => {
            getUsersForSelectDropdown(inputValue, (data) => {
                const result = data?.results;
                if (Array.isArray(result) && result.length > 0) {
                    resolve(
                        result
                            .map((user) => {
                                return {
                                    value: user.email,
                                    label: user.name || user.username || user.email,
                                };
                            })
                    );
                } else {
                    resolve([]);
                }
            });
        });
    };


    return (
        <div className="p-row r-r">
            <div className="div-block-204 f-w">
                <div className="div-block-183">
                    <div className="div-block-205">
                        <div className="div-block-206"/>
                        <div className="text-block-113">Notifications</div>
                    </div>
                </div>
                <div className="div-block-640">
                    <div className="form-block-7 w-form">
                        <form id="email-form-5" name="email-form-5" data-name="Email Form 5" method="get"
                              data-wf-page-id="67bdd8489f4824a7dce4f044"
                              data-wf-element-id="99e3b7bb-8ff2-4189-0119-a722332196b8"><label htmlFor="field-5"
                                                                                               className="text-block-139-bd-f-soc less-bold">Program</label>
                            <Async
                                isMulti
                                cacheOptions
                                classNamePrefix={'react-select'}
                                defaultOptions
                                loadOptions={coursePromiseOptions}
                                value={fields?.programs}
                                options={fields?.programs}
                                onChange={choice => handleMultiDropdownChange(choice, "programs")}
                                isSearchable={true}
                                placeholder={"Search for programs..."}
                            />
                            <label htmlFor="field-5" className="text-block-139-bd-f-soc less-bold">Teams</label>
                            <Async
                                isMulti
                                cacheOptions
                                classNamePrefix={'react-select'}
                                defaultOptions
                                loadOptions={teamsPromiseOptions}
                                value={fields?.groups}
                                options={fields?.groups}
                                onChange={choice => handleMultiDropdownChange(choice, "groups")}
                                isSearchable={true}
                                placeholder={"Search for teams..."}
                            />
                            {/*<label htmlFor="field-5" className="text-block-139-bd-f-soc less-bold">Location</label><select id="field-5" name="field-5" data-name="Field 5" className="select-field-13 less-huge w-select">
                            <option value>Select locations</option>
                            <option value="First">Student License</option>
                            <option value="Second">High School License</option>
                            <option value="Third">Grade I License</option>
                        </select>*/}
                            <label htmlFor="field-5" className="text-block-139-bd-f-soc less-bold">Learners</label>
                            <Async
                                isMulti
                                cacheOptions
                                classNamePrefix={'react-select'}
                                defaultOptions
                                loadOptions={usersPromiseOptions}
                                value={fields?.learners}
                                options={fields?.learners}
                                onChange={choice => handleMultiDropdownChange(choice, "learners")}
                                isSearchable={true}
                                placeholder={"Search for learners..."}
                            />
                            <div className="w-layout-hflex flex-block-39">
                                <div className="w-layout-vflex flex-block-40">
                                    <label htmlFor="field-8"
                                           className="text-block-139-bd-f-soc less-bold">Channels
                                        *</label>
                                    <Select
                                        className={"react-select-container"}
                                        isMulti
                                        classNamePrefix={'react-select'}
                                        value={fields?.channels}
                                        options={notificationChannels}
                                        onChange={choice => setFields({
                                            ...fields,
                                            channels: choice
                                        })}
                                        isSearchable={true}
                                        placeholder={"Select channels..."}
                                    />
                                    <label htmlFor="field-8"
                                           className="text-block-139-bd-f-soc less-bold">Subject *</label>
                                    <input className="text-field-12 w-input" maxLength={100} name="subject"
                                           data-name="Field 8" placeholder="Subject" type="text"
                                           onChange={handleTextInputChange}
                                           id="field-8" value={fields.subject}/>
                                    <label htmlFor="field-8"
                                           className="text-block-139-bd-f-soc less-bold">Date</label>
                                    <input className="text-field-12 w-input" maxLength={10} name="date"
                                           data-name="Field 8" placeholder="Select a date" type="date"
                                           onChange={handleTextInputChange} min={dayjs().format("YYYY-MM-DD")}
                                           id="field-8" value={fields.date}/>
                                    <label htmlFor="field-9" className="text-block-139-bd-f-soc less-bold">Message
                                        *</label>
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="wysiwig-toolbar-container border-top-0 border-left-0 border-right-0 p-0"
                                        wrapperClassName="demo-wrapper"
                                        editorStyle={{ height: "200px" }}
                                        editorClassName="demo-editor"
                                        onEditorStateChange={onEditorStateChange}
                                        plugins={[videoPlugin, imagePlugin]}
                                        //toolbar={TOOLBAR_OPTIONS}
                                    />
                                    {/*<textarea placeholder="Type in your message" maxLength={5000} id="field-9"
                                              name="message" onChange={handleTextInputChange} data-name="Field 9"
                                              className="textarea-4 w-input" value={fields.message}/>*/}
                                    <div className="div-block-264 f-w m-top-more jc-space-btw" style={{marginBottom:"10px"}}>
                                        <div onClick={handleSubmit}
                                             className={`div-block-207 first active follow-user redeem-btn mg-l16 mr-10 more-gap custom-submit-btn ${submitting ? "disabled" : ""}`}>
                                            {submitting ? <span><IBLSpinLoader size={20} color={"#FFF"}/></span> :
                                                <img src="/images/send.svg" loading="lazy" alt className="image-155"/>}
                                            <div className="text-block-111 red-text cf">Send notification</div>
                                        </div>
                                        {/*<div
                                            className="div-block-207 first active follow-user alt-btn cancel-program-enroll more-gap">
                                            <img src="/images/paperclip-1.svg" loading="lazy" alt
                                                 className="image-155"/>
                                            <div className="text-block-111 red-text cf">Import CSV file</div>
                                        </div>*/}
                                    </div>
                                </div>
                                <div className="w-layout-vflex flex-block-41"><label htmlFor="field-10"
                                                                                     className="text-block-139-bd-f-soc less-bold">Preview</label>
                                    <div className="w-layout-hflex flex-block-42">
                                        <div className="w-layout-hflex flex-block-44"><img src="/images/bell.svg"
                                                                                           loading="lazy" alt
                                                                                           className="image-156"/></div>
                                        <div className="w-layout-hflex flex-block-43"><label htmlFor="field-10"
                                                                                             className="text-block-139-bd-f-soc no-m-y">{fields?.subject || "New Notification"}</label>
                                            <div dangerouslySetInnerHTML={{__html:fields.message ?? ""}}></div>
                                        </div>
                                        <img src="/images/x-5.svg" loading="lazy" alt className="image-157"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamsNotificationEventViewContent;