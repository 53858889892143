import React, {useContext, useEffect, useState} from "react";
import "./TeamsAddNewViewContent.css"
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getFormattedFacet, getTableSizes} from "../../../hooks/helpers";
import useLicensingUsers from "../../../hooks/useLicensingUsers";
import {useDebounce} from "../../../hooks/useDebounce";
import {getLicensingUsersListInGroup} from "../../../hooks/utils/licensing";
import UsersAdvancedSearchModal from "../../../components/UsersAdvancedSearchModal/UsersAdvancedSearchModal";
import dayjs from "dayjs";
import EmptyTableDataInfographic from "../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic";
import {AppDataContext, IBLSpinLoader} from "@iblai/ibl-web-react-common";
import UsersPreviewBlock from "../../../components/UsersPreviewBlock/UsersPreviewBlock";
import useTeams from "../../../hooks/useTeams";
import {getTeamLearnersList} from "../../../hooks/utils/teams";

const TeamsAddNewViewContent = () => {
    const {teamID} = useParams()
    const { t } = useTranslation();
    const navigate = useNavigate()
    const {setNotification} = useContext(AppDataContext)

    const TABLE_SIZES = getTableSizes();

    const [search, setSearch] = useState('');
    const [size, setSize] = useState(TABLE_SIZES[0]);
    const [page, setPage] = useState(1);
    const {getUsersForSelectDropdown, createUserGroupsAndAssignUsers, handleAssignUserToGroup, getAdvancedFilterUsersForSelectDropdown} = useLicensingUsers({search, size, page})
    const {createTeamWithMembers, submitting, setSubmitting, updateTeamWithMembers} = useTeams()
    const [fields, setFields] = useState({
        groupName:"",
        users:[]
    })
    const handleSetNotification = (success, msg, timeout=2500) => {
        setNotification({
            msg: msg,
            success
        })
    }
    const [searchTerm, setSearchTerm] = useState("")
    const [searching, setSearching] = useState(false)
    const [searchedUsers, setSearchedUsers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [allSearchedUserChecked, setAllSearchedUserChecked] = useState(false)

    const [groupUsersLoading, setGroupUsersLoading] = useState(false)

    const handleUserSearch = () => {
        setAllSearchedUserChecked(false)
        if(searchTerm.length < 2) {
            setSearchedUsers([])
            return
        }
        setSearching(true)
        getUsersForSelectDropdown(searchTerm, (data)=>{
            const result = data?.results
            if(Array.isArray(result) && result.length > 0){
                setSearchedUsers(result)
            }else{
                setSearchedUsers([])
            }
            setSearching(false)
        })
    }

    const handleRetrieveUser = (userID) => {
        setSelectedUsers((old)=>old.filter((user)=>user?.user_id!==userID))
    }

    const userIsAlreadySelected = (user) => {
        return selectedUsers.findIndex((pre)=>pre.user_id===user.user_id)!==-1
    }

    const handleSearchedUserCheck = (user) => {
        if(userIsAlreadySelected(user)){
            //checked
            handleRetrieveUser(user.user_id)
        }else{
            handleAddUserToSelectedUsers(user)
        }
    }

    const handleAllSearchedUsersCheck = () => {
        if(allSearchedUserChecked){
            handleRetrieveAllSearchedUsersToSelectedUsers()
            setAllSearchedUserChecked(false)
        }else{
            handleAddAllSearchedUsersToSelectedUsers()
            setAllSearchedUserChecked(true)
        }
    }

    const handleAddAllSearchedUsersToSelectedUsers = () => {
        if(searchedUsers.length > 0){
            searchedUsers.forEach((user)=>{
                handleAddUserToSelectedUsers(user)
            })
        }
    }

    const handleRetrieveAllSearchedUsersToSelectedUsers = () => {
        if(searchedUsers.length > 0){
            searchedUsers.forEach((user)=>{
                handleRetrieveUser(user.user_id)
            })
        }
    }

    const handleAddUserToSelectedUsers = (user) => {
        if(!userIsAlreadySelected(user)){
            setSelectedUsers((old)=>[
                user,
                ...old
            ])
        }
    }

    useDebounce(handleUserSearch, 200, [searchTerm])

    useDebounce(()=>{
        if(selectedUsers.length === 0){
            setAllSearchedUserChecked(false)
        }
    }, 200, [selectedUsers])

    useEffect(() => {
        if(teamID){
            setGroupUsersLoading(true)
            getTeamLearnersList( {
                department_id:teamID,
            }, (data)=>{
                setSelectedUsers(Array.isArray(data?.results) ? data.results.map((user)=>({
                    user_id:user.user_id,
                    name:user?.name,
                    profile_image:user?.public_metadata?.public_metadata ?? "/images/default_avatar.png"
                })) : [])
                setGroupUsersLoading(false)
            }, ()=>{
                setSelectedUsers([])
                setGroupUsersLoading(false)
            })
        }
    }, [teamID]);



    const handleSuccess = () => {
        setSubmitting(false)
        handleSetNotification(true, !!teamID ? "Users updated!" : "Group created successfully!")
    }

    const handleError = (msg) => {
        setSubmitting(false)
        handleSetNotification(true,  "An error occurred. Please try again!")
    }

    const handleSubmit = async (e) => {
        if((!teamID && !fields?.groupName) || selectedUsers.length===0){
            handleSetNotification(false, "Fill out all required fields.", 5000)
            return
        }
        setSubmitting(true)
        const updatedFields = {...fields, users:selectedUsers.map((user)=>({user_id:user?.user_id, is_admin:true}))}
        if(teamID){
            if(await updateTeamWithMembers({teamID, users:updatedFields.users, showNotification:true})){
                setSubmitting(false)
            }
        }else{
            if(await createTeamWithMembers({teamData:{
                    name:updatedFields.groupName
                }, users:updatedFields.users}))
            {
                navigate('/teams/list')
            }
        }
    }

    //ADVANCED SEARCH

    const [advancedSearchFocused, setAdvancedSearchFocused] = useState(false)
    const [advancedSearchFacet, setAdvancedSearchFacet] = useState([])
    const [selectedAdvancedSearchQuery, setSelectedAdvancedSearchQuery] = useState([])
    const [selectedAdvancedSearchQueryPreviewList, setSelectedAdvancedSearchQueryPreviewList] = useState([])
    const [loadingFacets, setLoadingFacets]= useState(false)
    const [activeFacet, setActiveFacet] = useState("")

    const handleLoadAdvancedSearchFacets = () => {
        if(advancedSearchFocused && advancedSearchFacet.length === 0){
            setLoadingFacets(true)
            getAdvancedFilterUsersForSelectDropdown({}, (data)=>{
                const formattedFacets = getFormattedFacet(data?.facets)
                setAdvancedSearchFacet(formattedFacets)
                setLoadingFacets(false)
            })
        }

    }

    const handleFilterCheckboxChange = (value, previewData) => {
        if(selectedAdvancedSearchQuery.includes(value)){
            setSelectedAdvancedSearchQuery((old)=>old.filter((query)=>query!==value))
            setSelectedAdvancedSearchQueryPreviewList((old)=>old.filter((data)=>data?.value!==value))
        }else{
            setSelectedAdvancedSearchQuery((old)=>[
                value,
                ...old
            ])
            setSelectedAdvancedSearchQueryPreviewList((old)=>[
                previewData,
                ...old
            ])
        }
    }

    const handleClearFilter = () => {
        setSelectedAdvancedSearchQueryPreviewList([])
        setSelectedAdvancedSearchQuery([])
        setActiveFacet("")
        setAdvancedSearchFocused(false)
        setSearchedUsers([])
    }

    const handleAdvancedSearchFilterSubmit = () => {
        setAdvancedSearchFocused(false)
        setSearchTerm("")
        setSearching(true)
        getAdvancedFilterUsersForSelectDropdown(selectedAdvancedSearchQuery.join("&"), (data)=>{
            if(Array.isArray(data?.results) && data?.results.length > 0){
                const seen = new Set();
                setSearchedUsers(data?.results.filter((pre)=>{
                    const userInfo = pre?.user_info;
                    if (!userInfo) return false; // Skip if no user_info exists
                    const userId = userInfo.id; // Get the user ID
                    if (seen.has(userId)) return false; // Skip if user ID is already seen
                    seen.add(userId); // Add user ID to the Set
                    return true; // Keep this user
                }).map((user)=>({
                    ...user?.user_info,
                    profile_image:user?.user_info?.public_metadata?.profile_image?.image_url_full,
                    added_on:user?.user_info?.date_joined
                })))
            }else{
                setSearchedUsers([])
            }
            setSearching(false)
        })
    }

    useDebounce(handleLoadAdvancedSearchFacets, 200, [advancedSearchFocused])


  return (
      <>
          <div className="p-row r-r">
              <div className="div-block-204 f-w">
                  <div className="div-block-183">
                      <div className="div-block-205">
                          <div className="div-block-206"/>
                          <div className="text-block-113">{teamID ? "Update Team" : "Add New Team Manager"}</div>
                      </div>
                      <div className="div-block-308">
                          <Link prop-events-value-onclick="handleAddNewCourseClick" prop-events-names="onClick"
                             to={'/teams/list'} className="link-block-22 w-inline-block">
                              <div className="div-block-207 first active follow-user back-to-course-btn"><img
                                  src="/images/move-left.svg" loading="lazy" alt className="back-to-course-btn-icon"/>
                                  <div className="text-block-111">Back to all teams managers list</div>
                              </div>
                          </Link>
                      </div>
                  </div>
                  <div className="div-block-639">
                      <div className="hf_popup program-enroll-popup active with-sidebar no-floating-sidebar">
                          <div className="div-block-147 new_msg">
                              <div className="text-block-135 mg-0 smaller">Fill out the required fields
                              </div>
                          </div>
                          <div className="div-block-228">
                              <div className="form-block-7 w-form">
                                  <form onSubmit={e=>e.preventDefault()} id="email-form-5" name="email-form-5" data-name="Email Form 5" method="get"
                                        data-wf-page-id="67acac38449467e4aa62cf78"
                                        data-wf-element-id="f2cc1e8b-a88d-ee0c-34cc-0da31021b8c5">
                                      {
                                          !teamID && (
                                              <>
                                                  <label
                                                      htmlFor="name-3" className="text-block-139-bd-f-soc">Team manager name
                                                      *</label>
                                                  <input value={fields?.groupName} onChange={!teamID ? e => setFields({
                                                      ...fields,
                                                      groupName: e?.target?.value
                                                  }) : () => {
                                                  }} disabled={!!teamID} readOnly={!!teamID}
                                                         className="text-field-3 w-input license-name-input" maxLength="256"
                                                         name="name-2"
                                                         data-name="Name 2" placeholder="Team manager name" type="text"
                                                         id="name-2"/>
                                              </>
                                          )
                                      }

                                      <div className="w-layout-hflex flex-block-27"><label htmlFor="name-3"
                                                                                           className="text-block-139-bd-f-soc">Team
                                          managers *</label>
                                          <div onClick={() => setAdvancedSearchFocused(true)}
                                               data-w-id="f2cc1e8b-a88d-ee0c-34cc-0da31021b8cc"
                                               className="div-block-207 first active follow-user alt-btn">
                                              <div className="text-block-111 red-text cf">Advanced Search</div>
                                          </div>
                                      </div>
                                      <div className="w-layout-hflex flex-block-14">{!searching
                                          ?
                                          <img src="/images/search-1.svg"
                                               loading="lazy" alt=""
                                               className="image-143"/>
                                          :
                                          <span style={{width: "17px"}}><IBLSpinLoader size={17}
                                                                                       color={"#FFF"}/></span>}<input
                                          className="text-field-10 w-input" value={searchTerm}
                                          onChange={e => setSearchTerm(e?.target?.value)} maxLength="256" name="field-6"
                                          data-name="Field 6"
                                          placeholder="Search by name, username, email..." type="text" id="field-6"/>
                                      </div>
                                      <div react-component="table" className="table user-search-table">
                                          {
                                              searchedUsers.length > 0 && (

                                                  <>
                                                      <div className="th">
                                                          <div className="tc _10 centered">
                                                              <div
                                                                  className="div-block-2 options options_trigger no-margin centered">
                                                                  <div className="w-embed"><label><input
                                                                      checked={allSearchedUserChecked}
                                                                      onChange={handleAllSearchedUsersCheck} type="checkbox"
                                                                      id="toggle"/><span></span></label>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                          <div className="tc _60 centered">
                                                              <div className="w_sort">
                                                                  <div className="text-block-134 fa_icon sort hidden">
                                                                  </div>
                                                                  <div className="sm">Full Name</div>
                                                              </div>
                                                          </div>
                                                          <div className="tc _30 centered">
                                                              <div className="w_sort">
                                                                  <div className="sm">Date Added</div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      {
                                                          searchedUsers.map((user) => (
                                                              (
                                                                  <div className="tr "
                                                                       onClick={() => handleSearchedUserCheck(user)}>
                                                                      <div className="tc tcell _10 no-pad">
                                                                          <div
                                                                              className="div-block-2 options options_trigger no-margin centered">
                                                                              <div className="w-embed"><label><input
                                                                                  type="checkbox" id="toggle"
                                                                                  readOnly={true}
                                                                                  checked={selectedUsers.findIndex((pre) => pre.user_id === user.user_id) !== -1}
                                                                              /><span></span></label>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                      <div data-w-id="03fefb47-44d1-aba8-5c75-91d1533071dc"
                                                                           className="tc tcell _60 no-pad-y">
                                                                          <a href="#" onClick={e => e.preventDefault()}
                                                                             className="tl-2 np"><span
                                                                              className="nb">{user?.name || user?.username || user?.email}</span></a>
                                                                      </div>
                                                                      <div className="tc tcell _30 no-pad-y">
                                                                          <div
                                                                              className="m smaller">{user?.added_on ? dayjs(user?.added_on).format("MMM DD, YYYY") : "-"}</div>
                                                                      </div>
                                                                  </div>
                                                              )
                                                          ))
                                                      }
                                                      {
                                                          searchTerm && searchedUsers.length === 0 && !searching &&
                                                          <EmptyTableDataInfographic label={"No user found"}/>
                                                      }
                                                  </>
                                              )
                                          }
                                      </div>
                                  </form>
                                  <div className="w-form-done">
                                      <div>Thank you! Your submission has been received!</div>
                                  </div>
                                  <div className="w-form-fail">
                                      <div>Oops! Something went wrong while submitting the form.</div>
                                  </div>
                              </div>
                          </div>
                          <div className="div-block-147 new_msg no-border-b">
                              <div className="div-block-264 f-w">
                              <div onClick={!submitting ? handleSubmit : () => {
                                  }} data-w-id="5f64487e-caf3-b6dd-b426-6888986a7ec9"
                                       className="div-block-207 first active follow-user redeem-btn mg-l16 program-enroll">
                                      <div className="text-block-111 red-text cf">{teamID ? "Update" : "Add"}</div>
                                      {submitting && <span style={{marginLeft: "5px"}}> <IBLSpinLoader size={15}
                                                                                                       color={"#FFF"}/> </span>}
                                  </div>
                              </div>
                          </div>
                          <div className="w-layout-vflex enroll-notification-block"><img src="/images/check_2.png"
                                                                                         loading="lazy" sizes="100vw"
                                                                                         srcSet="images/check_2-p-500.png 500w, images/check_2.png 512w"
                                                                                         alt className="image-141"/>
                              <h4 className="heading-4">Program enrolled successfully</h4>
                              <div data-w-id="f2cc1e8b-a88d-ee0c-34cc-0da31021b99f"
                                   className="div-block-207 first active follow-user alt-btn cancel-program-enroll">
                                  <div className="text-block-111 red-text cf">Close</div>
                              </div>
                          </div>
                      </div>
                      {
                          selectedUsers.length > 0 && (
                              <UsersPreviewBlock {...{
                                  selectedUsers,
                                  setSelectedUsers,
                                  handleRetrieveUser,
                                  searchedUsers,
                                  nonFloating:true,
                                  fullHeight:true,
                                  title:"Team managers Preview"
                              }} />
                          )
                      }
                  </div>
              </div>
          </div>
          {
              advancedSearchFocused && <UsersAdvancedSearchModal {...{
                  loadingFacets,
                  advancedSearchFacet,
                  selectedAdvancedSearchQueryPreviewList,
                  handleFilterCheckboxChange,
                  setActiveFacet,
                  activeFacet,
                  selectedAdvancedSearchQuery,
                  setAdvancedSearchFocused,
                  handleClearFilter,
                  handleAdvancedSearchFilterSubmit
              }} />
          }
      </>
  );
};

export default TeamsAddNewViewContent;
