import React from 'react';
import './UsersPreviewBlock.css'
const UsersPreviewBlock =
    ({
         selectedUsers=[],
         setSelectedUsers=()=>{},
         handleRetrieveUser=()=>{},
         searchedUsers=[],
        nonFloating=false,
        fullHeight=false,
        title="Users Preview",
        inLayoutPreview=false,
        headingTitleSmaller=false,
        idKey="user_id",
        defaultImg= "/images/user-default-avatar.png"
     }) => {
    return (
        <div className={`w-layout-vflex user-search-preview-block ${inLayoutPreview ? "in-layout-search-preview" : ""} ${fullHeight ? "h-auto" : "" } ${nonFloating ? "not-floating" : ""}`}>
            <div className="w-layout-hflex flex-block-26">
                <h5 className={`user-search-preview-heading ${headingTitleSmaller ? "smaller" : ""}`}>{title}
                    ({selectedUsers.length})</h5>
                {
                    <a href="#" onClick={(e) => {
                        e.preventDefault()
                        setSelectedUsers([])
                    }} className="link-5">Clear all</a>
                }
            </div>
            <div className="w-layout-hflex flex-block-25">
                <div
                    className={`w-layout-vflex user-search-preview-content ${fullHeight ? "full-height" : ""} ${selectedUsers.length > 12 && searchedUsers.length === 0 ? "more-pad" : ""}`}>
                    {
                        selectedUsers.map((user, index) => (
                            <div title={user.name || user?.username || user.email} key={`user-search-preview-box-${index}`}
                                 className="w-layout-vflex user-search-preview-box">
                                <div className="div-block-638"
                                     style={{backgroundImage: `url(${user?.profile_image ?? defaultImg})`}}></div>
                                <div className="text-block-185">{user.name || user?.username || user.email}</div>
                                <div onClick={() => handleRetrieveUser(user?.[idKey])}
                                     className="w-layout-hflex pathway-content-box-closer user-preview-closer">
                                    <img
                                        src="/images/x-5.svg" loading="lazy" alt=""
                                        className="image-149"/>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default UsersPreviewBlock;