import {useEffect, useState} from 'react';
import {useDebounce} from "./useDebounce";
import {getTeamsList} from "./utils/teams";
import {getLearnersTeamsAssignmentList, getSkillsLearnersTeamsAssignmentList} from "./utils/teamsAssignments";  // Replace with the correct path

const useLearnersTeamsAssignmentsList = ({contentType="course",search="", page=1, size=10, refresher=null}) => {
    const [learners, setLearners] = useState([]);
    const [learnersLoading, setLearnersLoading] = useState(false);
    const [learnersPagination, setLearnersPagination] = useState({
        totalItems:0,
        currentPage:1,
        totalPages:0,
        pageSize:0
    });


    const handleTeamListRetrieval = (data) => {
        setLearners(Array.isArray(data?.results) ? data.results : [])
        setLearnersPagination({
            totalItems:  data?.count,
            currentPage: page,
            totalPages: Math.ceil(data?.count / size),
            pageSize: size,
        });
        setLearnersLoading(false)
    }

    useDebounce(()=>{
        setLearnersLoading(true)
        if(contentType==="skill"){
            getSkillsLearnersTeamsAssignmentList({
                page_size:size,
                page,
                ...(!!search ? {
                    query:search
                } : {})
            }, handleTeamListRetrieval, handleTeamListRetrieval)
        }else{
            getLearnersTeamsAssignmentList( contentType, {
                page_size:size,
                page,
                ...(!!search ? {
                    query:search
                } : {})
            }, handleTeamListRetrieval, handleTeamListRetrieval)
        }
    }, 200, [search, page, size, refresher, contentType])

    return {
        learnersLoading, learners,
        learnersPagination
    }


};

export default useLearnersTeamsAssignmentsList;
