import React, { useState } from 'react';

import './StudioAuthoringProgramsViewContent.css';
import { Link } from 'react-router-dom';
import {
  getRamdomCourseImg,
  getStudioURL, getTableIndex,
  getTableSizes,
} from '../../../hooks/helpers';
import TableSizeDropdown from '../../../components/TableSizeDropdown/TableSizeDropdown';
import { getUserName, IBLSpinLoader } from '@iblai/ibl-web-react-common';
import EmptyTableDataInfographic from '../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic';
import { useStudioAuthoringPrograms } from '../../../hooks/useStudioAuthoringPrograms';
import ReactPaginate from "react-paginate";
import {useTranslation} from "react-i18next";

const studioURL = getStudioURL();
const TABLE_SIZES = getTableSizes();

const StudioAuthoringProgramsViewContent = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(TABLE_SIZES[0]);
  const [programs, programsLoaded, pagination] = useStudioAuthoringPrograms(getUserName(), page, size, search);

  const paginate = ({ selected }) => {
    setPage(selected + 1);
  };

  return (
    <>
      <div className="div-block-183">
        <div className="div-block-205">
          <div className="div-block-206"></div>
          <div className="text-block-113">AUTHORING | PROGRAMS</div>
        </div>
        <div className="div-block-308">
          <Link
            to={studioURL}
            target="_blank"
            prop-events-value-onclick="handleAddNewCourseClick"
            prop-events-names="onClick"
            href="#"
            className="link-block-22 w-inline-block"
          >
            <div className="div-block-207 first active follow-user">
              <div className="text-block-111">Add New Program</div>
            </div>
          </Link>
        </div>
      </div>
      <div react-component="CourseTable" className="table_cont-2">
        <div className="div-block-217">
          <div className="div-block-169">
            <div>
              <div
                prop-data-value-tablename="Programs"
                prop-data-names="tableName"
                className="text-block-135"
              >
                Programs
                <br />
              </div>
            </div>
          </div>
          <div className="div-block-169">
            <div className="div-block-171">
              <div className="sm">Show</div>
              <div className="form-block w-form">
                <TableSizeDropdown setSize={setSize} setPage={setPage} />
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
              <div className="sm">entries</div>
            </div>
            <div className="div-block-171">
              <div className="sm">Search:</div>
              <div className="form-block w-form">
                <form
                  id="email-form-2"
                  name="email-form-2"
                  data-name="Email Form 2"
                  method="get"
                  data-wf-page-id="65830fc855f7d3a66ce3da0b"
                  data-wf-element-id="718b5728-a03b-be9a-4e5e-ebcb03ab51be"
                >
                  <input
                    onChange={(e) => {
                      setPage(1)
                      setSearch(e?.target?.value)
                    }}
                    className="input w-input"
                    maxLength="256"
                    name="field-3"
                    data-name="Field 3"
                    placeholder=""
                    prop-events-value-onchange="handleSearchBarOnChange"
                    type="text"
                    id="field-3"
                    prop-events-names="onChange"
                    required=""
                  />
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div react-component="table" className="table">
            <div className="th">
              <div className="tc _10">
                <div className="sm">#</div>
              </div>
              <div className="tc _40">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">Name</div>
                </div>
              </div>
              <div className="tc _25">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">ID</div>
                </div>
              </div>
              <div className="tc _25">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">Slug</div>
                </div>
              </div>
            </div>
            {!programsLoaded ? (
                <IBLSpinLoader
                    color={'#FFF'}
                    size={20}
                    containerHeight={'200px'}
                />
            ) : Array.isArray(programs) && programs.length > 0 ? (
                programs.map((program, index) => (
                    <div
                        key={index}
                        react-component="AuthoringProgramsRow"
                        className="tr"
                    >
                      <div className="tc tcell _10">
                        <div prop-rowindex="" className="m">
                          {getTableIndex(index, page, size)}
                        </div>
                      </div>
                      <div className="tc tcell _40">
                        <img
                            className="r-i"
                            src={getRamdomCourseImg()}
                            alt={program?.data?.name}
                        />
                        <Link
                            to={`/studio/authoring/programs/${program?.data?.program_id}?platform=${encodeURI(program?.data?.platform)}&key=${encodeURIComponent(program?.data?.program_key)}`}
                            target={''}
                            className="tl-2 np"
                        >
                      <span prop-coursetitle="" className="nb">
                        {program?.data?.name}
                      </span>
                        </Link>
                      </div>
                      <div className="tc tcell _25">
                        <div prop-courseid="" className="sm">
                          {program?.data?.program_id}
                        </div>
                      </div>
                      <div className="tc tcell w_a _25">
                        <div prop-programslug="" className="sm">
                          {program?.data?.slug}
                        </div>
                      </div>
                    </div>
                ))
            ) : (
                <EmptyTableDataInfographic/>
            )}
            <div className="tf pagination-container">
              <div className="div-block-289">
                <div className="info-3">
                  {t('Showing')}{' '}
                  {(page - 1) * size + 1}{' '}
                  {t('to')}{' '}
                  {Math.min(page * size, pagination.totalItems)}{' '}
                  {t('of')} {pagination.totalItems}
                </div>
                {
                    pagination.totalItems > size && (
                        <ReactPaginate
                            onPageChange={paginate}
                            pageCount={pagination.totalPages}
                            forcePage={page - 1}
                            previousLabel={'Prev'}
                            nextLabel={'Next'}
                            containerClassName={'pagination'}
                            pageLinkClassName={'page-number'}
                            previousLinkClassName={'page-number'}
                            nextLinkClassName={'page-number'}
                            activeLinkClassName={'active'}
                        />
                    )
                }
              </div>
            </div>
            {/*<div react-component="AuthoringProgramsPagination" className="tf">
                            <div className="div-block-289">
                                <div prop-totalitems="" prop-currentpage="" prop-itemsperpage=""
                                     className="info-3">Showing 1 to 10 of 20 entries
                                </div>
                                <div className="pagination">
                                    <div prop-currentpage="" className="arrow-invite-previous-disabled p-item">
                                        <div className="text-block fa_icon fa-arrow disabled"></div>
                                    </div>
                                    <div prop-currentpage="" className="p-active p-item">
                                        <div>1</div>
                                    </div>
                                    <div prop-currentpage="" className="p-item">
                                        <div>2</div>
                                    </div>
                                    <div prop-totalitems="" prop-currentpage="" className="p-item arrow-next">
                                        <div className="text-block fa_icon fa-arrow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default StudioAuthoringProgramsViewContent;
