import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './StudioAuthoringCoursesViewContent.css';
import { Link } from 'react-router-dom';
import {
  getRamdomCourseImg,
  getStudioURL,
  getTableSizes,
} from '../../../hooks/helpers';
import TableSizeDropdown from '../../../components/TableSizeDropdown/TableSizeDropdown';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import EmptyTableDataInfographic from '../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic';
import { getCourseAPI } from '../SkillsCoursesViewContent/utils';
import ReactPaginate from 'react-paginate';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlDivider from '@shoelace-style/shoelace/dist/react/divider';
import SlDropdown from '@shoelace-style/shoelace/dist/react/dropdown';
import SlMenu from '@shoelace-style/shoelace/dist/react/menu';
import SlMenuItem from '@shoelace-style/shoelace/dist/react/menu-item';

const studioURL = getStudioURL();
const TABLE_SIZES = getTableSizes();

const css = `
  .dropdown-hoist {
    border: solid 2px var(--sl-panel-border-color);
    padding: var(--sl-spacing-medium);
    overflow: hidden;
  }
`;

const StudioAuthoringCoursesViewContent = () => {
  const { t } = useTranslation();
  const [courses, setCourses] = useState([]);
  const [coursesLoaded, setCoursesLoaded] = useState(false);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(TABLE_SIZES[0]);
  const [pagination, setPagination] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchCourses(limit, 0, search);
  }, [limit]);

  const fetchCourses = async (limit = 12, offset = 0, search = "") => {
    setCoursesLoaded(false);
    try {
      const response = await getCourseAPI(limit, offset, search);
      const courseData = response?.courses || response;
      setCourses(Array.isArray(courseData?.results) ? courseData.results : []);
      
      setPagination({
        totalItems:  response?.courses?.count||  response?.count ,
        currentPage: response?.courses?.current_page || response?.current_page ,
        totalPages: response?.courses?.total_pages || response?.total_pages  ,
        pageSize: limit,
      });
      setCoursesLoaded(true);
    } catch (error) {
      console.error('Error fetching courses:', error);
      setCoursesLoaded(true);
    }
  };

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
    const offset = selected * limit;
    fetchCourses(limit, offset, search);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    setCurrentPage(1);
    fetchCourses(limit, 0, searchTerm);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setCurrentPage(1);
    fetchCourses(newLimit, 0, search);
  };

  const handleImageError = (e) => {
    e.target.src = getRamdomCourseImg();
  };

  return (
    <>
      <div className="div-block-183">
        <div className="div-block-205">
          <div className="div-block-206"></div>
          <div className="text-block-113">AUTHORING | COURSES</div>
        </div>
        <div className="div-block-308">
          {/*<Link
            to={"/studio/authoring/courses/add"}
            className="link-block-22 w-inline-block"
          >
            <div className="div-block-207 first active follow-user">
              <div className="text-block-111">Add New Course</div>
            </div>
          </Link>*/}
          <SlDropdown>
            <SlButton className={"primary-shoelace-bg"} slot="trigger">Add New Course</SlButton>

            <SlMenu className={'secondary-shoelace-bg'} style={{maxWidth:"200px"}}>
              <SlMenuItem className={'shoelace-link-color'} value="undo"><Link
                  to={"/studio/authoring/courses/add"}
              >
                Via Wizard
              </Link></SlMenuItem>
              <SlMenuItem className={'shoelace-link-color'} value="undo"><Link
                  to={studioURL}
                  target="_blank"
              >
                Via Studio
              </Link></SlMenuItem>

            </SlMenu>
          </SlDropdown>
        </div>
      </div>
      <div react-component="CourseTable" className="table_cont-2">
        <div className="div-block-217">
          <div className="div-block-169">
            <div>
              <div className="text-block-135">
                Courses
                <br />
              </div>
            </div>
          </div>
          <div className="div-block-169">
            <div className="div-block-171">
              <div className="sm">Show</div>
              <div className="form-block w-form">
                <TableSizeDropdown setSize={handleLimitChange} setPage={setCurrentPage}/>
              </div>
              <div className="sm">entries</div>
            </div>
            <div className="div-block-171">
              <div className="sm">Search:</div>
              <div className="form-block w-form">
                <form
                  id="email-form-2"
                  name="email-form-2"
                  data-name="Email Form 2"
                  method="get"
                >
                  <input
                    onChange={handleSearch}
                    className="input w-input"
                    maxLength="256"
                    name="field-3"
                    data-name="Field 3"
                    placeholder=""
                    type="text"
                    id="field-3"
                    required=""
                  />
                </form>
              </div>
            </div>
          </div>
          <div react-component="table" className="table">
            <div className="th">
              <div className="tc _10">
                <div className="sm">#</div>
              </div>
              <div className="tc _40">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">Name</div>
                </div>
              </div>
              <div className="tc _25">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">ID</div>
                </div>
              </div>
              <div className="tc _25">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">Slug</div>
                </div>
              </div>
            </div>
            {!coursesLoaded ? (
              <IBLSpinLoader
                color={'#FFF'}
                size={20}
                containerHeight={'200px'}
              />
            ) : courses.length > 0 ? (
              courses.map((course, index) => {
                const imagePath = course?.data?.edx_data?.course_image_asset_path || course?.edx_data?.course_image_asset_path;
                const fullImagePath = imagePath ? process.env.REACT_APP_IBL_LMS_URL + imagePath : '';

                return (
                  <div
                    key={index}
                    react-component="AuthoringCoursesRow"
                    className="tr"
                  >
                    <div className="tc tcell _10">
                      <div className="m">
                        {(currentPage - 1) * limit + index + 1}
                      </div>
                    </div>
                    <div className="tc tcell _40">
                      <img
                        className="r-i"
                        onError={handleImageError}
                        src={fullImagePath}
                        alt={course?.data.name || course?.name}
                      />
                      <Link
                        to={'/studio/authoring/courses/' + (course?.course_id || course?.data?.course_id  )}
                        target={''}
                        className="tl-2 np wrapped-ellipsis-text"
                      >
                        <span className="nb">
                          {course?.data?.name || course?.name}
                        </span>
                      </Link>
                    </div>
                    <div className="tc tcell _25">
                      <div className="sm">
                        {course?.data?.course_id || course?.course_id}
                      </div>
                    </div>
                    <div className="tc tcell w_a _25">
                      <div className="sm">
                        {course?.data?.slug || course?.slug}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <EmptyTableDataInfographic />
            )}
          </div>
        </div>
      </div>
      {pagination && pagination.totalItems > 0 && (
        <div className="tf pagination-container">
          <div className="div-block-289">
            <div className="info-3">
              {t('Showing')}{' '}
              {(currentPage - 1) * limit + 1}{' '}
              {t('to')}{' '}
              {Math.min(currentPage * limit, pagination.totalItems)}{' '}
              {t('of')} {pagination.totalItems}
            </div>
            <ReactPaginate
              onPageChange={paginate}
              pageCount={pagination.totalPages}
              forcePage={currentPage - 1}
              previousLabel={'Prev'}
              nextLabel={'Next'}
              containerClassName={'pagination'}
              pageLinkClassName={'page-number'}
              previousLinkClassName={'page-number'}
              nextLinkClassName={'page-number'}
              activeLinkClassName={'active'}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default StudioAuthoringCoursesViewContent;