import React, { useContext, useEffect, useRef, useState } from 'react';

import './StudioAuthoringSettingsViewContent.css';
import { getTenant } from '../../../hooks/helpers';
import { setStudioLogo, setStudioDarkLogo } from '../../../hooks/utils/studio';
import { AppDataContext, IBLSpinLoader } from '@iblai/ibl-web-react-common';
import useTenant from '../../../hooks/useTenant';
import { updateTenantMetaData } from '../../../hooks/utils/tenant';

const StudioAuthoringSettingsViewContent = () => {
  const { setTenantMetaData } = useContext(AppDataContext);
  const {
    handleUpdateTenantMetaData,
    tenantMetaData,
    tenantMetaDataLoading,
    METADATAS,
  } = useTenant();
  const { setNotification } = useContext(AppDataContext);
  const inputLightImgUploadRef = useRef(null);
  const inputDarkImgUploadRef = useRef(null);
  const [isUpdatingTenantDomain, setIsUpdatingTenantDomain] = useState(false);
  const [platformDomains, setPlatformDomains] = useState(() => {
    return {
      spa_domains: {
        skills: tenantMetaData?.spa_domains?.skills
          ? { ...tenantMetaData?.spa_domains?.skills }
          : {
              active: false,
              domain: '',
            },
        mentor: tenantMetaData?.spa_domains?.mentor
          ? { ...tenantMetaData?.spa_domains?.mentor }
          : {
              active: false,
              domain: '',
            },
        analytics: tenantMetaData?.spa_domains?.analytics
          ? { ...tenantMetaData?.spa_domains?.analytics }
          : {
              active: false,
              domain: '',
            },
      },
    };
  });

  const handleLightUploaderTrigger = () => {
    inputLightImgUploadRef.current.click();
  };

  const handleDarkUploaderTrigger = () => {
    inputDarkImgUploadRef.current.click();
  };

  const [lightLogoURL, setLightLogoURL] = useState('');
  const [darkLogoURL, setDarkLogoURL] = useState('');
  const [lightLogoURLUpdating, setLightLogoURLUpdating] = useState(false);
  const [darkLogoURLUpdating, setDarkLogoURLUpdating] = useState(false);

  const handleLightImgUploadChange = async (e) => {
    if (!e) {
      return;
    }
    setLightLogoURLUpdating(true);
    const file = e?.target?.files[0];
    inputLightImgUploadRef.current.value = '';
    const formData = new FormData();
    formData.append('file', file, file.name);
    setStudioLogo(formData, (response) => {
      setLightLogoURL(response.url);
      setLightLogoURLUpdating(false);
    });
  };

  const handleDarkImgUploadChange = async (e) => {
    if (!e) {
      return;
    }
    setDarkLogoURLUpdating(true);
    const file = e?.target?.files[0];
    inputDarkImgUploadRef.current.value = '';
    const formData = new FormData();
    formData.append('file', file, file.name);
    setStudioDarkLogo(formData, (response) => {
      setDarkLogoURL(response.url);
      setDarkLogoURLUpdating(false);
    });
  };

  const handleLoadSettingsData = () => {
    setLightLogoURL(
      `${process.env.REACT_APP_IBL_AXD_URL}/api/core/orgs/${getTenant()}/logo/`
    );
    setDarkLogoURL(
      `${process.env.REACT_APP_IBL_AXD_URL}/api/core/orgs/${getTenant()}/dark-mode-logo/`
    );
  };

  useEffect(() => {
    handleLoadSettingsData();
  }, []);

  const [focusedMetaData, setFocusedMetaData] = useState('');

  const handleMetaDataCheckBoxChange = (metaData) => {
    setFocusedMetaData(metaData.slug);
    const updatedValue = !(
      tenantMetaData?.[metaData.slug] ?? metaData.defaultValue
    );
    handleUpdateTenantMetaData(
      { [metaData.slug]: updatedValue },
      (successfullyUpdate) => {
        setFocusedMetaData('');
        if (successfullyUpdate === true) {
          setTenantMetaData({
            ...tenantMetaData,
            [metaData.slug]: updatedValue,
          });
        } else {
          setNotification({
            msg: 'An error occurred while updating tenant settings',
            success: false,
          });
        }
      }
    );
  };

  const handleDomainChangeSave = () => {
    setIsUpdatingTenantDomain(true);
    const newMetadata = {
      ...tenantMetaData,
      ...platformDomains,
    };
    updateTenantMetaData(newMetadata, (successfullyUpdate) => {
      setIsUpdatingTenantDomain(false);
    });
  };

  const handleDomainChange = (event) => {
    const { name, value } = event.target;
    setPlatformDomains({
      spa_domains: {
        ...platformDomains['spa_domains'],
        [name]: {
          ...platformDomains['spa_domains'][name],
          domain: value,
        },
      },
    });
  };

  return (
    <>
      <div className="div-block-183">
        <div className="div-block-205">
          <div className="div-block-206"></div>
          <div className="text-block-113">STUDIO | SETTINGS</div>
        </div>
      </div>
      <div className="div-block-326 l map">
        <div
          react-hook="useStudioSettings"
          react-component="StudioSettingsTable"
          className="table_cont-2"
        >
          <div className="div-block-325 w_bg nbg">
            <div className="text-block-172">Platform Logos</div>
            <div className="table">
              <div className="tr">
                <div className="tc tcell">
                  <div className="m-2">Light Logo</div>
                </div>
                <div className="tc tcell _35">
                  {lightLogoURLUpdating ? (
                    <IBLSpinLoader color={'#FFF'} size={20} />
                  ) : (
                    <div className="div-block-271">
                      <img
                        className="image-94"
                        src={lightLogoURL}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  )}
                </div>
                <div className="tc tcell _32-5">
                  <div
                    onClick={() => handleLightUploaderTrigger()}
                    className="div-block-327 first active edit edit_logo"
                  >
                    <div className="text-block-173">Edit</div>
                  </div>
                  <div className="html-embed-10 w-embed">
                    <input
                      onChange={handleLightImgUploadChange}
                      ref={inputLightImgUploadRef}
                      type="file"
                      prop-events-names="onChange"
                      prop-events-value-onchange="handleLightLogoOnChange"
                      className="file-upload light-logo"
                      accept=".jpg, .jpeg, .png, .gif, .svg"
                      style={{ display: 'none' }}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="tr">
                <div className="tc tcell">
                  <div className="m-2">Dark Logo</div>
                </div>
                <div className="tc tcell _35">
                  {darkLogoURLUpdating ? (
                    <IBLSpinLoader color={'#FFF'} size={20} />
                  ) : (
                    <div className="div-block-271">
                      <img
                        className="image-94"
                        src={darkLogoURL}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  )}
                </div>
                <div className="tc tcell _32-5">
                  <div
                    onClick={() => handleDarkUploaderTrigger()}
                    className="div-block-327 first active edit edit_logo"
                  >
                    <div className="text-block-173">Edit</div>
                  </div>
                  <div className="html-embed-10 w-embed">
                    <input
                      onChange={handleDarkImgUploadChange}
                      ref={inputDarkImgUploadRef}
                      type="file"
                      prop-events-names="onChange"
                      prop-events-value-onchange="handleDarkLogoOnChange"
                      className="file-upload dark-logo"
                      accept=".jpg, .jpeg, .png, .gif, .svg"
                      style={{ display: 'none' }}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="div-block-326 l map tenant-settings-form">
        <div
          react-hook="useStudioSettings"
          react-component="StudioSettingsTable"
          className="table_cont-2"
        >
          <div className="div-block-325 w_bg nbg">
            <div className="text-block-172">Tenant settings</div>
            {tenantMetaDataLoading ? (
              <IBLSpinLoader
                size={30}
                color={'#FFF'}
                containerHeight={'100px'}
              />
            ) : (
              <div className="table">
                {METADATAS.map((_metadata, index) => (
                  <div key={`metadata-setting-${index}`} className="tr">
                    <div className="tc tcell _65">
                      <div className="m-2">{_metadata.label}</div>
                    </div>
                    <div className="tc tcell _20">
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          handleMetaDataCheckBoxChange(_metadata);
                        }}
                        className="email-switching-block justify-start-flex w-embed"
                      >
                        {focusedMetaData === _metadata.slug ? (
                          <span style={{ marginLeft: '10px' }}>
                            <IBLSpinLoader size={20} color={'#FFF'} />
                          </span>
                        ) : (
                          <label className="user-switcher">
                            <input
                              /*onChange={
                                                      handleAddingUnregisteredUsersCheck
                                                    }*/
                              type="checkbox"
                              checked={
                                tenantMetaData?.[_metadata.slug] ??
                                _metadata.defaultValue
                              }
                            />
                            <span className="user-switcher-slider round"></span>
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {process.env.REACT_APP_IBL_MULTITENANCY_ENABLED === 'true' &&
        (!tenantMetaDataLoading ? (
          <div className="div-block-326 l map tenant-domains-settings">
            <div className="table_cont-2">
              <div className="div-block-325 w_bg nbg">
                <div className="text-block-172">Platform Domains</div>
                <div className="domain">
                  <div className="w-layout-hflex">
                    <div style={{ flex: 1 }}>
                      <label htmlFor="skills">Skills Domain</label>
                      <div className="w-layout-hflex flex-block-14">
                        <input
                          name="skills"
                          value={platformDomains?.spa_domains.skills.domain}
                          className="text-field-10 w-input"
                          maxLength={100}
                          onChange={handleDomainChange}
                          data-name="Field 6"
                          type="text"
                          id="field-6"
                          placeholder="e.g. https://skills.iblai.app"
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                      <label htmlFor="skills">Active</label>
                      <div className="tc tcell _20">
                        <p>
                          {platformDomains?.spa_domains.skills.active
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="domain">
                  <div className="w-layout-hflex">
                    <div style={{ flex: 1 }}>
                      <label htmlFor="mentor">Mentor Domain</label>
                      <div className="w-layout-hflex flex-block-14">
                        <input
                          name="mentor"
                          className="text-field-10 w-input"
                          maxLength={100}
                          onChange={handleDomainChange}
                          data-name="Field 6"
                          type="text"
                          id="field-6"
                          value={platformDomains?.spa_domains.mentor.domain}
                          placeholder="e.g. https://mentor.iblai.app"
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                      <label htmlFor="mentor">Active</label>
                      <div className="tc tcell _20">
                        <p>
                          {platformDomains?.spa_domains.mentor.active
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="domain">
                  <div className="w-layout-hflex">
                    <div style={{ flex: 1 }}>
                      <label for="analytics">Analytics Domain</label>
                      <div className="w-layout-hflex flex-block-14">
                        <input
                          name="analytics"
                          value={platformDomains?.spa_domains.analytics.domain}
                          className="text-field-10 w-input"
                          maxLength={100}
                          data-name="Field 6"
                          type="text"
                          id="field-6"
                          onChange={handleDomainChange}
                          placeholder="e.g. https://analytics.iblai.app"
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                      <label for="analytics">Active</label>
                      <div className="tc tcell _20">
                        <p>
                          {platformDomains?.spa_domains.analytics.active
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-block-327 active domain_save_btn">
                  <div
                    className="text-block-173"
                    onClick={() => handleDomainChangeSave()}
                  >
                    {isUpdatingTenantDomain ? (
                      <IBLSpinLoader color={'#FFF'} size={20} />
                    ) : (
                      'Save'
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <IBLSpinLoader color={'#FFF'} size={20} />
        ))}
    </>
  );
};

export default StudioAuthoringSettingsViewContent;
