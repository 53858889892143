import { useEffect, useRef, useState } from 'react';
import {getAdminProgramsAPI, getStudioURL, getTableSizes} from './helpers';
import {getCourseAPI} from "../views/content/SkillsCoursesViewContent/utils";
import {useDebounce} from "./useDebounce";

const studioURL = getStudioURL();
const TABLE_SIZES = getTableSizes();
export const useStudioAuthoringPrograms = (username, page=1, limit=10, search="") => {
  const hasFetchedData = useRef(false);
  const [profilePrograms, setProfilePrograms] = useState([]);
  const [profileProgramsLoaded, setProfileProgramsLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    totalItems:0,
    currentPage:1,
    totalPages:0,
    pageSize:0
  });

  const fetchPrograms = async () => {
    setProfileProgramsLoaded(false);
    const offset = page===1 ? 0 : ((page-1) * limit)
    const response = await getCourseAPI(limit, offset, search, true);
    setProfilePrograms(Array.isArray(response?.results) ? response.results : []);
    setPagination({
      totalItems:  response?.count,
      currentPage: page,
      totalPages: Math.ceil(response?.count / limit),
      pageSize: limit,
    });
    setProfileProgramsLoaded(true);
    /*getAdminProgramsAPI(
      username,
      (programs) => {
        setProfilePrograms(programs);
        setProfileProgramsLoaded(true);
      },
      (programDetail) => {
        // Update the specific program's detail asynchronously
        setProfilePrograms((currentPrograms) => {
          return currentPrograms.map((program) => {
            if (program.program_id === programDetail.program_id) {
              return { ...program, ...programDetail };
            }
            return program;
          });
        });
      }
    );*/
  }

  useDebounce( fetchPrograms, 200, [limit, page, search]);

  return [profilePrograms, profileProgramsLoaded, pagination];
};
