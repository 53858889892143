import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useOutletContext, useParams} from "react-router-dom"
import Async from "react-select/async";
import useTeamsAssignments from "../../../hooks/useTeamsAssignments";
import {AppDataContext, IBLSpinLoader} from "@iblai/ibl-web-react-common";
import TeamsUsersAssignmentTabContent
    from "../TeamsAssignmentCreationParentViewContent/modules/TeamsUsersAssignmentTabContent/TeamsUsersAssignmentTabContent";
import TeamsAssignmentTabContent
    from "../TeamsAssignmentCreationParentViewContent/modules/TeamsAssignmentTabContent/TeamsAssignmentTabContent";

const TeamsAssignmentCourseCreationViewContent = () => {
    const navigate = useNavigate()
    const {setCurrentTab, currentTab} = useOutletContext()
    const {getCoursesForSelectDropdown, handleSaveLearnersAssignmentSuggestions, handleSaveBulkLearnersAssignmentSuggestions,
        handleSaveTeamsAssignmentSuggestions
    } = useTeamsAssignments()
    const {setNotification} = useContext(AppDataContext)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectedTeams, setSelectedTeams] = useState([])
    const [submitting, setSubmitting] = useState(false)
    //SUB TABS HANDLER
    const LEARNER_MODE = "learners"
    const TEAM_MODE = "teams"
    const BULK_MODE = "bulk"
    const ASSIGN_MODES = [
        {
            label: "Learners",
            slug: "learners"
        },
        {
            label: "Teams",
            slug: "teams"
        },
        {
            label: "Bulk",
            slug: "bulk"
        },
    ]
    const [currentAssignMode, setCurrentAssignMode] = useState(ASSIGN_MODES[0].slug)

    //FIELDS HANDLING
    const defaultFields = {
        courses: [],
        content: currentAssignMode,
        learners: [],
        teams: [],
        bulk: ""
    }
    const [fields, setFields] = useState(defaultFields)

    useEffect(() => {
        setCurrentTab('courses')
    }, []);

    //COURSE DROPDOWN
    const handleCourseChange = (choice) => {
        setFields({
            ...fields,
            courses: choice,
        });
    };

    const coursePromiseOptions = (inputValue) => {
        return new Promise((resolve) => {
            getCoursesForSelectDropdown(inputValue, (data) => {
                const result = data?.results;
                if (Array.isArray(result) && result.length > 0) {
                    resolve(
                        result
                            .map((course) => {
                                return {
                                    value: course?.data?.course_id,
                                    label: course?.data.name,
                                };
                            })
                    );
                } else {
                    resolve([]);
                }
            });
        });
    };

    const handleSubmit = async () => {
        if(submitting){
            return
        }
        if(currentAssignMode===LEARNER_MODE){
            if(fields.courses.length === 0 || (currentAssignMode===LEARNER_MODE && selectedUsers.length === 0) || (currentAssignMode===TEAM_MODE && selectedTeams.length === 0)){
                setNotification({
                    msg:`Fill out all required fields.`,
                    success:false
                })
                return
            }
        }
        setSubmitting(true)
        let response
        switch (currentAssignMode){
            case TEAM_MODE:
                response = await handleSaveTeamsAssignmentSuggestions({contentType:"course", assignmentData:{
                        content:fields.courses.map((_course)=>({course_id:_course.value})),
                        teams: [...selectedTeams]
                    }})
                break;
            default:
                response = await handleSaveBulkLearnersAssignmentSuggestions({contentType:"course", assignmentData:{
                        content:fields.courses.map((_course)=>({course_id:_course.value})),
                        learners: [...selectedUsers]
                    }})

        }
           setSubmitting(false)
        if(!response){
            setNotification({
                msg:`Course assignment failed. Please try again.`,
                success:false
            })
            return
        }
        setNotification({
            msg:`Course assignment successfully`,
            success:true
        })
        setFields(defaultFields)
        setSelectedTeams([])
        setSelectedUsers([])
    }
    return (
        <>
            <div data-w-tab="Platform" className="w-tab-pane w--tab-active">
                <div className="div-block-228 less-pad">
                    <div className="form-block-7 w-form">
                        <form id="email-form-5" name="email-form-5" data-name="Email Form 5"
                              method="get" data-wf-page-id="67a0b1858f35b36230d3e1a3"
                              data-wf-element-id="f013830d-d35f-ead7-aced-31486906216d"><label
                            htmlFor="field-5" className="text-block-139-bd-f-soc">Courses *</label>
                            <Async
                                isMulti
                                cacheOptions
                                classNamePrefix={'react-select'}
                                defaultOptions
                                loadOptions={coursePromiseOptions}
                                value={fields?.courses}
                                options={fields?.courses}
                                onChange={handleCourseChange}
                                isSearchable={true}
                                placeholder={"Search for courses..."}
                            />
                            <label htmlFor="name-3" className="text-block-139-bd-f-soc">Assign Mode
                                *</label>
                            <div data-current="Individual" data-easing="ease" data-duration-in={300}
                                 data-duration-out={100} className="tabs-2 w-tabs">
                                <div className="tabs-menu-2 w-tab-menu">
                                    <a onClick={e => {
                                        e.preventDefault()
                                        setCurrentAssignMode(LEARNER_MODE)
                                    }} href={"#"} data-w-tab="Individual"
                                       className={`invite-mode-tab-link w-inline-block w-tab-link ${currentAssignMode === LEARNER_MODE ? "w--current" : ""} `}>
                                        <div className="w-layout-hflex flex-block-13"><img
                                            loading="lazy" src="/images/user-12.svg" alt
                                            className="image-142"/></div>
                                        <h5 className="heading-5 capitalize">{LEARNER_MODE}</h5>
                                    </a>
                                    <a data-w-tab="Bulk" onClick={e => {
                                        e.preventDefault()
                                        setCurrentAssignMode(TEAM_MODE)
                                    }} href={"#"}
                                       className={`invite-mode-tab-link w-inline-block w-tab-link ${currentAssignMode === TEAM_MODE ? "w--current" : ""} `}>
                                        <div className="w-layout-hflex flex-block-13"><img
                                            loading="lazy" src="/images/users-4.svg" alt
                                            className="image-142"/></div>
                                        <h5 className="heading-5 capitalize">{TEAM_MODE}</h5>
                                    </a>
                                    {/*<a data-w-tab="Bulk 2" onClick={e => {
                                    e.preventDefault()
                                    setCurrentAssignMode(BULK_MODE)
                                }} href={"#"}
                                   className={`invite-mode-tab-link w-inline-block w-tab-link ${currentAssignMode===BULK_MODE ? "w--current" : ""} `}>
                                    <div className="w-layout-hflex flex-block-13"><img
                                        loading="lazy" src="/images/file-stack.svg" alt
                                        className="image-142"/></div>
                                    <h5 className="heading-5 capitalize">{BULK_MODE}</h5>
                                </a>*/}
                                </div>
                                <div className="tabs-content w-tab-content">
                                    {
                                        currentAssignMode === LEARNER_MODE && <TeamsUsersAssignmentTabContent {...{
                                            selectedUsers, setSelectedUsers
                                        }} />
                                    }
                                    {
                                        currentAssignMode === TEAM_MODE && (
                                            <TeamsAssignmentTabContent {...{
                                                selectedTeams, setSelectedTeams
                                            }} />
                                        )
                                    }
                                    {
                                        currentAssignMode === BULK_MODE && (
                                            <div data-w-tab="Bulk 2" className="w-tab-pane w--tab-active">
                                                <div className="w-layout-hflex flex-block-31"><label
                                                    htmlFor="name-3" className="text-block-139-bd-f-soc">CSV
                                                    file upload *</label>
                                                    <a href="#" className="link-block-25 w-inline-block"><img
                                                        loading="lazy" src="/images/paperclip-1.svg" alt
                                                        className="image-151"/>
                                                        <div>usa-team.csv</div>
                                                    </a>
                                                </div>
                                                <div
                                                    className="div-block-207 first active follow-user redeem-btn mg-l16 no-mg-left">
                                                    <div className="text-block-111 red-text cf">Upload</div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </form>
                        <div className="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-layout-hflex flex-block-37">
                <div className="div-block-308">
                    <a onClick={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }} href="#" className="link-block-22 w-inline-block ">
                        <div data-w-id="248c8885-eeb2-71e0-8144-2591929c8295"
                             className={`div-block-207 first active follow-user assignment-submit-btn ${submitting ? "disabled" : ""}`}>
                            <div className="text-block-111">Add Assignment</div>
                            {submitting &&
                                <span style={{marginLeft: "10px"}}><IBLSpinLoader size={15} color={"#FFF"}/></span>}
                        </div>
                    </a>
                </div>
            </div>
        </>

    )
        ;
};

export default TeamsAssignmentCourseCreationViewContent;