import React, {useContext, useEffect, useState} from 'react';
import {createNotification, getNotificationContext, sendNotification} from "./utils/notification";
import {AppDataContext} from "@iblai/ibl-web-react-common";
import dayjs from "dayjs";
import {EditorState} from "draft-js";

const UseTeamsNotifications = () => {

    const {setNotification} = useContext(AppDataContext)
    const [submitting, setSubmitting] = useState(false);
    const [notificationChannels, setNotificationChannels] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const defaultFields = {
        programs: [],
        groups: [],
        location: [],
        learners: [],
        date: "",
        message: "",
        subject: "",
        channels:[]
    }

    const handleFetchNotificationChannels = async () =>{
      const notificationContexts = await getNotificationContext();
      if(notificationContexts){
          setNotificationChannels(notificationContexts.map((_channel)=>({
              label: String(_channel.name).includes("_") ? String(_channel.name).replaceAll("_", " ").toUpperCase() : String(_channel.name).toUpperCase(),
              value: _channel.id,
          })))
      }
    }

    useEffect(() => {
        handleFetchNotificationChannels()
    }, []);

    const [fields, setFields] = useState(defaultFields);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!fields.programs.length && !fields.groups.length && !fields.learners.length) {
            setNotification({
                msg: "Fill out at least a target audience for the notification.",
                success: false
            })
            return
        }
        if (!fields.subject || !fields.message || fields.channels.length === 0) {
            setNotification({
                msg: "Fill out all required fields.",
                success: false
            })
            return
        }
        let sources = []
        if (fields.learners.length > 0) {
            sources = [
                ...sources,
                ...fields.learners.map((learner) => ({
                    type: 'email',
                    data: learner?.value
                }))

            ]
        }
        if (fields.programs.length > 0) {
            sources = [
                ...sources,
                ...fields.programs.map((program) => ({
                    type: 'program',
                    data: program.value
                }))
            ]
        }
        if (fields.groups.length > 0) {
            sources = [
                ...sources,
                ...fields.groups.map((group) => ({
                    type: 'department',
                    data: group.value
                }))
            ]
        }
        setSubmitting(true);
        const data = {
            template_data: {
                message_title: fields.subject,
                message_body: fields.message,
            },
            ...(fields.date ? {
                process_on: dayjs(fields.date).toISOString(),
            } : {}),
            sources,
            channels: fields.channels.map((channel) => (channel.value))
        }
        handleCreateAndSendNotification(data, (result) => {
            setFields(defaultFields)
            setEditorState(EditorState.createEmpty())
        })
    }

    const handleCreateAndSendNotification = async (data, callback) => {
        const notificationData = await createNotification(data);
        if (!notificationData?.build_id) {
            setSubmitting(false);
            setNotification({
                msg: "Failed to create the notification.",
                success: false,
            })
            return
        }
        const sendNotificationData = await sendNotification(notificationData?.build_id);
        if (!sendNotificationData?.build_id) {
            setSubmitting(false);
            setNotification({
                msg: "Failed to schedule notification dispatch.",
                success: false,
            })
            return
        }
        setSubmitting(false);
        setNotification({
            msg: "Notification created and dispatch initialized.",
            success: true,
        })
        callback(notificationData)
    }

    const handleMultiDropdownChange = (choice, target) => {
        setFields({
            ...fields,
            [target]: choice,
        });
    };

    const handleTextInputChange = (e) => {
        setFields({
            ...fields,
            [e?.target?.name]: e?.target?.value,
        });
    }
    return {notificationChannels,
        handleMultiDropdownChange, handleTextInputChange,
        handleCreateAndSendNotification, submitting, fields, setFields, handleSubmit,
        editorState, setEditorState

    }
};

export default UseTeamsNotifications;